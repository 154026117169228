<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="fb-responsible-form"
  >
    <div class="card-header pt-0">
      <div class="title">
        Insira os dados do Responsável
      </div>
    </div>
    <div class="card-content">
      <div class="fields">
        <b-field
          :message="
            errors.first('responsible_name') || getMessage('responsible_name')
          "
          :type="{
            'is-danger':
              errors.has('responsible_name') || getMessage('responsible_name')
          }"
          label="Nome do responsável"
        >
          <b-input
            v-model="responsible_name"
            v-validate="'required|first_name'"
            placeholder="Ex.: Severo James"
            type="text"
            name="responsible_name"
            size="is-large"
            rounded
          ></b-input>
        </b-field>

        <b-field :group-multiline="mobile" grouped>
          <b-field
            :message="
              errors.first('responsible_cpf') ||
                getMessage('responsible_cpf') ||
                getMessage('cpf')
            "
            :type="{
              'is-danger':
                errors.has('responsible_cpf') || getMessage('responsible_cpf')
            }"
            label="CPF do responsável"
            expanded
          >
            <b-input
              v-model="responsible_cpf"
              v-validate="'required|cpf'"
              v-cleave="masks.cpf"
              @input="validateAll"
              autocomplete="off"
              placeholder="Ex.: 999.999.999-99"
              type="text"
              name="responsible_cpf"
              size="is-large"
              rounded
            ></b-input>
          </b-field>

          <fb-user-select-birthdate
            v-model="responsible_birthdate"
            :messages="messages"
            field-name="responsible_birthdate"
            has-shadow="true"
          />
        </b-field>
        <p v-if="!responsible_terms_accepted_at" class="terms">
          <b-checkbox
            v-model="responsible_terms_accepted"
            false-value="0"
            true-value="1"
            >Li e aceito os
            <a href="/links/termos-de-uso-e-politica/" target="_blank"
              >termos de uso e política de privacidade</a
            >
          </b-checkbox>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import cleave from '@/utils/directives/cleave'

export default {
  directives: { cleave },
  props: {
    messages: {
      type: Object
    }
  },
  data() {
    return {
      masks: {
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
          onValueChanged: ({ target }) => {
            this.$nextTick(() => {
              if (target.value !== this.responsible_cpf) {
                this.$set(this, 'responsible_cpf', target.value)
              }
            })
            this.validateAll(target.value === this.responsible_cpf)
          }
        }
      }
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    currentStudent() {
      return this.$store.getters.currentStudent
    },
    responsible_name: {
      get() {
        return this.currentStudent?.responsible_name
      },
      set(responsible_name) {
        this.setProfile({ responsible_name })
        this.validateAll()
      }
    },
    responsible_cpf: {
      get() {
        return this.currentStudent?.responsible_cpf
      },
      set(responsible_cpf) {
        this.setProfile({ responsible_cpf })
        this.validateAll()
      }
    },
    responsible_birthdate: {
      get() {
        return this.currentStudent.responsible_birthdate
      },
      set(responsible_birthdate) {
        this.setProfile({ responsible_birthdate })
        this.validateAll()
      }
    },
    responsible_terms_accepted: {
      get() {
        return this.currentStudent?.responsible_terms_accepted
      },
      set(responsible_terms_accepted) {
        this.setProfile({ responsible_terms_accepted })
        this.validateAll()
      }
    },
    responsible_terms_accepted_at() {
      return this.currentStudent?.responsible_terms_accepted_at
    },
    persisted: {
      get() {
        return this.currentStudent?.persisted
      },
      set(persisted) {
        this.setProfile({ persisted })
      },
      cache: false
    }
  },
  async mounted() {
    await this.validateAll(true)
  },
  methods: {
    async validateAll(skipPersisted) {
      const valid = await this.$validator.validateAll(null, {
        silent: true
      })

      this.$emit('messages', {})

      const termsAccepted =
        this.responsible_terms_accepted_at != null ||
        this.responsible_terms_accepted === '1'

      const result = valid && termsAccepted && !!this.responsible_birthdate

      this.$emit('validate', result)

      if (!skipPersisted) {
        this.$set(this, 'persisted', false)
      }

      return result
    },
    getMessage(attr) {
      if (!this.messages || !this.messages[attr]) {
        return ''
      }
      return this.messages[attr][0]
    },
    ...mapActions('auth', ['setProfile'])
  }
}
</script>

<style lang="scss">
.fb-responsible-form {
  padding: ($gap * 4) 0px;
  .card {
    &-header {
      padding: ($gap * 3) 0px;
      box-shadow: none;
      justify-content: center;
      .title {
        text-align: center;
      }
    }
  }
  .terms {
    padding: $gap 0px ($gap * 2);
    a {
      color: $primary;
      font-weight: bold;
    }
    .control-label {
      margin-left: $gap;
    }
  }
  &.is-desktop {
    padding: 0px;
    .fields {
      padding: $gap ($gap * 10);
    }
  }
  &.is-mobile {
    .fields {
      padding: $gap ($gap * 2);
    }
  }
}
</style>
