<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  model: {
    prop: 'period',
    event: 'change'
  },
  props: {
    period: {
      type: Number,
      required: true
    }
  },
  computed: {
    isRecurring() {
      return this.getPeriod.plan.is_recurring
    },
    getPeriod() {
      if (this.product && this.product.plans?.length) {
        const { slug, title, type } = this.product
        const payload = {
          plan: this.product.plans[this.period],
          product: {
            slug,
            title,
            type
          }
        }

        return payload
      }
      return {}
    },
    hasPlanDiscount() {
      const { discount } = this.getPeriod.plan?.price
      return discount
    },
    getPrice() {
      const { price } = this.getPeriod.plan
      return {
        integer: price.per_month.integer_part,
        decimal: price.per_month.decimal_part
      }
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    knowBetter() {
      if (!this.isAuthenticated) {
        this.$store.dispatch(
          'site/setRedirectUrl',
          this.product.know_better_url
        )
        this.$router.push(
          `/login?redirect_after=${this.product.know_better_url}`
        )
        return
      }
      location.assign(this.product.know_better_url)
    },
    ...mapActions('cart', ['addPlan'])
  }
}
</script>

<style></style>
