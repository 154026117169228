<template>
  <div
    v-if="about"
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="product-about"
  >
    <div class="card-header-title">SOBRE O CURSO</div>
    <div v-html="about"></div>
  </div>
</template>

<script>
export default {
  props: {
    about: null
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    }
  }
}
</script>

<style lang="scss">
.product-about {
  line-height: 1.25;
  padding: 1.5rem 6rem;
  &.is-desktop {
    border-top: 1px solid $grey-lighter;
    border-radius: 0px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    .card-content {
      padding: 3rem 6rem;
    }
  }
}
</style>
