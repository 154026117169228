<template>
  <ul
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    v-if="visible"
    class="fb-checkout-progress"
  >
    <li
      v-for="(step, index) in steps"
      :key="step.icon"
      :class="[
        { 'is-actived': index <= progress },
        { 'is-last': index === progress }
      ]"
    >
      <div class="fb-checkout-progress-icon">
        <b-icon :icon="step.icon" pack="custom" />
        <div v-show="hasLabel" class="fb-checkout-progress-label">
          {{ step.label }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    hasLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      steps: [
        { icon: 'icon-usuario', label: 'Identificação' },
        { icon: 'icon-carteira', label: 'Pagamento' },
        { icon: 'icon-confirmacao', label: 'Confirmação' }
      ]
    }
  },
  computed: {
    visible() {
      const authFormsPath = ['/login', '/cadastro', '/esqueci-a-senha']
      const { query, path } = this.$route

      if (authFormsPath.includes(path)) {
        return query.redirect_after === '/checkout'
      }
      return true
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    ...mapState({
      progress: (state) => state.checkoutProgress
    })
  }
}
</script>
