<template>
  <div
    v-if="resume"
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="product-resume"
  >
    <div class="card-header-title">APÓS O CURSO O QUE VOCÊ TERÁ APRENDIDO</div>
    <div v-html="resume"></div>
  </div>
</template>

<script>
export default {
  props: {
    resume: null
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    }
  }
}
</script>

<style lang="scss">
.product-resume {
  > p {
    text-transform: uppercase;
    color: $info;
    font-size: 14px;
    font-weight: bold;
    justify-content: flex-start;
    padding: ($gap * 2) $gap $gap;
  }
  ul {
    li {
      display: flex;
      padding: $gap 0px;
      > span {
        flex: 1;
      }
      &::before {
        display: inline-block;
        width: ($gap * 4);
        min-width: ($gap * 4);
        align-self: center;
        font-family: 'icomoon';
        content: '\e914
'; //.icon-confirmacao
        color: $secondary;
        font-size: 14px;
        text-align: center;
        margin-right: $gap;
      }
    }
  }
  &.is-desktop {
    border-top: 1px solid $grey-lighter;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 9rem;
  }
}
</style>
