import { getUniqueSessionId } from '~/utils/cookies/session'

export async function addCoupon(app, coupon) {
  const { $axios } = app
  const unique_session_id = getUniqueSessionId(app)
  try {
    const { data } = await $axios.post(
      `/api/student/v1/carts/coupons/${coupon}`,
      {
        unique_session_id
      }
    )
    return { success: true, data }
  } catch (e) {
    const { data } = e.response
    const error = data.errors.coupon_name.join(', ')
    return { success: false, error }
  }
}

export async function removeCoupon(app, coupon) {
  const { $axios } = app
  const unique_session_id = getUniqueSessionId(app)
  try {
    const { data } = await $axios.delete(
      `/api/student/v1/carts/coupons/${coupon}`,
      {
        data: {
          unique_session_id
        }
      }
    )
    return { success: true, data }
  } catch (e) {
    const { data } = e.response
    const error = data.errors.coupon_name.join(', ')
    return { success: false, error }
  }
}
