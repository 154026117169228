import filter from 'lodash/filter'
import { mapIcon } from '~/utils/map'

import { addCoupon } from '~/services/coupon'

// Um produto eh um curso oferecido.
// O que se coloca no carrinho de compras eh um plano de acesso ao curso.
// Esse plano pode ser: Mensal, Semestral ou Anual.
export async function getProducts({ $axios }) {
  try {
    const { data } = await $axios.get('api/app/v1/products')
    // Cursos completos como: ENEM, ENEM + Medicina e ITA/IME.
    const courses = filter(data, { type: 'full_course' }).map(mapIcon)
    // Cursos avulsos, como: Matematica Basica etc.
    const detached_courses = filter(data, { type: 'detached_course' }).map(
      mapIcon
    )
    // Cursos VIP: Super Med, Super Ita/Ime
    const super_courses = filter(data, { type: 'super' }).map(mapIcon)

    return {
      super_courses,
      courses,
      detached_courses
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.log(e)
    return []
  }
}

export async function getMyProducts({ $axios }) {
  try {
    const { data } = await $axios.get('api/student/v1/products')
    return data
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.log(e)
    return []
  }
}

export async function getProduct({ $axios }, slug) {
  try {
    const { data } = await $axios.get(`api/app/v1/products/${slug}`)
    const product = [data].map(mapIcon)[0]

    return product
  } catch (e) {
    return null
  }
}

// Adiciona plano no carrinho por ação de link
export async function addPlanFromURL(context) {
  const { $route, $store, product } = context
  const { query } = $route

  if (query && query['comprar-agora']) {
    const { slug, title, type } = product

    const plan = product.plans.find(
      (plan) => plan.slug === query['comprar-agora']
    )

    if (plan) {
      const payload = {
        plan,
        product: {
          slug,
          title,
          type
        },
        skipModal: true
      }

      await $store.dispatch('cart/addPlan', payload)
    }
  }
}

// Adiciona um cupom por ação de link
export async function addCouponFromURL(context) {
  const { $route } = context
  const { query } = $route

  if (query && query.cupom) {
    const { data, success } = await addCoupon(context, query.cupom)
    if (success) {
      context.$store.dispatch('cart/setProducts', data)
    }
  }
}

export function redirectToRouteFromURL(context) {
  const { $route, $router } = context
  const { query } = $route

  if (query && query.redirect) {
    $router.push(query.redirect)
  }
}
