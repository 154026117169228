<template>
  <div class="nuxt-static-page p-4">
    <nuxt />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.nuxt-static-page {
  width: 100vh;
  height: 100vh;
}
</style>
