import { getUniqueSessionId } from '~/utils/cookies/session'

export async function currentCart(app) {
  try {
    const unique_session_id = getUniqueSessionId(app)
    const shopping_cart_id = app.state?.cart?.shopping_cart_id

    const { data } = await app.$axios.get('api/student/v1/carts/current', {
      params: {
        unique_session_id,
        shopping_cart_id
      }
    })

    return { success: true, data }
  } catch (error) {
    return { success: false, error }
  }
}

export async function addPlan(app, payload) {
  const { slug } = payload
  try {
    const unique_session_id = getUniqueSessionId(app)
    const shopping_cart_id = app.state?.cart?.shopping_cart_id

    const { data } = await app.$axios.post(
      `api/student/v1/carts/plans/${slug}`,
      {
        unique_session_id,
        shopping_cart_id
      }
    )
    return { success: true, data }
  } catch (error) {
    await app.dispatch('cart/clearProducts')
    return { success: false, error }
  }
}

export async function removePlan(app, payload) {
  const { slug } = payload
  try {
    const unique_session_id = getUniqueSessionId(app)
    const shopping_cart_id = app.state?.cart?.shopping_cart_id

    const { data } = await app.$axios.delete(
      `api/student/v1/carts/plans/${slug}`,
      {
        data: {
          unique_session_id,
          shopping_cart_id
        }
      }
    )
    return { success: true, data }
  } catch (error) {
    await app.dispatch('cart/clearProducts')
    return { success: false, error }
  }
}
