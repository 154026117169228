const cacheControl = (values) => ({ res }) => {
  if (!process.server) return

  const cacheControlValue = Object.entries(values)
    .map(([key, value]) => `${key}=${value}`)
    .join(',')

  res.setHeader('Cache-Control', cacheControlValue)
  // Adicionado flags necessárias para o Vercel
  res.setHeader('X-Vercel-Cache', 'HIT')
}

export default cacheControl
