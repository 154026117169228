<template>
  <div class="fb-checkout-item">
    <div class="columns is-mobile">
      <div class="column is-half">
        <div class="title">{{ item.product.title }}</div>
        <div class="subtitle">{{ planIntervalInfo }}</div>
        <div v-if="item.plan.price.discount" class="subtitle">
          <div v-if="item.coupon">
            CUPOM <b>{{ item.coupon.name }}</b>
          </div>
          <div>
            Desconto de
            <b>{{ item.plan.price.discount.discount | currency }}</b>
          </div>
        </div>
      </div>
      <div class="column fb-checkout-item-resume-price">
        <div v-if="!hidePrice" class="fb-checkout-item-price">
          R$ <b>{{ item.plan.price.final_price | currency({ symbol: '' }) }}</b>
        </div>
        <div v-if="!hidePrice" class="fb-checkout-item-period">
          <span class="period">{{ recurring }}</span>
        </div>
        <button
          v-if="!onlyRead"
          @click="() => removePlan(item.plan)"
          class="delete is-small fb-checkout-item-delete"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CourseCardMixins from '~/utils/mixins/CourseCardMixins'
export default {
  extends: CourseCardMixins,
  props: {
    item: {},
    onlyRead: {
      type: Boolean,
      default: false
    },
    hidePrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    plan() {
      return this.item.plan
    },
    planIntervalInfo() {
      if (this.plan.type === 'closed_period') {
        return 'Turma de Agosto'
      }
      switch (this.plan.interval_count) {
        case 1:
          return 'Plano Mensal'
        case 6:
          return 'Plano Semestral'
        case 12:
          return 'Plano Anual'
        default:
          return ''
      }
    },
    ...mapGetters('cart', ['totalItems'])
  },
  methods: {
    async removePlan(plan) {
      await this.$store.dispatch('cart/removePlan', plan)
      if (!this.totalItems) {
        this.$router.replace('/carrinho')
      }
    }
  }
}
</script>

<style lang="scss">
.fb-checkout-item {
  position: relative;
  border-bottom: 1px solid $grey-lighter;
  padding: $gap * 2 $gap * 4.25;
  margin: 0 -0.75rem;
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #484a4b !important;
    letter-spacing: 0.14px;
  }
  .subtitle {
    font-size: 12px;
    color: $grey;
    text-transform: uppercase;
  }
  &-resume-price {
    padding: 0.75rem $gap * 3;
  }
  &-price,
  &-period {
    text-align: right;
    margin-right: $gap;
  }
  &-price {
    b {
      font-size: 16px;
      font-weight: bold;
      color: #09456f;
    }
  }
  &-period {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .period {
      font-size: 12px;
      color: #a3a3a3;
    }
  }
  &-delete {
    position: absolute;
    top: $gap;
    right: $gap * 4;
    background-color: $info;
    &:hover {
      background-color: transparentize($info, 0.25);
    }
  }
}
</style>
