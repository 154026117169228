// Doc: https://support.google.com/tagmanager/answer/7679219?hl=en
// Doc: https://www.facebook.com/business/help/402791146561655?id=1205376682832142

import { v4 as uuidv4 } from 'uuid'
import { toHash } from '~/utils/strings'

function gtag() {
  window.dataLayer.push(arguments)
}

export function trackEvent(eventName, data) {
  // Emitindo para Google Analytics
  if (global.dataLayer && typeof global.dataLayer === 'object') {
    const payload = {
      event: eventName,
      google_tag_params: {}
    }

    if (data) {
      payload.google_tag_params = Object.assign(payload.google_tag_params, data)
    }

    const currentStudent = self?.$nuxt?.$store?.getters?.currentStudent

    if (currentStudent?.email) {
      payload.user_data = {
        email_address: toHash(currentStudent.email),
        phone_number: toHash(currentStudent.phone || ''),
        first_name: toHash(currentStudent.first_name || ''),
        last_name: toHash(currentStudent.last_name || ''),
        birthdate: toHash(currentStudent.birthdate || ''),
        gender: toHash(currentStudent.gender || '')
      }
    }

    // Emitindo evento em GA4
    // eslint-disable-next-line no-undef
    gtag('event', eventName, {
      'x-fb-event_id': uuidv4(),
      event_time: self?.$nuxt.$dayjs().unix(),
      ...payload,
      ...data
    })
  }
}
