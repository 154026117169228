<template>
  <div v-if="isAuthenticated" class="fb-my-courses">
    <div class="fb-my-courses-welcome">
      <div class="title is-spaced">
        Olá, <strong>{{ currentStudent.first_name }}</strong
        >!
      </div>
      <div v-if="myCourses.length" class="has-text-centered">
        <div class="subtitle">
          Continue estudando!
        </div>
        <b-button
          @click="toPlatform"
          type="is-info"
          rounded
          size="is-large"
          expanded
          style="max-width: 20rem;"
          class="mx-auto"
        >
          Ir para plataforma
        </b-button>
      </div>
      <div v-else class="subtitle">
        Se você ainda não iniciou nenhum curso, selecione um abaixo e teste
        grátis <br />
        nosso <b>plano de estudos</b> personalizado, <b>simulados</b> e
        <b>videoaulas</b>.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMyProducts } from '@/services/products'

export default {
  data() {
    return {
      myCourses: []
    }
  },
  computed: {
    ...mapGetters(['currentStudent', 'isAuthenticated'])
  },
  async created() {
    this.myCourses = await getMyProducts(this)
  },
  methods: {
    toPlatform() {
      location.assign(process.env.baseClassroomUrl)
    },
    isActive(product) {
      // O curso eh ativo se a data final de acesso exisitr e for maior que hoje.
      return (
        product.access_ends_at && this.$dayjs().isBefore(product.access_ends_at)
      )
    }
  }
}
</script>

<style lang="scss">
.fb-my-courses {
  &-welcome {
    padding: ($gap * 4) 0px;
    .title,
    .subtitle {
      text-align: center;
    }
    .title {
      font-size: 24px;
      strong {
        font-weight: 800;
      }
    }
  }
  .fb-course {
    padding: $gap * 3;
    transition: box-shadow 0.5s;
    &:hover {
      box-shadow: $card-box-shadow-hover;
    }
    .title {
      font-size: 14px;
      font-weight: 800;
      text-transform: uppercase;
      color: #484a4b;
      margin-bottom: $gap * 3;
    }
    .subtitle {
      font-size: 14px;
      color: #5c5c5c;
      .active {
        color: green;
      }
      .inactive {
        color: red;
      }
    }
  }
  [role='button'] {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
