// https://nosir.github.io/cleave.js/
import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.br'

// const emit = (vnode, name, data) => {
//   const handlers =
//     (vnode.data && vnode.data.on) ||
//     (vnode.componentOptions && vnode.componentOptions.listeners)

//   if (handlers && handlers[name]) {
//     handlers[name].fns(data)
//   }
// }

// setTimeout(() => {
//   const value = input._vCleave.getFormattedValue()
//   emit(vnode, 'input', value)
// }, 400)

/**
 * We add a new instance of Cleave when the element
 * is bound and destroy it when it's unbound.
 */
const cleave = {
  name: 'cleave',
  bind(el, binding, vnode) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default cleave
