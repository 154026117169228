// Perfis de Pagamento:
// Cartoes de credito do aluno.
// O sistema considera apenas o ultimo perfil de pagamento cadastrado.
export async function getPaymentProfiles({ $axios }) {
  try {
    const { data } = await $axios.get('api/student/v1/payment_profiles')
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log(error)
    return []
  }
}
