<template>
  <div class="fb-active-campaing-whatsapp-button">
    <button @click.stop="toggle" class="fab-button" aria-label="Whatsapp">
      <b-icon icon="icon-whatsapp" pack="custom" />
    </button>
  </div>
</template>

<script>
import { addScript, removeScript } from '~/utils/dom'
export default {
  data() {
    return {
      visible: false
    }
  },
  computed: {
    formId() {
      return process.env.activeCampaignWppFormId
    }
  },
  mounted() {},
  methods: {
    toggle() {
      this.visible = !this.visible
      if (this.visible) {
        addScript(
          'whatsapp-form',
          'https://fbonline.activehosted.com/f/embed.php?id=1',
          this.onLoad
        )
      } else {
        removeScript('whatsapp-form')
      }
    },
    onLoad() {
      const wppform = document.querySelector('.' + this.formId)

      wppform.parentNode &&
        wppform.parentNode.setAttribute('aria-visible', this.visible)

      wppform.querySelector('._close').addEventListener('click', this.toggle)
    }
  }
}
</script>

<style lang="scss">
._form-wrapper {
  &[aria-visible='true'] {
    visibility: visible;
    pointer-events: all;
  }
  visibility: hidden;
  pointer-events: none;
  > form {
    top: auto !important;
    left: auto !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    @media screen and (max-width: $tablet) {
      position: fixed !important;
      right: 1.5rem !important;
      bottom: -0.75rem !important;
    }
  }
  ._form-branding {
    display: none;
  }
}
.fb-active-campaing-whatsapp-button {
  .fab-button {
    @include reset-button;
    background-color: #25d366;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 2rem;
    bottom: 4rem;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: $radius-rounded;
    box-shadow: 3px 3px 8px transparentize($color: $grey-darker, $amount: 0.5);
    z-index: 5;
    .icon {
      color: $white;
      i {
        font-size: 2.5rem;
      }
    }
  }
}
</style>
