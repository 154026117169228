<template>
  <div class="card card-study-material card--shadow">
    <div class="card-header">
      <div class="card-header-title">Material de Estudo</div>
    </div>
    <div class="card-content">
      <!-- <b-button @click="perPage += 1" type="is-text" class="load-more"
        >Ver mais</b-button
      > -->
      <b-collapse
        v-for="(discipline, index) in disciplines"
        :key="index.toString()"
        :open="index === 0"
        class="card card--shadow mb-2"
      >
        <div
          slot="trigger"
          slot-scope="props"
          :class="props.open ? 'opened' : ''"
          class="card-content"
          role="button"
        >
          <div class="title ">{{ discipline.name }}</div>
          <div class="subtitle">
            {{ discipline.discipline_modules.length }}
            {{
              discipline.discipline_modules.length > 1 ? 'módulos' : 'módulo'
            }}
          </div>
          <div class="card-trigger-icon">
            <b-icon
              v-if="props.open"
              icon="icon-menos"
              pack="custom"
              size="is-normal"
              type="is-white"
            />
            <b-icon
              v-else
              icon="icon-mais"
              pack="custom"
              size="is-normal"
              type="is-white"
            />
          </div>
        </div>
        <div class="card-content">
          <div class="content">
            <div
              v-for="disciplineModule in discipline.discipline_modules"
              class="modules columns is-mobile is-vcentered"
            >
              <b-icon icon="icon-videoaula" pack="custom" size="is-medium">
              </b-icon>
              <div class="column">
                <div class="module-title">{{ disciplineModule.name }}</div>
                <div class="module-subtitle">
                  {{ disciplineModule.total_lessons }}
                  {{ disciplineModule.total_lessons > 1 ? 'Aulas' : 'Aula' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disciplines: Array
  },
  data() {
    return {
      perPage: 1,
      isOpen: -1
    }
  }
}
</script>

<style lang="scss">
.card-study-material {
  .collapse-trigger {
    display: block;
    height: 64px;
  }
  .card-content {
    padding: $gap * 2;
    padding-bottom: $gap * 1.5;
    margin-top: $gap * -1;
    position: relative;
    &.opened {
      border-bottom: 1px solid #ccc;
      padding-bottom: 0px;
    }
    .card-header-icon {
      position: absolute;
      top: $gap;
      right: $gap;
      padding: 0px;
    }
    .modules {
      margin: $gap * 0.5 0px;
      padding: $gap 0px;
      &:last-child {
        padding: 0px;
        margin-bottom: 0px;
      }
      .module-title {
        font-size: 14px;
        font-weight: bold;
      }
      .module-subtitle {
        font-size: 12px;
        font-weight: normal;
      }
      &:not(:last-child) {
        border-bottom: 1px solid transparentize(#a3a3a3, 0.7);
      }
      .icon {
        margin-right: $gap;
        .custom::before {
          font-size: 24px;
          color: $grey-light;
        }
      }
    }
  }

  .collapse-content {
    .card-content {
      padding: $gap $gap * 2;
    }
  }
  .title {
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: $gap * 0.5;
  }
  .subtitle {
    font-size: 12px;
    font-weight: 400;
    color: $text;
  }
  .load-more {
    display: block;
    margin: ($gap * 2) auto 0px;
  }
  .card-content {
    position: relative;
    .card-trigger-icon {
      @include collapse-trigger-with-add-minus;
      margin: 0px;
      margin-top: $gap * 2.5;
    }
  }
}
</style>
