<template>
  <div class="fb-switch-navigation">
    <b-field>
      <b-radio-button
        :value="input"
        v-for="link in links"
        @input="onChangeInput"
        :key="link.url"
        :native-value="link.url"
        type="is-info"
      >
        <span>{{ link.name }}</span>
      </b-radio-button>
      {{ input.name }}
    </b-field>
  </div>
</template>

<script>
import each from 'lodash/each'
export default {
  props: {
    links: Array,
    default: String
  },
  data() {
    return {
      input: null
    }
  },
  created() {
    this.input = this.default
  },
  methods: {
    onChangeInput(link) {
      this.$set(this, 'input', link)

      const query = []

      each(this.$route.query, (value, key) => {
        query.push(`${key}=${value}`)
      })

      const url = `${link}?${query.join('&')}`

      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss">
.fb-switch-navigation {
  position: relative;
  align-items: center;
  justify-content: center !important;
  background-color: $info-light;
  max-width: ($gap * 36);
  margin: 0px auto;
  border-radius: ($gap * 6) !important;
  z-index: 3;
  .control {
    flex: 1;
  }
  .button {
    border: none;
    border-radius: ($gap * 6) !important;
    margin: 0px;
    width: 100%;
    height: 37px;
    padding: ($gap * 2) ($gap * 4);
    &:not(.is-info) {
      background-color: transparent;
      color: transparentize($info, 0.4);
      font-weight: normal;
    }
    &:last-child,
    &:first-child {
      border-radius: ($gap * 6) !important;
    }
  }
}
</style>
