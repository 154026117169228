<template>
  <div class="is-product-card">
    <p class="title fb-course-card--price">
      <span class="currency">R$</span>
      <span class="price">{{ getPrice.integer }}</span>
      <span class="cents">,{{ getPrice.decimal }}/ mês</span>
      <span v-if="!isPage && !isRecurring" class="total_price">
        Total <b>{{ getPeriod.plan.price.final_price | currency }}</b>
      </span>
      <span v-else class="total_price">
        Todo mês - recorrente
      </span>
    </p>

    <fb-course-card-period-select
      :input="period"
      :periods="product.plans"
      @change="change"
    ></fb-course-card-period-select>

    <ul class="fb-course-card--description">
      <li v-for="o in product.offer" :key="o">
        {{ o }}
      </li>
    </ul>

    <div class="card-content--buttons">
      <b-button
        @click="() => addPlan(getPeriod)"
        type="is-info"
        expanded
        rounded
        outlined
        size="is-large"
        icon-left="icon-add-carrinho"
        icon-pack="custom"
        >Adicionar ao Carrinho</b-button
      >
      <b-button
        @click.stop="knowBetter"
        type="is-info"
        rounded
        expanded
        size="is-large"
        >Testar Grátis</b-button
      >
    </div>
  </div>
</template>

<script>
import CardMixin from './CardMixin.vue'
export default {
  extends: CardMixin,
  props: {
    isPage: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    change(newPeriod) {
      this.$emit('change', newPeriod)
    }
  }
}
</script>

<style></style>
