import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _452ea862 = () => interopDefault(import('../pages/cadastro.vue' /* webpackChunkName: "pages/cadastro" */))
const _69d28ea3 = () => interopDefault(import('../pages/carrinho/index.vue' /* webpackChunkName: "pages/carrinho/index" */))
const _3620c042 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _16aaa4fa = () => interopDefault(import('../pages/crednosso.vue' /* webpackChunkName: "pages/crednosso" */))
const _4d06ffef = () => interopDefault(import('../pages/esqueci-a-senha.vue' /* webpackChunkName: "pages/esqueci-a-senha" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0dd5f592 = () => interopDefault(import('../pages/professores.vue' /* webpackChunkName: "pages/professores" */))
const _5dbf20e6 = () => interopDefault(import('../pages/sair.vue' /* webpackChunkName: "pages/sair" */))
const _4252ff3c = () => interopDefault(import('../pages/suporte.vue' /* webpackChunkName: "pages/suporte" */))
const _117319b1 = () => interopDefault(import('../pages/checkout/boleto-gerado.vue' /* webpackChunkName: "pages/checkout/boleto-gerado" */))
const _3fad8431 = () => interopDefault(import('../pages/checkout/pagamento-efetuado.vue' /* webpackChunkName: "pages/checkout/pagamento-efetuado" */))
const _2f66cf9f = () => interopDefault(import('../pages/curso/404.vue' /* webpackChunkName: "pages/curso/404" */))
const _4780de2e = () => interopDefault(import('../pages/links/termos-de-uso-e-politica.vue' /* webpackChunkName: "pages/links/termos-de-uso-e-politica" */))
const _4e96ef7e = () => interopDefault(import('../pages/super/404.vue' /* webpackChunkName: "pages/super/404" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _afec2100 = () => interopDefault(import('../pages/afiliados/_slug/index.vue' /* webpackChunkName: "pages/afiliados/_slug/index" */))
const _8059a40c = () => interopDefault(import('../pages/curso-avulso/_id.vue' /* webpackChunkName: "pages/curso-avulso/_id" */))
const _75fb6721 = () => interopDefault(import('../pages/curso/_id.vue' /* webpackChunkName: "pages/curso/_id" */))
const _d5a8f200 = () => interopDefault(import('../pages/super/_id.vue' /* webpackChunkName: "pages/super/_id" */))
const _01f2dd6c = () => interopDefault(import('../pages/afiliados/_slug/_ticket/checkout.vue' /* webpackChunkName: "pages/afiliados/_slug/_ticket/checkout" */))
const _475960ae = () => interopDefault(import('../pages/afiliados/_slug/_ticket/confirmed.vue' /* webpackChunkName: "pages/afiliados/_slug/_ticket/confirmed" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cadastro",
    component: _452ea862,
    name: "cadastro"
  }, {
    path: "/carrinho",
    component: _69d28ea3,
    name: "carrinho"
  }, {
    path: "/checkout",
    component: _3620c042,
    name: "checkout"
  }, {
    path: "/crednosso",
    component: _16aaa4fa,
    name: "crednosso"
  }, {
    path: "/esqueci-a-senha",
    component: _4d06ffef,
    name: "esqueci-a-senha"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login"
  }, {
    path: "/professores",
    component: _0dd5f592,
    name: "professores"
  }, {
    path: "/sair",
    component: _5dbf20e6,
    name: "sair"
  }, {
    path: "/suporte",
    component: _4252ff3c,
    name: "suporte"
  }, {
    path: "/checkout/boleto-gerado",
    component: _117319b1,
    name: "checkout-boleto-gerado"
  }, {
    path: "/checkout/pagamento-efetuado",
    component: _3fad8431,
    name: "checkout-pagamento-efetuado"
  }, {
    path: "/curso/404",
    component: _2f66cf9f,
    name: "curso-404"
  }, {
    path: "/links/termos-de-uso-e-politica",
    component: _4780de2e,
    name: "links-termos-de-uso-e-politica"
  }, {
    path: "/super/404",
    component: _4e96ef7e,
    name: "super-404"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }, {
    path: "/afiliados/:slug",
    component: _afec2100,
    name: "afiliados-slug"
  }, {
    path: "/curso-avulso/:id?",
    component: _8059a40c,
    name: "curso-avulso-id"
  }, {
    path: "/curso/:id?",
    component: _75fb6721,
    name: "curso-id"
  }, {
    path: "/super/:id?",
    component: _d5a8f200,
    name: "super-id"
  }, {
    path: "/afiliados/:slug?/:ticket/checkout",
    component: _01f2dd6c,
    name: "afiliados-slug-ticket-checkout"
  }, {
    path: "/afiliados/:slug?/:ticket/confirmed",
    component: _475960ae,
    name: "afiliados-slug-ticket-confirmed"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
