<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="layout-lite"
  >
    <CheckoutFlowHeader />

    <b-navbar
      :mobile-burger="false"
      type="is-primary"
      wrapper-class="container"
      class="fb-site-checkout-flow-navbar"
      fixed-top
      shadow
    >
      <template slot="brand">
        <b-navbar-item
          :to="{ path: '/' }"
          class="navbar-brand--fb-logo"
          tag="nuxt-link"
        >
          <img
            src="~/assets/images/logo-white-border.png"
            alt="FB Online"
            width="100px"
          />
        </b-navbar-item>
      </template>
      <template slot="start">
        <Progress v-model="progress" :has-label="true" />
      </template>
    </b-navbar>

    <nuxt-child :progress="progress" @progress="onProgressChange" />

    <Footer :lite="true" />

    <client-only>
      <fb-site-interventional-modal />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CheckoutFlowHeader from '@/components/site/CheckoutFlowHeader'
import Footer from '@/components/site/Footer'

import Progress from '@/components/associate/Progress'

export default {
  components: { CheckoutFlowHeader, Footer, Progress },
  data() {
    return {
      progress: 0
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm', 'md'].includes(this.$mq)
    },
    ...mapState({
      label: (state) => state.checkoutProgressLabel
    })
  },
  methods: {
    onProgressChange(number) {
      this.progress = number
    }
  }
}
</script>
<style lang="scss">
.layout-lite {
  &.is-desktop {
    .lite-page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: ($gap * 8) 0px ($gap * 10);
    }
  }
  .fb-site-checkout-flow-navbar {
    margin-bottom: $gap * 6;
    .navbar-start {
      flex: 1;
      margin: auto 0px auto -118px;
      pointer-events: all;
      @media screen and (max-width: $desktop) {
        display: flex;
      }
      .fb-checkout-progress {
        padding-top: 0px;
        padding-bottom: $gap * 3;
      }
    }
    @media (max-width: $tablet) {
      display: none;
    }
  }
}
</style>
