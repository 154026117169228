<template>
  <div
    v-if="product"
    :class="{ 'is-page': isPage, 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="card fb-card-course"
  >
    <nuxt-link
      v-if="!isPage"
      :title="product.title"
      :to="`/curso-avulso/${product.slug}`"
      class="fb-card-course--link"
    ></nuxt-link>
    <div class="card-heading">
      <div
        :style="{
          'background-image': `url(${sampleHeading})`
        }"
        class="card-heading-background"
      ></div>
      <fb-detached-course-title
        :is-page="isPage"
        :product="product"
      ></fb-detached-course-title>
    </div>
    <!-- card-content -->
    <div v-if="mobile || !isPage" class="card-content">
      <div class="fb-card-course--info columns is-vcentered is-mobile">
        <div
          v-if="!isPage"
          :class="{ 'p-0': !isPage }"
          class="column fb-card-course--tags"
        >
          <b-tag
            v-for="(tag, index) in tagList"
            :key="index.toString()"
            type="is-warning"
            rounded
            >{{ tag }}</b-tag
          >
        </div>
        <div v-if="isPage" class="column is-full fb-card-course--price">
          Por:
          <strong>{{ price.final_price | currency({ symbol: '' }) }}</strong>
          {{ recurring }} <br />
          Acesso ao curso por <b>{{ plan.interval_count }}</b> meses
        </div>
        <div v-else class="column fb-card-course--price">
          <span v-if="price.discount" class="discount">{{
            price.full_price | currency
          }}</span>
          Por:
          <strong>{{ price.final_price | currency({ symbol: '' }) }}</strong>
        </div>
      </div>

      <div v-if="!isPage" class="detached-course-divider"></div>

      <div v-if="!isPage || mobile" class="fb-card-course--actions ">
        <div class="columns is-mobile">
          <div class="column">
            <b-button
              @click="addPlan"
              type="is-info"
              rounded
              outlined
              expanded
              size="is-medium"
              icon-left="icon-add-carrinho"
              icon-pack="custom"
            >
              Carrinho
            </b-button>
          </div>
          <div class="column">
            <b-button
              @click="buyNow"
              type="is-info"
              rounded
              expanded
              size="is-medium"
              >Comprar</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-content">
      <slot name="desktop-content"></slot>
    </div>
    <!-- end-card-content -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import take from 'lodash/take'
import CourseCardMixins from '~/utils/mixins/CourseCardMixins'

export default {
  mixins: [CourseCardMixins],
  props: {
    isPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sampleHeading: require('~/assets/images/sample-course-card.png')
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    tagList() {
      // Lista apenas a 2 primeiras tags do produto.
      return take(this.product.tag_list, 2)
    },
    getPeriod() {
      if (this.product && this.product.plans) {
        const { slug, title, type } = this.product
        return {
          plan: this.product.plans[0],
          product: {
            slug,
            title,
            type
          }
        }
      }
      return {}
    }
  },
  methods: {
    addPlan() {
      this.$addPlan(this.getPeriod)
    },
    ...mapActions({
      $addPlan: 'cart/addPlan'
    })
  }
}
</script>

<style lang="scss">
.fb-card-course {
  position: relative;
  user-select: none;
  box-shadow: $card-box-shadow;
  min-height: ($gap * 40);
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: $card-box-shadow-hover;
  }
  .card-content {
    padding-bottom: 0px;
  }
  &.is-page:not(.is-desktop) {
    max-width: none;
    margin-left: ($gap * -2) !important;
    margin-right: ($gap * -2) !important;
    .card-heading::before {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .fb-card-course__title {
      margin: 0px ($gap * 5) ($gap * -6) !important;
    }
    .card-heading {
      position: relative;
      background: darken($secondary, 5);
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .fb-card-course--actions {
      padding: 0px ($gap * 2) ($gap * 2);
    }
    .fb-card-course--price {
      text-align: center;
      line-height: 1.5;
      margin: 4px auto ($gap * 2);
      strong {
        margin: 0px 4px;
      }
    }
  }
  .card {
    &-heading {
      position: relative;
      height: 155px;
      border-top-left-radius: $card-radius;
      border-top-right-radius: $card-radius;
      &-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: ($gap * 15);
        background-size: cover;
        background-position: center;
        z-index: 0;
        border-top-left-radius: $card-radius;
        border-top-right-radius: $card-radius;
      }
      .fb-card-course__title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0px ($gap * 2) ($gap * -6);
        z-index: 2;
      }
    }
  }
  .detached-course-divider {
    @include divider($border-width: 1px);
    border-bottom-width: 1px;
    margin-bottom: 0px;
  }
  &--info {
    margin-top: ($gap * 6);
    margin-bottom: 0px !important;
    align-items: flex-end;
  }
  &--tags {
    .tag {
      background-color: $tag-bg-color !important;
      color: $tag-text-color !important;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.25rem;
      margin-right: $gap * 0.5;
    }
  }
  &--price {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    strong {
      font-size: 24px;
      color: $info;
      margin-left: $gap;
    }
    .discount {
      position: absolute;
      top: -4px;
      right: $gap * 1.5;
      text-decoration: line-through;
      color: $grey-light;
    }
  }
  &--actions {
    position: relative;
    padding: $gap * 2 0px;
    z-index: 5;
    .column {
      padding: 0.5rem;
    }
    .button {
      font-size: 12px;
      .icon > i::before {
        font-size: 18px;
      }
    }
  }
  &--link {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }
  &.is-page {
    &.is-desktop {
      border-radius: $card-radius;
      overflow: hidden;
      .card-heading,
      .card-heading-background {
        height: 280px;
      }
    }
    &.is-mobile {
      .fb-card-course--price {
        display: block;
      }
    }
  }
}
</style>
