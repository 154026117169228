import creditCardType from 'credit-card-type'

// https://gist.github.com/erikhenrique/5931368

export function isCreditCardTypeOf(str, type) {
  const cards = creditCardType(str.replace(' ', ''))

  if (cards.length) {
    return cards.some((card) => card.type === type)
  }

  return false
}

// DECREAPTED
// export function isElo(str) {
//   if (!str) return false

//   let digits = str.match(/\d+/g)

//   if (!digits) {
//     return false
//   }

//   digits = digits.map(Number).join('')

//   return /^(636368|636369|438935|504175|451416|636297|5067|4576|4011|506699)\d+$/.test(
//     digits
//   )
// }

// export function isVisa(str) {
//   if (!str) return false

//   let digits = str.match(/\d+/g)

//   if (!digits) {
//     return false
//   }

//   digits = digits.map(Number).join('')

//   return /^(4)\d+$/.test(digits)
// }

// export function isMaster(str) {
//   if (!str) return false

//   let digits = str.match(/\d+/g)

//   if (!digits) {
//     return false
//   }

//   digits = digits.map(Number).join('')

//   return /^(5)\d+$/.test(digits)
// }

// export function isDiners(str) {
//   if (!str) return false

//   let digits = str.match(/\d+/g)

//   if (!digits) {
//     return false
//   }

//   digits = digits.map(Number).join('')

//   return /^(301|305|36|38)\d+$/.test(digits)
// }

// export function isAmex(str) {
//   if (!str) return false

//   let digits = str.match(/\d+/g)

//   if (!digits) {
//     return false
//   }

//   digits = digits.map(Number).join('')

//   return /^(34|37)\d+$/.test(digits)
// }

// export function isHiper(str) {
//   if (!str) return false

//   let digits = str.match(/\d+/g)

//   if (!digits) {
//     return false
//   }

//   digits = digits.map(Number).join('')

//   return /^(606282\d{10}(\d{3})?)|(3841\d{15})/.test(digits)
// }
