<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="fb-checkout-pay-with-credit-card"
  >
    <div
      :class="{ 'card--shadow': mobile, 'card--no-shadow': !mobile }"
      class="card"
    >
      <div class="card-header">
        <div class="title">
          <span v-if="recurring.length && notRecurring.length">
            Pagamento Recorrente e Parcelamento
          </span>
          <span v-else-if="recurring.length">Pagamento Recorrente</span>
          <span v-else>
            Escolha a quantidade de parcelas
          </span>
        </div>
      </div>
      <div class="card-content">
        <p
          v-if="needsMoreThanOneSubscription()"
          class="fb-checkout-pay-with-credit-card-description"
        >
          O seu pagamento foi dividido pois existem itens recorrentes.
        </p>
        <div
          v-if="recurring.length"
          class="fb-checkout-pay-with-credit-card-item card card--shadow"
        >
          <div class="card-header">
            <div class="title">
              Pagamento Mensal Recorrente
            </div>
          </div>
          <div class="card-content">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <div class="fb-checkout-pay-with-credit-card-item-total">
                  <span v-if="recurring.length == 1">1 item</span>
                  <span v-else> {{ recurring.length }} items </span>
                </div>
              </div>
              <div class="column has-text-right">
                <div class="fb-checkout-pay-with-credit-card-item-price">
                  R$ <b>{{ finalPriceRecurring | currency({ symbol: '' }) }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="notRecurring.length"
          class="fb-checkout-pay-with-credit-card-item card card--shadow"
        >
          <div class="card-header">
            <div class="title">
              Parcelamento de itens não recorrentes
            </div>
          </div>
          <div class="card-content">
            <b-collapse
              :open.sync="isOpenInstallment"
              aria-id="selectTotalInstallment"
            >
              <div slot="trigger">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <div class="fb-checkout-pay-with-credit-card-item-total">
                      <span v-if="notRecurring.length == 1">
                        1 item
                      </span>
                      <span v-else>{{ notRecurring.length }} Itens</span>
                    </div>
                  </div>
                  <div class="column has-text-right">
                    <div
                      class="fb-checkout-pay-with-credit-card-item-installment"
                    >
                      {{ installment }}x
                    </div>
                    <div class="fb-checkout-pay-with-credit-card-item-price">
                      R$
                      <b>{{
                        notRecurringPerMonth | currency({ symbol: '' })
                      }}</b>
                    </div>
                    <b-icon
                      :icon="isOpenInstallment ? 'chevron-up' : 'chevron-down'"
                    />
                  </div>
                </div>
              </div>
              <section>
                <!-- Lista de valores para cada parcela -->
                <div v-for="i in installments" class="field">
                  <b-radio
                    v-model="installment"
                    :native-value="i.installment"
                    type="is-info"
                    size="is-medium"
                  >
                    <b>{{ i.installment }}x</b>
                    {{ finalPriceNotRecurringPerInstallment(i) | currency }}
                    <!-- Pagamentos de 1x tem um desconto adicional -->
                    <small v-if="i.installment === 1" class="gap-left">
                      {{ i.additional_discount }} de desconto</small
                    >
                  </b-radio>
                </div>
              </section>
            </b-collapse>
          </div>
        </div>
        <div class="fb-checkout-pay-with-credit-card-item card card--shadow">
          <div class="card-content">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <b>Total</b>
              </div>
              <div class="column has-text-right">
                <div class="fb-checkout-pay-with-credit-card-item-price">
                  R$
                  <b>{{ finalPrice | currency({ symbol: '' }) }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fb-checkout-pay-with-credit-card-confirmation mt-4 mb-2">
          <b-notification
            :active.sync="hasError"
            type="is-danger"
            has-icon
            role="alert"
          >
            <p>
              <b>{{ errorMessage.title }}</b> <br />
              {{ errorMessage.content }}
            </p>
          </b-notification>
          <b-button
            @click.stop="submit"
            :disabled="invalid"
            :loading="processing"
            type="is-info"
            rounded
            expanded
            size="is-large"
          >
            Pagar {{ finalPrice | currency }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { checkout } from '@/services/checkout'
import { destroyUniqSessionId } from '@/utils/cookies/session'
import { trackEvent } from '~/utils/gtm'

export default {
  props: {
    hasPersonData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      installment: 1,
      totalPrice: 0,
      processing: false,
      hasError: false,
      isOpenInstallment: true,
      errorMessage: {}
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    notRecurringPerMonth() {
      return this.$store.getters['cart/notRecurringPerMonth'](this.installment)
    },
    finalPriceNotRecurring() {
      return this.$store.getters['cart/finalPriceNotRecurring'](
        this.installment
      )
    },
    finalPrice() {
      return this.$store.getters['cart/finalPrice'](this.installment)
    },
    invalid() {
      return this.processing || !this.hasPersonData
    },
    ...mapGetters(['cart', 'currentStudent']),
    ...mapGetters('cart', [
      'recurring',
      'notRecurring',
      'finalPriceRecurring',
      'installments'
    ])
  },
  methods: {
    needsMoreThanOneSubscription() {
      return this.recurring.length > 0 && this.notRecurring.length > 0
    },
    async submit() {
      this.processing = true

      const { data } = await checkout(this, {
        id: this.cart.id,
        installments: this.installment,
        payment_method_code: 'credit_card'
      })

      if (data[0]?.success) {
        // Notificando que houve uma tentativa de compra para o facebook
        trackEvent('Purchase', {
          ecomm_pagetype: 'purchase-credit',
          ecomm_prodid: this.cart.items.map((item) => item.plan.slug),
          ecomm_pvalue: this.cart.items.map(
            (item) => item.plan.price.final_price
          ),
          ecomm_totalvalue: this.cart.resume?.total,
          ecomm_coupon: this.cart.coupon,
          ecomm_discounts: this.cart.resume?.discounts
        })

        destroyUniqSessionId(this)
        // Limpando Carrinho
        this.clearProducts()
        // Ir para uma tela de confirmação
        this.$router.push('checkout/pagamento-efetuado')
      } else {
        this.errorMessage = data.length ? data[0] : data
        this.hasError = true
      }

      this.processing = false
    },
    finalPriceNotRecurringPerInstallment(installment) {
      return this.$store.getters['cart/finalPriceNotRecurringPerInstallment'](
        installment
      )
    },
    ...mapActions('auth', ['setRemoteProfile']),
    ...mapActions('cart', ['clearProducts'])
  }
}
</script>

<style lang="scss">
.fb-checkout-pay-with-credit-card {
  user-select: none;
  .card {
    &-header {
      padding: ($gap * 3) 0px 0px;
      box-shadow: none;
      justify-content: center;
      .title {
        text-align: center;
      }
    }
  }
  &-item {
    &.card {
      margin: ($gap * 2) 0px;
      .card-header {
        justify-content: flex-start;
        border-bottom: 1px solid $grey-lighter;
        padding: $gap * 2;
        .title {
          color: $black !important;
        }
      }
      .collapse {
        section {
          margin: ($gap * 3) 0px 0px;
          .field {
            border-bottom: 1px solid $grey-lighter;
            padding: 0px;
            margin-bottom: 0px !important;
            label {
              padding: ($gap * 1.5) 0px;
              width: 100%;
            }
            b {
              font-weight: bold;
              margin: 0px $gap;
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
      b {
        text-transform: uppercase;
      }
    }
    &-total {
      text-transform: uppercase;
      color: $grey;
    }
    &-price,
    &-installment,
    .icon {
      display: inline;
      padding-right: $gap * 2;
      .mdi::before {
        font-size: 18px;
      }
    }
    .icon {
      position: absolute;
      right: $gap;
    }
    &-price {
      b {
        font-size: 16px;
        font-weight: bold;
        color: $info;
      }
    }
    &-installment {
      margin-right: $gap;
      font-size: 14px;
      font-weight: bold;
    }
  }
  &-description {
    margin: 0px ($gap * 5);
    text-align: center;
  }
  &-confirmation {
    .button {
      font-size: 12px;
      max-width: 295px;
      margin: auto;
    }
  }
  &.is-desktop {
    padding: 0px;
  }
}
</style>
