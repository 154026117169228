<template>
  <div class="fb-course-card-collapse">
    <div
      @click="toggle"
      :class="{ 'is-active': active }"
      class="fb-course-card-collapse-trigger"
      role="button"
    >
      <div class="columns is-align-items-strech is-mobile">
        <div class="column has-icon is-3 is-flex is-justify-content-center">
          <span class="is-align-self-center">
            <b-icon
              :icon="product.icon_slug"
              pack="custom"
              size="is-large"
              type="is-info"
            />
          </span>
        </div>
        <div class="column has-info has-text-right">
          <div class="vect-row"></div>
          <div class="title is-size-3 has-text-white">
            {{ product.title }}
          </div>
          <div
            class="subtitle is-size-5 has-text-white is-inline-flex is-align-items-center"
          >
            <i class="seen-more">
              {{ active ? 'Ver menos' : 'Ver mais' }} &nbsp;</i
            >
            <i class="custom icon-expand-down is-size-4"></i>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade" mode="ease-out">
      <div v-if="active" class="fb-course-card-collapse-body">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import CardMixin from './CardMixin.vue'

export default {
  extends: CardMixin,
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      active: true
    }
  },
  methods: {
    toggle() {
      this.active = !this.active
    }
  }
}
</script>

<style lang="scss">
.fb-course-card-collapse {
  &-trigger {
    padding: 0rem;
    margin: 1.5rem 1rem;

    &.is-active {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &:hover,
    &:active {
      cursor: pointer;
    }
    .price {
      font-size: $gap * 3;
      font-weight: bold;
      letter-spacing: -0.1rem;
      word-spacing: -0.4rem;
    }
    .columns {
      overflow: hidden;
      border-radius: $card-radius;
      border: 2px solid;
    }
    .column {
      padding: 1.5rem;
      position: relative;
      &.has-info {
        background-color: #167f60;
      }
    }
    .custom {
      &.icon-ita-e-ime::before {
        font-size: 2rem;
      }
      &.icon-afa::before {
        font-size: 3.5rem;
      }
      &.icon-efom::before {
        font-size: 3.5rem;
      }
      &.icon-especex::before {
        font-size: 3.5rem;
      }
    }
  }
  &-body {
    padding: 1rem 1.5rem;
  }
  .vect-row {
    position: absolute;
    width: 13px;
    height: 150%;
    background-color: #8bbfb0;
    top: -25%;
    left: -0.5rem;
    z-index: 1;
    transform: rotate(175deg);
  }
}
</style>
