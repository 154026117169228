<template>
  <div>
    <b-modal
      v-if="isModal"
      :active.sync="visible"
      :width="495"
      :can-cancel="false"
      has-modal-card
    >
      <div class="card fb-card-modal-new-credit-card">
        <header class="card-header">
          <span class="flex"></span>
          <b-button
            @click="close"
            type="is-text"
            size="is-large"
            icon-right="icon-fechar"
            icon-pack="custom"
          />
        </header>
        <div class="card-header-title">Insira os dados do novo cartão</div>
        <div :class="{ 'is-mobile': mobile }" class="card-content">
          <fb-checkout-credit-card
            :card-brand="cardBrand"
            :card-number="card_number"
            :holder-name="holder_name"
            :card-expiration="card_expiration"
            :card-cvv="card_cvv"
            :payment-company-code="payment_company_code"
            :isCvvFocus="cvvFocus"
          ></fb-checkout-credit-card>

          <fb-checkout-credit-card-select-brand
            v-model="payment_company_code"
            :is-visible="isVisibleSelectBrand"
            :messages="messages"
          ></fb-checkout-credit-card-select-brand>

          <b-field
            :type="{ 'is-danger': messages.card_number }"
            :message="messages.card_number"
            label="Número do Cartão"
          >
            <b-input
              v-model="card_number"
              v-cleave="masks.creditCard"
              name="creditcard"
              placeholder="9999 9999 9999 9999"
              rounded
            />
          </b-field>
          <b-field
            :type="{ 'is-danger': messages.holder_name }"
            :message="messages.holder_name"
            label="Nome no cartão"
          >
            <b-input
              v-model="holder_name"
              name="name"
              placeholder="JOAO D NEVES"
              autocomplete="off"
              rounded
            />
          </b-field>
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <b-field
                :type="{ 'is-danger': messages.card_expiration }"
                :message="messages.card_expiration"
                label="Vencimento"
              >
                <b-input
                  v-model="card_expiration"
                  v-cleave="masks.expire"
                  :has-counter="false"
                  name="expire"
                  autocomplete="off"
                  placeholder="MM/AA"
                  rounded
                  maxlength="5"
                />
              </b-field>
            </div>
            <div class="column">
              <b-field
                :type="{ 'is-danger': messages.card_cvv }"
                :message="messages.card_cvv"
                label="CVV"
              >
                <b-input
                  v-model="card_cvv"
                  v-cleave="masks.cvv"
                  @focus="cvvFocus = true"
                  @blur="cvvFocus = false"
                  :maxlength="cvvMaxLen"
                  :has-counter="false"
                  name="cvv"
                  placeholder="***"
                  rounded
                />
              </b-field>
            </div>
          </div>
          <div class="fb-card-modal-new-credit-card-buttons">
            <b-button
              @click.stop="submit"
              :disabled="loading || isInvalid"
              :loading="loading"
              type="is-info"
              size="is-large"
              rounded
              expanded
            >
              Salvar Cartão
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <div v-else class="card-content fb-card-modal-new-credit-card">
      <div class="card-content">
        <fb-checkout-credit-card
          :card-brand="cardBrand"
          :card-number="card_number"
          :holder-name="holder_name"
          :card-expiration="card_expiration"
          :card-cvv="card_cvv"
          :isCvvFocus="cvvFocus"
        ></fb-checkout-credit-card>

        <fb-checkout-credit-card-select-brand
          v-model="payment_company_code"
          :is-visible="isVisibleSelectBrand"
          :messages="messages"
        ></fb-checkout-credit-card-select-brand>

        <b-field
          :type="{ 'is-danger': messages.card_number }"
          :message="messages.card_number"
          label="Número do Cartão"
        >
          <b-input
            v-model="card_number"
            v-cleave="masks.creditCard"
            name="creditcard"
            placeholder="9999 9999 9999 9999"
            rounded
          />
        </b-field>
        <b-field
          :type="{ 'is-danger': messages.holder_name }"
          :message="messages.holder_name"
          label="Nome no cartão"
        >
          <b-input
            v-model="holder_name"
            name="name"
            placeholder="JOAO D NEVES"
            autocomplete="off"
            rounded
          />
        </b-field>
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <b-field
              :type="{ 'is-danger': messages.card_expiration }"
              :message="messages.card_expiration"
              label="Vencimento"
            >
              <b-input
                v-model="card_expiration"
                v-cleave="masks.expire"
                :has-counter="false"
                name="expire"
                autocomplete="off"
                placeholder="MM/AA"
                rounded
                maxlength="5"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :type="{ 'is-danger': messages.card_cvv }"
              :message="messages.card_cvv"
              label="CVV"
            >
              <b-input
                v-model="card_cvv"
                v-cleave="masks.cvv"
                @focus="cvvFocus = true"
                @blur="cvvFocus = false"
                :maxlength="cvvMaxLen"
                :has-counter="false"
                name="cvv"
                placeholder="***"
                rounded
              />
            </b-field>
          </div>
        </div>
        <div class="fb-card-modal-new-credit-card-buttons">
          <b-button
            @click.stop="submit"
            :disabled="loading || isInvalid"
            :loading="loading"
            type="is-info"
            size="is-large"
            rounded
            expanded
          >
            Salvar Cartão
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cleave from '~/utils/directives/cleave'
import { isCreditCardTypeOf } from '~/utils/creditcards'

export default {
  directives: { cleave },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      payment_company_code: null,
      visible: false,
      card_number: null,
      holder_name: '',
      card_expiration: '',
      card_cvv: '',
      cvvFocus: false,
      cvvMaxLen: 3,
      loading: false,
      messages: {},
      masks: {
        creditCard: {
          creditCard: true
        },
        expire: {
          delimiters: ['/'],
          blocks: [2, 2],
          numericOnly: true
        },
        cvv: {
          blocks: [4],
          numericOnly: true
        }
      }
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    creditCardNumber() {
      const numbers = this.number.replace(/\s/g, '').split('')
      return numbers
    },
    maskCVV() {
      return this.cvv
        .split()
        .map((l) => '*')
        .join('')
    },
    cardBrand() {
      if (this.payment_company_code) {
        try {
          return require(`~/assets/images/credit-card-brand/${this.payment_company_code}.svg`)
        } catch (error) {
          return ''
        }
      }
      return ''
    },
    isInvalid() {
      const { card_number, holder_name, card_expiration, card_cvv } = this

      const errors = []

      errors[0] = !card_number
      errors[1] = !holder_name
      errors[2] = !card_expiration && card_expiration.length === 5
      errors[3] = !card_cvv
      // errors[4] = !payment_company_code

      return errors.some((e) => e)
    },
    isVisibleSelectBrand() {
      if (typeof this.card_number === 'string') {
        return (
          this.card_number.length !== 0 &&
          this.card_number.length > 4 &&
          !this.payment_company_code
        )
      }
      return false
    }
  },
  watch: {
    card_number(number) {
      this.cvvMaxLen = 3

      if (!number) {
        return
      }

      if (typeof number === 'string' && number.length === 0) {
        this.payment_company_code = null
      }

      if (isCreditCardTypeOf(number, 'elo')) {
        this.payment_company_code = 'elo'
      } else if (isCreditCardTypeOf(number, 'visa')) {
        this.payment_company_code = 'visa'
      } else if (isCreditCardTypeOf(number, 'mastercard')) {
        this.payment_company_code = 'mastercard'
      } else if (isCreditCardTypeOf(number, 'diners-club')) {
        this.payment_company_code = 'diners_club'
      } else if (isCreditCardTypeOf(number, 'hipercard')) {
        this.payment_company_code = 'hipercard'
      } else if (isCreditCardTypeOf(number, 'american-express')) {
        this.payment_company_code = 'american_express'
        this.cvvMaxLen = 4
      } else {
        this.payment_company_code = null
        this.cvvMaxLen = 3
      }
    },
    visible(visible) {
      if (!visible) {
        this.card_number = ''
        this.holder_name = ''
        this.card_expiration = ''
        this.card_cvv = ''
      }
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    async submit() {
      const vindi_public_key = process.env.vindiApiPublicKey
      const vindi_public_url =
        process.env.vindiApiPublicBaseUrl + '/payment_profiles'

      const {
        card_number,
        holder_name,
        card_expiration,
        card_cvv,
        payment_company_code
      } = this

      const payload = {
        payment_method_code: 'credit_card',
        payment_company_code,
        card_number,
        card_expiration,
        card_cvv,
        holder_name: holder_name.toUpperCase()
      }

      try {
        this.loading = true

        const { data } = await this.$axios({
          method: 'POST',
          foreign_request: true,
          url: vindi_public_url,
          data: payload,
          headers: {
            Authorization: 'Basic ' + btoa(vindi_public_key + ':')
          }
        })

        this.$emit('close', data.payment_profile)

        this.visible = false
      } catch ({ response }) {
        if (response) {
          const { data } = response
          const errors = {}
          if (data.errors) {
            data.errors.forEach(({ parameter, message }, index, array) => {
              errors[parameter] = message
            })

            this.$set(this, 'messages', errors)
          }
        }
      } finally {
        this.loading = false
      }
    },
    close() {
      this.visible = false
      this.$emit('close', false)
    }
  }
}
</script>

<style lang="scss">
.fb-card-modal-new-credit-card {
  max-width: 95%;
  margin: auto;

  .card-content {
    padding: 1rem $gap * 3 $gap * 4;
  }
  > .card-header {
    padding: ($gap * 2) ($gap * 2) 0px !important;
    box-shadow: none;
    &-title {
      display: block;
      padding-top: $gap;
      font-weight: 800;
      text-align: center;
      text-transform: uppercase;
      color: $info;
      font-size: 14px;
    }
    .icon .custom::before {
      color: $black;
      font-size: 1.25rem;
    }
  }
  .is-mobile {
    .credit-card {
      width: $gap * 30;
      height: $gap * 16;
    }
  }
}

.modal {
  .fb-card-modal-new-credit-card {
    width: 100vw;
  }
}
.collapse-content {
  .fb-card-modal-new-credit-card {
    width: 100%;
    padding: $gap * 2 0px !important;
    &-buttons {
      width: 100%;
    }
  }
  .field {
    width: 100%;
  }
}

@media screen and (max-width: $tablet) {
  .fb-card-modal-new-credit-card {
    .credit-card-numbers {
      margin-bottom: $gap;
      .column {
        letter-spacing: $gap * 0.25;
      }
    }
    .credit-card-brand {
      top: $gap * 0.75;
      left: $gap * 2;
      img {
        width: $gap * 3.5;
      }
    }
  }
}
</style>
