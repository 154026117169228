<template>
  <div>
    <SiteContainer></SiteContainer>
    <Navbar></Navbar>
    <div
      :class="{
        'is-mobile': mobile,
        'is-desktop': !mobile
      }"
    >
      <nuxt />
    </div>
    <SiteFooter></SiteFooter>
    <ActiveCampaignWhatsappButton />
    <client-only>
      <fb-site-interventional-modal />
      <b-modal
        :active.sync="isCartModalVisible"
        :on-cancel="closeCartModal"
        :can-cancel="false"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <fb-cart-product-added-success />
      </b-modal>
    </client-only>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Navbar from '@/components/site/Navbar'
import SiteContainer from '@/components/site/Container'
import SiteFooter from '@/components/site/Footer'
import ActiveCampaignWhatsappButton from '~/components/site/ActiveCampaignWhatsappButton'

export default {
  components: {
    Navbar,
    SiteContainer,
    SiteFooter,
    ActiveCampaignWhatsappButton
  },
  data() {
    return {
      isPresentLeading: true
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    ...mapState('cart', ['isCartModalVisible'])
  },
  async created() {
    await this.fetchProducts()
  },
  methods: {
    setIsPresentLeading({ isPresentLeading }) {
      this.isPresentLeading = isPresentLeading
    },
    ...mapActions({
      fetchProducts: 'product/fetchProducts',
      closeCartModal: 'cart/closeCartModal'
    })
  }
}
</script>
<style></style>
