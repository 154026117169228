<template>
  <b-menu>
    <b-menu-list>
      <b-menu-item @click="closeDrawer" to="/" tag="nuxt-link" label="Início" />
      <!-- <b-menu-item
        @click="closeDrawer"
        to="/sobre"
        label="Sobre"
        tag="nuxt-link"
      ></b-menu-item> -->
      <!--
        <b-menu-item
          @click="closeDrawer"
          to="/blog"
          label="Blog"
          tag="nuxt-link"
        />
      -->
      <b-menu-item
        @click="closeDrawer"
        to="/professores"
        label="Professores"
        tag="nuxt-link"
      />
      <b-menu-item
        @click="closeDrawer"
        to="/suporte"
        label="Suporte"
        tag="nuxt-link"
      />
    </b-menu-list>
  </b-menu>
</template>

<script>
export default {
  methods: {
    closeDrawer(path) {
      this.$root.drawerToggle()
    }
  }
}
</script>

<style lang="scss">
.fb-site-container--content {
  .menu {
    margin-top: ($gap * 12);
    li {
      text-transform: uppercase;
      margin: ($gap * 2) 0px;
      li {
        margin: $gap 0px;
      }
      a {
        font-size: 12px;
        padding: 0.5rem 0;
        @include link-color($white);
      }
    }
  }
}
</style>
