export function getStrapiMedia(url) {
  // Check if URL is a local path
  if (url && url.startsWith('/')) {
    // Prepend Strapi address
    return `${process.env.baseBlogUrl}${url}`
  }
  // Otherwise return full URL
  return url || ''
}

export default {
  getStrapiMedia
}
