<script>
export default {
  data() {
    return {
      odd: false
    }
  },
  mounted() {
    setInterval(() => {
      this.odd = !this.odd
    }, 3000)
  }
}
</script>

<template>
  <div class="fb-blackfriday-top-promo has-text-centered">
    <div v-if="odd">Descontos antecipados para a sua aprovação em 2024!</div>
    <div v-else>
      <div class="fb-blackfriday-label">
        50% OFF nos planos anuais
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.fb-blackfriday-top-promo {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  font-size: 1.25rem;
  font-weight: 600;
  color: $white;
  height: 80px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  .fb-blackfriday-label {
    display: inline-block;
    background: linear-gradient(91deg, #00ceff 0%, #3fd7fb 5.96%, #54ffbb 100%);
    border-radius: 0px 24px;
    padding: 0.75rem 1.5rem;
    color: $black;
    font-size: bold;
  }
}
</style>
