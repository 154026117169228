<template>
  <div class="fb-site-container">
    <aside :class="{ 'is-visible': visible }">
      <div class="fb-site-container--content">
        <img
          v-if="!isAuthenticated"
          src="~/assets/images/logo-white-border.png"
          width="100px"
          height="55px"
          alt="FB Online"
          class="fb-site-container-logo"
        />

        <div v-if="isAuthenticated" class="fb-site-container--auth is-logged">
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <figure class="image is-48x48">
                <img :src="currentStudent.avatar" class="is-rounded" />
              </figure>
            </div>
            <div class="column is-three-quarters">
              <div class="title">{{ currentStudent.name }}</div>
              <a @click="logout" class="btn-logout">
                Sair
              </a>
            </div>
          </div>
        </div>
        <div v-else class="fb-site-container--auth">
          <nuxt-link to="/cadastro">Cadastre-se</nuxt-link> ou <br />
          <nuxt-link to="/login">Entre</nuxt-link> na sua conta
        </div>
        <SiteSideMenu></SiteSideMenu>
      </div>
    </aside>
    <div
      @click="close"
      :class="{ 'is-invisible': !visible }"
      class="fb-site-container-overlay"
    ></div>
    <transition name="fade">
      <b-button
        @click="close"
        :class="{ 'is-invisible': !visible }"
        icon-right="icon-fechar"
        icon-pack="custom"
        rounded
        class="close-modal"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SiteSideMenu from '@/components/site/SideMenu'
export default {
  components: { SiteSideMenu },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    enabled() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    ...mapGetters(['currentStudent', 'isAuthenticated'])
  },
  mounted() {
    this.$root.drawerToggle = () => this.toggle()
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    toggle() {
      this.visible = !this.visible
    },
    async logout() {
      await this.$logout()
      this.close()
    },
    ...mapActions({
      $logout: 'auth/logout'
    })
  }
}
</script>

<style lang="scss">
.fb-site-container {
  > aside {
    position: fixed;
    top: 0;
    left: 0;
    background: url('~assets/images/mask-drawer-menu.png') no-repeat #149f87;
    background-position: top center;
    background-size: $drawer-width;
    background-blend-mode: multiply;
    width: $drawer-width;
    transform: translateX(-100%);
    height: 100%;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    z-index: 100;
    transition: transform 0.5s;
    &.is-visible {
      transform: translateX(0%);
    }
    .fb-site-container-logo {
      width: 100px;
      height: 55px;
      margin-left: -2px;
      margin-bottom: $gap;
    }
  }
  .fb-site-container__nuxt {
    height: 100vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .fb-site-container--content {
    padding: ($gap * 3);
  }
  &--auth {
    color: transparentize($white, 0.5);
    font-size: 12px;
    text-transform: uppercase;
    margin-top: $gap;
    letter-spacing: 1.5px;
    &.is-logged {
      .title {
        font-size: 12px;
        font-weight: bold;
        color: white;
        margin-bottom: $gap * 0.5;
      }
      .btn-logout {
        color: white;
        font-weight: normal;
      }
    }
    a {
      color: $white;
      font-weight: 800;
      margin-right: ($gap / 2);
    }
  }
  &-overlay {
    position: fixed;
    background-color: #000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    z-index: 90;
    transition: opacity 0.5;
    &.is-invisible {
      pointer-events: none;
    }
  }
  .close-modal {
    position: fixed;
    top: ($gap * 3);
    right: ($gap * 3);
    width: ($gap * 5) !important;
    height: ($gap * 5) !important;
    padding: 0px;
    z-index: 100;
    &.invisible {
      visibility: hidden;
    }
    .icon-fechar {
      color: $info;
    }
  }
}
</style>
