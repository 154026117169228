<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="fb-checkout-pay-with-bank-slip"
  >
    <div
      :class="{ 'card--shadow': mobile, 'card--no-shadow': !mobile }"
      class="card"
    >
      <div class="card-header">
        <div class="title">
          Pagamento à Vista
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-vcentered is-centered is-mobile">
          <b-icon pack="custom" icon="icon-boleto" class="icon-bank-slip" />
          <div class="fb-checkout-pay-with-bank-slip-total">
            <div
              v-if="additionalDiscount"
              class="fb-checkout-pay-with-bank-slip-discount"
            >
              - {{ additionalDiscount }}
            </div>
            <div class="fb-checkout-pay-with-bank-slip-price">
              <span class="parcel">1x</span> de
              <b>{{ finalPrice | currency }}</b>
            </div>
          </div>
        </div>
        <div class="fb-checkout-pay-with-bank-slip-divider"></div>
        <div class="card-header">
          <div class="title">
            Insira os dados abaixo
          </div>
        </div>
        <b-field :group-multiline="mobile" grouped>
          <b-field
            :message="errors.first('zipcode')"
            :type="{ 'is-danger': errors.has('zipcode') }"
            label="CEP"
            expanded
          >
            <b-input
              v-model.lazy="bankSlip.address.zipcode"
              v-validate="'required|zipcode'"
              v-cleave="masks.zipcode"
              @blur="fetchZipCode"
              @ended="fetchZipCode"
              :loading="busy"
              :value="bankSlip.address.zipcode"
              autocomplete="off"
              name="zipcode"
              placeholder="Ex.: 99999-999"
              rounded
            />
          </b-field>
        </b-field>
        <b-field :group-multiline="mobile" grouped>
          <b-field
            :message="errors.first('address')"
            :type="{ 'is-danger': errors.has('address') }"
            label="Endereço"
            expanded
          >
            <b-input
              v-model="bankSlip.address.address"
              v-validate="'required'"
              name="address"
              placeholder="Ex.: Rua dos Alfeneiros"
              rounded
            />
          </b-field>
          <b-field
            :message="errors.first('number')"
            :type="{ 'is-danger': errors.has('number') }"
            label="Número"
          >
            <b-input
              v-model="bankSlip.address.number"
              v-validate="'required'"
              name="number"
              placeholder="Ex.: 4"
              rounded
            />
          </b-field>
        </b-field>
        <b-field :group-multiline="mobile" grouped>
          <b-field label="Complemento" expanded>
            <b-input
              v-model="bankSlip.address.complement"
              placeholder="Ex.: Under the stairs"
              name="complement"
              rounded
            />
          </b-field>
          <b-field
            :message="errors.first('neighborhood')"
            :type="{ 'is-danger': errors.has('neighborhood') }"
            label="Bairro"
            expanded
          >
            <b-input
              v-model="bankSlip.address.neighborhood"
              v-validate="'required'"
              placeholder="Ex.: Surrey"
              name="neighborhood"
              rounded
            />
          </b-field>
        </b-field>
        <b-field :group-multiline="mobile" grouped>
          <b-field
            :message="errors.first('state')"
            :type="{ 'is-danger': errors.has('state') }"
            label="Estado"
            expanded
          >
            <b-select
              v-model="bankSlip.address.state"
              v-validate="'required'"
              name="state"
              expanded
              rounded
              placeholder="Selecione"
              size="is-large"
              class="is-shadow"
            >
              <option v-for="e in estados" :value="e.sigla" :key="e.sigla">
                {{ e.sigla }}
              </option>
            </b-select>
          </b-field>
          <b-field
            :message="errors.first('city')"
            :type="{ 'is-danger': errors.has('city') }"
            label="Cidade"
            expanded
          >
            <b-input
              v-model="bankSlip.address.city"
              v-validate="'required'"
              name="city"
              placeholder="Ex.: Little Whinging"
              rounded
            />
          </b-field>
        </b-field>
        <div class="fb-checkout-pay-with-bank-slip-confirmation">
          <b-notification
            :active.sync="hasError"
            type="is-danger"
            has-icon
            role="alert"
          >
            <p>
              <b>{{ messages.title }}</b> <br />
              {{ messages.content }}
            </p>
          </b-notification>
          <b-button
            @click.stop="submit"
            :disabled="invalid"
            :loading="processing"
            type="is-info"
            size="is-large"
            rounded
            expanded
          >
            Gerar Boleto de {{ finalPrice | currency }}
          </b-button>
          <p>
            Após o pagamento, o seu acesso será liberado em até 3 dias úteis.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import cleave from '@/utils/directives/cleave'

import { cloneObservable } from '@/utils/map'
import { destroyUniqSessionId } from '@/utils/cookies/session'

import { checkout } from '@/services/checkout'
import { estados } from '@/services/estados'
import { trackEvent } from '~/utils/gtm'

export default {
  directives: { cleave },
  props: {
    hasPersonData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bankSlip: {
        address: {}
      },
      isZipCodeValid: false,
      isZipCodePersisted: false,
      isFormInvalid: true,
      busy: false,
      processing: false,
      hasError: false,
      masks: {
        zipcode: {
          delimiters: ['-'],
          blocks: [5, 3],
          numericOnly: true,
          onValueChanged: ({ target }) => {
            this.$nextTick(() => {
              this.$set(this.bankSlip.address, 'zipcode', target.value)
            })
          }
        }
      },
      messages: {},
      estados
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    additionalDiscount() {
      if (!this.$store.getters['cart/installments'].length) {
        return 0
      }
      return this.$store.getters['cart/installments'][0].additional_discount
    },
    finalPrice() {
      return this.$store.getters['cart/finalPrice'](1)
    },
    invalid() {
      return this.processing || !this.hasPersonData
    },
    ...mapGetters(['cart', 'currentStudent']),
    ...mapGetters('cart', ['installments'])
  },
  watch: {
    bankSlip: {
      handler(bankSlip) {
        this.$emit('change', bankSlip)
      },
      deep: true
    }
  },
  created() {
    const currentStudent = cloneObservable(this.currentStudent)

    if (this.currentStudent.cpf) {
      this.bankSlip.cpf = currentStudent.cpf
    }

    if (this.currentStudent.address) {
      this.bankSlip.address = currentStudent.address
      this.isZipCodeValid = true
      this.isZipCodePersisted = false
    }
  },
  methods: {
    async fetchZipCode() {
      try {
        this.busy = true

        const { data } = await this.$axios({
          url: `https://brasilapi.com.br/api/cep/v1/${this.bankSlip.address.zipcode}`,
          method: 'get',
          foreign_request: true,
          timeout: 1000 * 2, // 2 segundos
          headers: { 'Content-Type': 'application/json' }
        })

        const { street, neighborhood, city, state } = data

        this.bankSlip.address = Object.assign({}, this.bankSlip.address, {
          address: street,
          neighborhood,
          state,
          city
        })

        this.busy = false
        this.isZipCodeValid = true
        this.isZipCodePersisted = true

        setTimeout(() => {
          this.$validator.validateAll()
        })
      } catch (e) {
        this.isZipCodePersisted = true
        this.isZipCodeValid = false
        this.busy = false
      }
    },
    async submit() {
      const valid = await this.$validator.validateAll()

      if (!valid) {
        return false
      }

      this.processing = true

      // Para gerar um boleto eh necessário informar CPF e endereço.
      const payload = {
        cpf: this.bankSlip.cpf,
        address_attributes: this.bankSlip.address
      }

      // REVIEW: pq o perfil local esta sendo atualizado antes de ser enviado?
      //
      // Salva os novos dados do usuário no perfil local.
      await this.setRemoteProfile(payload)

      // Se o perfil foi salvo, o checkout pode ser finalizado.
      const { data } = await checkout(this, {
        id: this.cart.id,
        installments: 1, // nao existe parcelamento para boleto
        payment_method_code: 'bank_slip'
      })

      // O checkout retorna o ID da assinatura para imprimir o boleto.
      if (data[0]?.success) {
        // Notificando que houve uma tentativa de compra para o facebook
        trackEvent('Purchase', {
          ecomm_pagetype: 'purchase-bankslip',
          ecomm_prodid: this.cart.items.map((item) => item.plan.slug),
          ecomm_pvalue: this.cart.items.map(
            (item) => item.plan.price.final_price
          ),
          ecomm_totalvalue: this.cart.resume?.total,
          ecomm_coupon: this.cart.coupon,
          ecomm_discounts: this.cart.resume?.discounts
        })

        destroyUniqSessionId(this)
        // Limpando Carrinho
        this.clearProducts()
        // Ir para uma tela de confirmação
        this.$router.push({
          path: 'checkout/boleto-gerado',
          query: {
            print_url: data[0].bank_slip.print_url,
            barcode: data[0].bank_slip.typeable_barcode
          }
        })
      } else {
        // O retorno do checkout tenha falhado
        this.messages = data.length ? data[0] : data
        this.hasError = true
      }

      this.processing = false
    },
    ...mapActions('auth', ['setRemoteProfile']),
    ...mapActions('cart', ['clearProducts'])
  }
}
</script>

<style lang="scss">
.fb-checkout-pay-with-bank-slip {
  margin-bottom: $gap * 3;
  .card {
    &-header {
      padding: ($gap * 3) 0px;
      box-shadow: none;
      justify-content: center;
      .title {
        text-align: center;
      }
    }
    &-content,
    &-header {
      padding: $gap * 3 $gap * 5;
    }
  }
  .no-margin {
    margin-bottom: 0px;
    .field {
      margin-bottom: $gap !important;
    }
  }
  &-description {
    margin: 0px ($gap * 5);
    text-align: center;
  }
  &-total {
    display: inline-flex;
    flex-direction: column;
    text-align: right;
    padding: 0px ($gap * 3);
  }
  &-divider {
    border-bottom: 2px solid $grey-lighter;
    padding: $gap 0px;
  }
  &-discount {
    color: $grey-light;
  }
  &-price {
    font-size: 14px;
    .parcel {
      font-weight: bold;
    }
    b {
      font-weight: bold;
      font-size: 16px;
      color: $info;
    }
  }
  &-confirmation {
    .button {
      font-size: 12px;
      max-width: 295px;
      margin: auto;
    }
    > p {
      margin: ($gap * 3) ($gap * 4) ($gap * 3);
      text-align: center;
    }
  }
  .icon-bank-slip {
    width: $gap * 6;
    height: $gap * 6;
    .custom::before {
      font-size: $gap * 6;
    }
  }
  &.is-desktop {
    padding: 0px;
    .card {
      padding: 0px $gap * 7;
    }
  }
}
</style>
