<template>
  <client-only>
    <footer :class="{ 'is-desktop': !mobile, 'is-lite': lite }">
      <div v-if="!lite" :class="{ columns: !mobile }" class="container fb-lead">
        <div :class="{ 'column is-one-quarter': !mobile }" class="footer-logo">
          <img
            src="~/assets/images/logo-white-border.png"
            width="100px"
            height="55px"
            alt="FB Online"
          />
          <p v-show="!mobile">
            O FB Online é o curso online do Farias Brito, uma Instituição que
            tem mais de 80 anos de experiência em educação.
          </p>
          <fb-site-social-media v-if="!mobile" />
        </div>
        <div :class="{ column: !mobile }" class="container is-fluid">
          <div class="columns is-multiline is-space-evenly">
            <div class="column is-3-widescreen">
              <p class="title is-5">FB ONLINE</p>
              <ul>
                <!-- <li>
                  <nuxt-link to="/sobre">
                    Sobre
                  </nuxt-link>
                </li> -->
                <!-- <li>
                  <nuxt-link to="/blog">
                    Blog
                  </nuxt-link>
                </li> -->
                <li>
                  <nuxt-link to="/professores">
                    Professores
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link to="/suporte">
                    Suporte
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link to="/links/termos-de-uso-e-politica">
                    Termos de Uso e Política de privacidade
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <div class="column is-3-widescreen">
              <p class="title is-5">CONTATO</p>
              <address class="email">
                <b-icon
                  icon="icon-email"
                  pack="custom"
                  type="is-white"
                ></b-icon>
                <a href="mailto:atendimento@fbonline.com.br" target="_blank"
                  >atendimento@fbonline.com.br</a
                >
              </address>
              <address>
                <b-icon
                  icon="icon-localizacao"
                  pack="custom"
                  type="is-white"
                ></b-icon>
                Rua Barão do Rio Branco, 2424 <br />
                José Bonifácio <br />
                Fortaleza-CE
              </address>
            </div>
            <!-- <div class="column is-3-widescreen">
              <p class="title is-5 is-uppercase">Afiliados</p>
              <ul>
                <li>
                  <nuxt-link to="/crednosso">
                    Crednosso
                  </nuxt-link>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
        <fb-site-social-media v-if="!lite && mobile" />
      </div>
      <div
        :class="{ mobile: mobile }"
        class="fb-copyright tile is-parent is-vertical"
      >
        <p>
          Todos os direitos reservados <br />
          Desenvolvido por FB Online | &copy; {{ currentYear }}
        </p>
      </div>
    </footer>
  </client-only>
</template>

<script>
export default {
  props: {
    lite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  // background: url('~assets/images/bg-footer-texture-mobile.png') no-repeat
  //   $primary;
  background-color: $primary;
  background-position: center;
  background-size: 100%;
  color: transparentize($white, 0.2);
  padding-top: $gap * 2;
  padding-bottom: $gap * 6;
  .fb-lead {
    padding-top: ($gap * 2);
  }
  .footer-logo {
    padding: 0px ($gap * 2);
    margin: $gap 0px ($gap * 2);
    text-align: center;
    img {
      max-width: 87px;
    }
  }
  ul li {
    margin: $gap 0px;
  }
  address {
    position: relative;
    font-style: normal;
    line-height: 1.75;
    margin-bottom: $gap * 2;
    .icon {
      position: absolute;
      top: $gap * 0.25;
      left: $gap * -3;
    }
    a {
      color: $white;
    }
  }
  .title {
    color: $white;
  }
  ul {
    li {
      a {
        color: $white;
      }
    }
  }
  .fb-copyright {
    border-top: 1px solid $grey-lighter;
    &.mobile {
      margin-top: $gap * 3;
    }
    p {
      text-align: center;
      text-transform: uppercase;
      line-height: ($gap * 3);
      letter-spacing: 1.25px;
      padding: ($gap * 2) 0px;
    }
  }
  @media (max-width: $tablet) {
    .fb-lead {
      padding: $gap * 2 $gap * 6;
    }
  }
}
footer.is-desktop {
  // background: url('~assets/images/bg-footer-texture-desktop.png') no-repeat
  //   $primary;
  background-color: $primary;
  background-size: contain;
  .footer-logo {
    text-align: left;
    img {
      margin-bottom: $gap * 2;
    }
  }
  &.is-lite {
    .fb-copyright {
      border-top: none;
    }
  }
}
</style>
