export const AUTH_PATHS = ['/login', '/cadastro']

const LOGOUT_PATH_REGEX = /^\/sair/

export default function({ store, route, redirect }) {
  window.onNuxtReady(async () => {
    if (!route.path.match(LOGOUT_PATH_REGEX)) {
      await store.dispatch('auth/profile')
    }
    // persist current user
    const isNotPermitted = isNotPermittedPaths(route, store)
    // check if in auth paths
    if (isNotPermitted) {
      redirect('/')
    }
  })
}

function isNotPermittedPaths(route, store) {
  const isPresent = AUTH_PATHS.includes(route.path)
  return store.getters.isAuthenticated && isPresent
}
