<template>
  <div class="fb-coupon">
    <b-collapse :open="false" class="coupon-container">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="contentIdForA11y3"
      >
        <p class="card-header-title">
          INSERIR CUPOM DE DESCONTO
        </p>
        <div class="card-header-icon">
          <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
        </div>
      </div>
      <b-field
        :type="{ 'is-danger': invalid }"
        :message="errors.first('coupon') || errorMessage"
        class="fb-coupon-field"
        grouped
      >
        <b-input
          v-if="!cart.coupon"
          v-model="coupon"
          v-validate="'alphanumeric'"
          :value="coupon"
          :disabled="disabled"
          name="coupon"
          placeholder="CUPOM"
          rounded
          expanded
        />
        <div v-else class="mr-2" style="flex: 1;">
          <p class="applied-coupon control mb-1">
            <b-icon icon="icon-confirmacao" pack="custom" class="gap-left" />
            Cupom <b>{{ cart.coupon }}</b> adicionado
          </p>
        </div>

        <p class="control">
          <b-button
            v-if="!cart.coupon"
            @click.stop="addCoupon"
            :disabled="invalid || disabled"
            type="is-info"
            size="is-large"
            rounded
            class="coupon-button"
          >
            Adicionar
          </b-button>
          <b-button
            v-else="cart.coupon"
            @click.stop="removeCoupon"
            :disabled="disabled"
            type="is-info"
            size="is-medium"
            rounded
            inverted
            class="coupon-button"
          >
            Remover
          </b-button>
        </p>
      </b-field>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { addCoupon, removeCoupon } from '@/services/coupon'

export default {
  data() {
    return {
      coupon: '',
      errorMessage: '',
      disabled: false,
      loading: false
    }
  },
  computed: {
    invalid() {
      return !!this.errorMessage || this.errors.has('coupon')
    },
    ...mapGetters({
      cart: 'cart'
    })
  },
  methods: {
    async addCoupon() {
      if (this.coupon) {
        this.loading = true
        this.disabled = true

        const { data, success, error } = await addCoupon(this, this.coupon)

        if (success) {
          this.setProducts(data)
          this.errorMessage = ''
        } else {
          this.errorMessage = error
        }

        this.loading = false
        this.disabled = false
      }
    },
    async removeCoupon() {
      if (this.cart.coupon) {
        this.loading = true
        this.disabled = true

        const { error, data, success } = await removeCoupon(
          this,
          this.cart.coupon
        )

        if (success) {
          this.setProducts(data)
          this.errorMessage = ''
        } else {
          this.errorMessage = error
        }

        this.loading = false
        this.disabled = false
      }
    },
    ...mapActions('cart', ['setProducts'])
  }
}
</script>

<style lang="scss">
.fb-coupon {
  padding: ($gap * 2) ($gap * 3);
  border-bottom: 1px solid $grey-lighter;
  .coupon-container {
    .card-header {
      box-shadow: none;
      &-icon {
        .is-opened {
          transform: rotate(90deg);
        }
      }
    }
    .card-header-title {
      color: #484a4b;
      padding-left: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.14px;
      justify-content: flex-start;
      padding-left: $gap;
    }
  }
  .applied-coupon {
    flex-grow: 1;
    flex-shrink: 1;
    background: #f5f5f5;
    text-align: center;
    line-height: 36px;
    border-radius: 20px;
  }
  &-field {
    padding-top: $gap * 2;
    position: relative;
    .coupon-button {
      padding: 0px $gap * 3;
      font-size: 12px;
    }
  }
  .help {
    display: inline-block;
    position: absolute;
    bottom: $gap * -2;
    left: 0;
    padding-left: $gap * 1.5;
    text-transform: uppercase;
  }
}
</style>
