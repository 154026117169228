// Perfil do aluno.
export async function getProfile(app) {
  const { $axios } = app
  try {
    const { data } = await $axios.get('/api/student/v1/user/profile')
    return { success: true, data }
  } catch (e) {
    return { success: false, responsible: {} }
  }
}

export async function updateProfile(app, student) {
  const { $axios } = app
  try {
    const { data } = await $axios.put(`/api/student/v1/user/profile`, {
      student
    })
    return { success: true, data }
  } catch (e) {
    const { data } = e.response
    return { success: false, error: data }
  }
}
