import modules from './modules'

const state = () => ({
  blocked: false,
  checkoutProgress: null,
  checkoutProgressLabel: null
})

const actions = {
  nuxtServerInit({ dispatch }, { app }) {
    const store = app.$cookies.get('vuex')
    if (!store) {
      return
    }

    const { auth, cart, site } = store

    dispatch('auth/setAuth', auth, { root: true })
    dispatch('cart/setItemIds', cart.itemIds, { root: true })
    dispatch('cart/setShoppingCartId', cart.shopping_cart_id, { root: true })
    dispatch('site/setSite', site, { root: true })
    // // Carregando produtos da Loja
    // await dispatch('product/fetchProducts', {}, { root: true })
  },
  // bloqueando toda interação do usuário com a aplicação
  block({ commit }) {
    commit('Block')
  },
  // bloqueando toda interação do usuário com a aplicação
  unblock({ commit }) {
    commit('Unblock')
  },

  setCheckoutProgress({ commit }, number) {
    commit('SetCheckoutProgress', number)
  },

  setCheckoutProgressLabel({ commit }, label) {
    commit('SetCheckoutProgressLabel', label)
  }
}

const mutations = {
  Block(state) {
    state.blocked = true
  },
  Unblock(state) {
    state.blocked = false
  },
  SetCheckoutProgress(state, number) {
    state.checkoutProgress = number
  },
  SetCheckoutProgressLabel(state, label) {
    state.checkoutProgressLabel = label
  }
}

const getters = {
  isAuthenticated(state) {
    return state.auth.loggedIn
  },

  currentStudent(state) {
    if (state.auth.student?.email) {
      return state.auth.student
    }
    return {}
  },
  cart(state) {
    return state.cart.data
  },
  site(state) {
    return state.site.data
  }
}

export default { modules, state, actions, mutations, getters }
