const state = () => ({
  data: {
    redirectUrl: ''
  }
})

const actions = {
  setSite({ commit }, payload) {
    commit('SetSite', payload)
  },
  setRedirectUrl({ commit }, url) {
    commit('SetRedirectUrl', url)
  }
}

const mutations = {
  SetSite(state, payload) {
    state.data = payload
  },
  SetRedirectUrl(state, url) {
    state.data.redirectUrl = url
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
