<template>
  <div
    :class="{
      'is-mobile': mobile,
      'is-desktop': !mobile,
      'is-selected': paymentMethod !== null
    }"
    class="fb-checkout-payment-method-selection"
  >
    <div
      :class="{ 'card--shadow': mobile, 'card--no-shadow': !mobile }"
      class="card"
    >
      <div class="card-header">
        <div class="title">
          Escolha um método de pagamento
        </div>
      </div>
      <div class="card-content">
        <p
          class="has-text-center fb-checkout-payment-method-selection-description"
        >
          <span v-if="isPaymentWithBankShip">
            A inclusão de item com pagamento mensal não permite<br />a seleção
            de boleto.
          </span>
          <span v-else>
            Adicione um cartão para efetuar o pagamento ou<br />pague no boleto
            com desconto.
          </span>
        </p>
        <div class="payment-methods">
          <div
            v-for="(paymentProfile, index) in paymentProfiles"
            :key="index.toString()"
            class="field"
          >
            <b-radio
              v-model="paymentMethod"
              :native-value="index"
              :class="{ 'is-active': index === paymentMethod }"
              type="is-info"
              size="is-medium"
              name="payment-method"
              class="payment-method"
            >
              <div class="card card--shadow">
                <div class="card-content">
                  <span class="payment-method-label">
                    <img
                      :src="cardIcon(paymentProfile)"
                      width="24"
                      class="payment-method-brand"
                      alt="Bandeira do Cartão"
                    />
                    {{ paymentProfile.card_company_name }}
                  </span>
                  <span class="payment-method-end-number">
                    <span
                      v-for="i in [1, 2, 3, 4, 5]"
                      :key="i"
                      class="payment-method-mask-circle"
                    />
                    {{ paymentProfile.card_number_last_four }}
                  </span>
                </div>
              </div>
            </b-radio>
          </div>
          <div v-if="mobile" class="field">
            <b-radio
              v-model="paymentMethod"
              @click.native="openCreditCardModal"
              :native-value="-1"
              type="is-info"
              size="is-medium"
              class="payment-method"
              name="payment-method"
            >
              <div class="card card--shadow">
                <div class="card-content">
                  <span class="payment-method-label">
                    <b-icon
                      icon="icon-cartao-de-credito"
                      pack="custom"
                      size="is-medium"
                    />
                    <b>Adicionar Cartão de Crédito</b>
                  </span>
                </div>
              </div>
            </b-radio>
          </div>
          <div
            v-else
            :class="{ 'is-expanded': isCreditCardOpen }"
            class="field new-credit-card"
          >
            <b-radio
              v-if="mobile"
              v-model="paymentMethod"
              @click.native="openCreditCardModal"
              :native-value="-1"
              type="is-info"
              size="is-medium"
              class="payment-method"
              name="payment-method"
            >
              <div class="card card--shadow">
                <div class="card-content">
                  <span class="payment-method-label">
                    <b-icon
                      icon="icon-cartao-de-credito"
                      pack="custom"
                      size="is-medium"
                    />
                    <b>Adicionar Cartão de Crédito</b>
                  </span>
                </div>
              </div>
            </b-radio>
            <div v-else class="card-header" aria-controls="contentIdForA11y3">
              <b-radio
                v-model="paymentMethod"
                @click.native="openCreditCardModal"
                :native-value="-1"
                type="is-info"
                size="is-medium"
                class="payment-method"
                name="payment-method"
              >
              </b-radio>
              <div @click="openCreditCardModal" class="card card--shadow">
                <div class="card-content">
                  <span class="payment-method-label">
                    <b-icon
                      icon="icon-cartao-de-credito"
                      pack="custom"
                      size="is-medium"
                    />
                    <b>Adicionar Cartão de Crédito</b>
                  </span>
                </div>
                <b-collapse :open="isCreditCardOpen" class="coupon-container">
                  <b-field class="credit-card-wrapper">
                    <fb-checkout-credit-card-form
                      ref="notmodal"
                      @close="close"
                      :is-modal="false"
                    />
                  </b-field>
                </b-collapse>
              </div>
            </div>
          </div>
          <div class="field">
            <b-radio
              v-model="paymentMethod"
              :native-value="-2"
              :class="{ 'is-active': -2 === paymentMethod }"
              :disabled="isPaymentWithBankShip"
              type="is-info"
              size="is-medium"
              class="payment-method"
              name="payment-method"
            >
              <div class="card card--shadow">
                <div class="card-content">
                  <span class="payment-method-label">
                    <b-icon icon="icon-boleto" pack="custom" size="is-medium" />
                    <b>Boleto</b>
                  </span>
                </div>
              </div>
            </b-radio>
          </div>
        </div>
      </div>
    </div>
    <fb-checkout-credit-card-form ref="modal" @close="close" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getPaymentProfiles } from '@/services/payment-profiles'

export default {
  model: {
    prop: 'input',
    event: 'change'
  },
  props: {
    input: null
  },
  data() {
    return {
      paymentMethod: null,
      paymentProfiles: []
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    isPaymentWithBankShip() {
      return this.cart.items.some((i) => i.is_recurring)
    },
    isCreditCardOpen() {
      return this.paymentMethod === -1
    },
    ...mapGetters(['cart'])
  },
  watch: {
    paymentMethod(value) {
      this.$emit('change', value)
    }
  },
  async created() {
    if (this.paymentProfiles.length === 0)
      this.paymentProfiles = await getPaymentProfiles(this)
  },
  methods: {
    cardIcon(paymentProfile) {
      try {
        return require(`~/assets/images/credit-card-brand/${paymentProfile.card_company_code}.svg`)
      } catch (error) {
        return ''
      }
    },
    openCreditCardModal() {
      this.paymentMethod = -1
      if (this.mobile) this.$refs.modal.open()
      else this.$refs.notmodal.open()
      // this.$refs.modal.open()
    },
    async close(paymentProfile) {
      if (!paymentProfile) {
        this.$set(this, 'paymentMethod', undefined)
        return
      }

      const payload = {
        payment_profile: {
          gateway_token: paymentProfile.gateway_token,
          payment_method_code: 'credit_card'
        }
      }
      try {
        const { data } = await this.$axios.post(
          '/api/student/v1/payment_profiles',
          payload
        )
        this.paymentProfiles = [data.info]

        this.paymentMethod = 0
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Falha, não foi possível salvar cartão de crédito',
          type: 'is-danger',
          position: 'is-bottom'
        })

        this.paymentMethod = null
      }
    }
  }
}
</script>
<style lang="scss">
.fb-checkout-payment-method-selection {
  padding: $gap 0px ($gap * 2);

  .card {
    &-header {
      padding: ($gap * 3) 0px 0px;
      box-shadow: none;
      justify-content: center;

      .title {
        text-align: center;
      }
    }
  }

  .b-radio {
    align-items: flex-start;
    .check {
      margin-top: $gap * 2.5;
    }
  }

  .new-credit-card {
    .card-header {
      padding: 0px;
      z-index: 2;
      position: relative;
    }
    .collapse.coupon-container {
      margin-right: 0px !important;
    }
    .collapse-content {
      margin: auto;
    }
    .coupon-container {
      z-index: 1;
      position: relative;
      margin-right: 16px !important;
      .credit-card-wrapper {
        width: 100%;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        .fb-card-modal-new-credit-card,
        .fb-card-modal-new-credit-card .card-content {
          flex-direction: column;
        }
      }
    }
  }

  .fields {
    padding: $gap ($gap * 2);
  }

  .terms {
    padding: 0px $gap $gap;

    b {
      color: $primary;
    }
  }

  .payment-methods {
    padding: $gap * 2;

    .payment-method {
      display: flex;
      width: 100%;

      .check {
        margin-right: $gap * 2;
      }

      .icon {
        margin-right: $gap;
      }

      .control-label {
        display: block;
        margin-right: $gap * 2;
        flex: 1;
      }

      .card {
        .card-content {
          display: flex;
          align-items: center;
          padding: 20px ($gap * 2);
        }
      }

      &.is-active {
        .card {
          border: 1px solid $info;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0px;
        color: $info;
        b,
        strong {
          font-weight: 600;
        }
      }

      &-brand {
        margin-right: $gap * 1.5;
      }

      &-end-number {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        text-align: right;
        font-size: 14px;
        font-weight: 600;
        color: $info;
      }

      &-mask-circle {
        align-items: center;
        background-color: $info;
        width: $gap * 0.75;
        height: $gap * 0.75;
        border-radius: 50%;
        margin: 0px 2px;

        &:last-child {
          margin-right: $gap * 1.5;
        }
      }
    }
  }

  &-description {
    text-align: center;
    margin: 0px ($gap * 5);
  }

  &.is-desktop {
    padding: 0px;
    &:not(.is-selected) {
      border-bottom: none !important;
      > .card {
        border-radius: $input-border-radius !important;
        border-radius: $input-border-radius !important;
      }
    }
    .payment-methods {
      padding: $gap * 2 $gap * 7;
    }
    .new-credit-card {
      .b-radio {
        max-width: 40px;
      }
      .card {
        width: 100%;
        margin-right: $gap * 2;
        &:hover,
        &:active {
          cursor: pointer;
        }
        .card-content {
          padding: $gap * 2;
        }
        .payment-method-label {
          min-height: 32px;
        }
        .icon {
          margin-right: $gap;
        }
      }
    }
    .new-credit-card.is-expanded {
      .control-label .card {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
}
</style>
