<template>
  <div class="columns is-relative is-vcentered">
    <div class="column">
      <div class="title fb-course-card--price">
        <div class="columns is-vcentered is-relative content-price">
          <div v-if="hasPlanDiscount && !mobile" class="column is-4">
            <div class="discount--icon">
              <div class="icon-discount"></div>
            </div>
            <div class="section--divider"></div>
            <div class="discount--price">
              <div class="discount-heading">DESCONTO</div>
              <div class="discount-value">-{{ hasPlanDiscount.info }}</div>
            </div>
          </div>
          <div
            :class="{
              'is-border-left': hasPlanDiscount && !mobile
            }"
            class="column"
          >
            <span class="currency">R$</span>
            <span class="price">{{ getPrice.integer }}</span>
            <span class="cents">,{{ getPrice.decimal }}/ mês</span>
            <span>
              <span v-if="!isRecurring" class="total_price">
                Total
                <b>{{ getPeriod.plan.price.final_price | currency }}</b>
              </span>
              <span v-else class="total_price">
                Todo mês - recorrente
              </span>
            </span>
          </div>
        </div>
      </div>
      <fb-course-card-period-select
        :input="period"
        :periods="product.plans"
        @change="change"
      ></fb-course-card-period-select>
    </div>
    <div v-show="!mobile" class="is-desktop-border-left"></div>
    <div class="column is-align-self-end">
      <div class="card-content--actions">
        <div class="subtitle">
          <div v-if="mobile" class="divider"></div>
          Ao escolher testar, você tem acesso à primeira aula de cada módulo!
        </div>
        <div class="columns is-mobile">
          <div class="column ">
            <b-button
              @click.stop="() => addPlan(getPeriod)"
              type="is-info"
              rounded
              outlined
              expanded
              size="is-medium"
              icon-left="icon-add-carrinho"
              icon-pack="custom"
            >
              Carrinho
            </b-button>
          </div>
          <div class="column">
            <b-button
              @click.stop="knowBetter"
              type="is-info"
              rounded
              expanded
              size="is-medium"
              >Testar</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardMixin from './CardMixin.vue'

export default {
  extends: CardMixin,
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    change(newPeriod) {
      this.$emit('change', newPeriod)
    }
  }
}
</script>

<style></style>
