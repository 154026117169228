const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['cache-control'] = require('../middleware/cache-control.js')
middleware['cache-control'] = middleware['cache-control'].default || middleware['cache-control']

middleware['cart'] = require('../middleware/cart.js')
middleware['cart'] = middleware['cart'].default || middleware['cart']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['ticket'] = require('../middleware/ticket.js')
middleware['ticket'] = middleware['ticket'].default || middleware['ticket']

export default middleware
