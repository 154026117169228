import { set } from 'vue'
import find from 'lodash/find'
import { getProducts } from '@/services/products'

const state = () => ({
  courses: [],
  detached_courses: [],
  super_courses: []
})

const actions = {
  async fetchProducts({ state, commit }) {
    if (state.courses?.length && state.detached_courses?.length) {
      return
    }

    const { courses, detached_courses, super_courses } = await getProducts(this)

    commit('SET_COURSES', courses)
    commit('SET_SUPER_COURSES', super_courses)
    commit('SET_DETACHED_COURSES', detached_courses)
  }
}

const mutations = {
  SET_COURSES(state, payload) {
    set(state, 'courses', payload)
  },
  SET_DETACHED_COURSES(state, payload) {
    set(state, 'detached_courses', payload)
  },
  SET_SUPER_COURSES(state, payload) {
    set(state, 'super_courses', payload)
  }
}

const getters = {
  getCourse: (state) => (slug) => {
    return find(state.courses, { slug })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
