var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fb-course-card-wrap",class:{ 'is-page': _vm.isPage }},[(_vm.isPage)?_c('div',{staticClass:"fb-course-card card is-page",class:[
      { 'is-mobile': _vm.mobile, 'is-desktop': !_vm.mobile },
      _vm.activePeriodClass
    ]},[_c('LayoutHeading',{attrs:{"period":_vm.selectedPeriodIndex,"product":_vm.product,"isPage":true}}),_vm._v(" "),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[(_vm.isPage)?_c('LayoutPage',{attrs:{"product":_vm.product},model:{value:(_vm.selectedPeriodIndex),callback:function ($$v) {_vm.selectedPeriodIndex=$$v},expression:"selectedPeriodIndex"}}):_c('LayoutProduct',{attrs:{"product":_vm.product},model:{value:(_vm.selectedPeriodIndex),callback:function ($$v) {_vm.selectedPeriodIndex=$$v},expression:"selectedPeriodIndex"}})],1)])],1):(_vm.mobile)?_c('Collapse',{attrs:{"product":_vm.product}},[_c('div',{staticClass:"fb-course-card card",class:_vm.activePeriodClass},[_c('nuxt-link',{staticClass:"fb-course-card--link",attrs:{"title":_vm.product.title,"to":`/curso/${_vm.product.slug}`}}),_vm._v(" "),_c('LayoutHeading',{attrs:{"period":_vm.selectedPeriodIndex,"product":_vm.product}}),_vm._v(" "),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[(_vm.isPage)?_c('LayoutPage',{attrs:{"period":_vm.selectedPeriodIndex,"product":_vm.product}}):_c('LayoutProduct',{attrs:{"product":_vm.product},model:{value:(_vm.selectedPeriodIndex),callback:function ($$v) {_vm.selectedPeriodIndex=$$v},expression:"selectedPeriodIndex"}})],1)])],1)]):_c('div',{staticClass:"fb-course-card card",class:[
      { 'is-mobile': _vm.mobile, 'is-desktop': !_vm.mobile },
      _vm.activePeriodClass
    ]},[_c('nuxt-link',{staticClass:"fb-course-card--link",attrs:{"title":_vm.product.title,"to":`/curso/${_vm.product.slug}`}}),_vm._v(" "),_c('LayoutHeading',{attrs:{"period":_vm.selectedPeriodIndex,"product":_vm.product}}),_vm._v(" "),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[(_vm.isPage)?_c('LayoutPage',{attrs:{"product":_vm.product},model:{value:(_vm.selectedPeriodIndex),callback:function ($$v) {_vm.selectedPeriodIndex=$$v},expression:"selectedPeriodIndex"}}):_c('LayoutProduct',{attrs:{"product":_vm.product},model:{value:(_vm.selectedPeriodIndex),callback:function ($$v) {_vm.selectedPeriodIndex=$$v},expression:"selectedPeriodIndex"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }