<template>
  <div class="form-register is-mobile">
    <div class="container is-fluid lite-container">
      <form @submit.prevent="register" method="post">
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field
              :message="errors.first('name')"
              :type="{ 'is-danger': errors.has('name') }"
              label="Nome Completo"
            >
              <b-input
                v-model="name"
                v-validate="'required|full_name'"
                placeholder="Ex.: João das Neves Pereira"
                autofocus="true"
                type="text"
                name="name"
                size="is-large"
                icon-pack="custom"
                icon="icon-usuario"
                rounded
              ></b-input>
            </b-field>
          </div>

          <div class="column is-12">
            <b-field
              :message="errors.first('email') || getMessage('email')"
              :type="{
                'is-danger': errors.has('email') || getMessage('email')
              }"
              label="Email"
            >
              <b-input
                v-model="email"
                v-validate="'required|email'"
                placeholder="Ex.: justharry@gmail.com"
                type="email"
                name="email"
                size="is-large"
                icon-pack="custom"
                icon="icon-email"
                rounded
              ></b-input>
            </b-field>
          </div>

          <div class="column is-6">
            <b-field
              :message="errors.first('phone')"
              :type="{ 'is-danger': errors.has('phone') }"
              label="Telefone"
            >
              <b-input
                v-model="phone"
                v-cleave="masks.phone"
                v-validate="'required|phone'"
                placeholder="(99) 99999 9999"
                type="phone"
                name="phone"
                size="is-large"
                icon-pack="custom"
                icon="icon-phone"
                rounded
              ></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              :message="errors.first('password') || getMessage('password')"
              :type="{
                'is-danger': errors.has('password') || getMessage('password')
              }"
              label="Password"
            >
              <b-input
                v-model="password"
                v-validate="'required|password'"
                placeholder="******"
                type="password"
                name="password"
                size="is-large"
                icon-pack="custom"
                icon="icon-senha"
                rounded
              ></b-input>
            </b-field>
          </div>

          <div class="column is-12">
            <fb-user-select-birthdate
              v-model="birthdate"
              field-name="birthdate"
            />
          </div>

          <div class="column is-12">
            <b-field
              :message="errors.first('terms_accepted')"
              :type="{ 'is-danger': errors.has('terms_accepted') }"
              class="terms-accepted"
            >
              <b-checkbox
                v-model="terms_accepted"
                v-validate="'required'"
                :false-value="0"
                true-value="1"
                name="terms_accepted"
                value="1"
                >Li e aceito os
                <a href="/links/termos-de-uso-e-politica/" target="_blank">
                  termos de uso e política de privacidade</a
                >
              </b-checkbox>
            </b-field>
          </div>
        </div>

        <recaptcha />

        <div class="control">
          <b-button
            :disabled="disabled || invalid"
            :loading="disabled"
            class="is-fullwidth"
            type="is-info"
            size="is-large"
            native-type="submit"
            rounded
          >
            Criar Conta
          </b-button>
        </div>
      </form>

      <div class="has-text-centered">
        Já é um aluno?
        <nuxt-link to="/login">Entre</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import omit from 'lodash/omit'
import cleave from '~/utils/directives/cleave'
import { splitFullName } from '~/utils/strings'

export default {
  directives: { cleave },
  props: {
    messages: {
      type: Object,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // user fields
      name: '',
      first_name: '',
      last_name: '',
      birthdate: null,
      phone: '',
      email: '',
      password: '',
      terms_accepted: 0,
      // others
      masks: {
        phone: {
          phone: true,
          phoneRegionCode: 'BR'
        }
      }
    }
  },
  computed: {
    invalid() {
      return !this.birthdate || !this.terms_accepted
    }
  },
  watch: {
    name(nextValue) {
      const name = splitFullName(nextValue)
      this.first_name = name ? name.firstName : nextValue
      this.last_name = name ? name.lastName : ''
    }
  },
  methods: {
    async register() {
      this.$emit('disabled', true)

      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
      } catch (error) {}

      const result = await this.$validator.validateAll()

      if (result) {
        return this.$emit('register', omit(this, ['name']))
      }

      this.$emit('disabled', false)
    },
    getMessage(attr) {
      if (!this.messages) {
        return ''
      }
      return this.messages[attr]?.join(',')
    }
  }
}
</script>

<style lang="scss">
.form-register {
  .lite-container {
    padding: $gap * 2 $gap * 3;
  }
}
</style>
