<template>
  <div v-if="product" class="fb-product-tabs">
    <section>
      <div class="card card--shadow fb-card-tabs">
        <b-tabs v-model="activeTab">
          <b-tab-item label="Sobre o Curso">
            <div
              :class="{ 'is-readmore': readMore }"
              class="fb-card-tabs-description"
            >
              <div v-html="product.about"></div>
            </div>
            <b-button
              @click="readMore = !readMore"
              type="is-text"
              class="read-more"
              >{{ readMoreBtnText }}</b-button
            >
          </b-tab-item>

          <b-tab-item label="Ementa">
            <fb-product-resume
              :resume="product.description"
            ></fb-product-resume>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>

    <section v-show="Tabs.about === activeTab">
      <div class="is-about-active">
        <fb-product-features :offer="product.offer" />
        <client-only>
          <fb-product-video
            v-show="isDetached"
            :video="product.presentation_url"
          ></fb-product-video>
        </client-only>
        <fb-professor-list :professors="product.professors" />
      </div>
    </section>

    <section v-show="Tabs.resume === activeTab">
      <div class="is-resume-active">
        <fb-product-study-material :disciplines="product.disciplines" />
      </div>
    </section>
  </div>
</template>

<script>
const Tabs = {
  about: 0,
  resume: 1
}

export default {
  props: {
    product: null
  },
  data() {
    return {
      readMore: false,
      activeTab: 0
    }
  },
  computed: {
    readMoreBtnText() {
      return this.readMore ? 'Ler Menos' : 'Ler Mais'
    },
    Tabs() {
      return Tabs
    },
    isDetached() {
      return this.product.type === 'detached_course'
    }
  }
}
</script>

<style lang="scss">
.fb-product-tabs {
  > section {
    .is-about-active,
    .is-resume-active,
    .fb-card-tabs {
      > .card,
      &.card {
        max-width: none;
        margin-top: ($gap * 3);
        margin-left: ($gap * -2);
        margin-right: ($gap * -2);
      }
    }
  }
  .b-tabs .tabs {
    ul {
      display: flex;
      li {
        flex: 1;
        &.is-active {
          a {
            font-weight: 800;
            color: $info;
            border-bottom-width: 2px;
            border-bottom-color: $info;
          }
        }
        a {
          padding: ($gap * 2) 0.5rem;
          text-transform: uppercase;
          color: $grey-light;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  .tab-content {
    padding: ($gap * 4);
  }
  .read-more {
    display: block;
    margin: ($gap * 2) auto 0px;
  }
  .fb-card-tabs {
    &-description {
      max-height: $gap * 20;
      overflow: hidden;
      > p:first-child {
        text-align: center;
        text-transform: uppercase;
        color: $info;
        font-size: 14px;
        font-weight: bold;
        justify-content: center;
        padding: ($gap * 2) $gap $gap;
      }
      &.is-readmore {
        max-height: none !important;
      }
    }
  }
}
</style>
