<template>
  <div class="card-heading fb-course-card-heading">
    <div
      v-if="hasPlanDiscount && (mobile || !isPage)"
      class="fb-course-card--discount card"
    >
      <div class="discount-heading">DESCONTO</div>
      <div class="discount-value">-{{ hasPlanDiscount.info }}</div>
    </div>
    <div class="fb-course-card--icon-wrap">
      <div class="fb-course-card--icon">
        <b-icon
          :icon="product.icon_slug"
          pack="custom"
          size="is-normal"
          type="is-primary"
        />
      </div>
    </div>
    <div class="title">
      Curso <br />
      {{ product.title }}
    </div>
  </div>
</template>

<script>
import CardMixin from './CardMixin.vue'
export default {
  extends: CardMixin,
  props: {
    product: {
      type: Object,
      required: true
    },
    isPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style></style>
