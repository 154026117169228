<template>
  <div class="is-home">
    <SiteContainer></SiteContainer>
    <BlackfridayTopPromo />
    <Navbar></Navbar>
    <nuxt />
    <SiteFooter></SiteFooter>
    <ActiveCampaignWhatsappButton />
    <client-only>
      <fb-site-interventional-modal />
      <b-modal
        :active.sync="isCartModalVisible"
        :on-cancel="closeCartModal"
        :can-cancel="false"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <fb-cart-product-added-success />
      </b-modal>
    </client-only>
  </div>
</template>

<script>
import AOS from 'aos'
import { mapState, mapActions } from 'vuex'

import Navbar from '@/components/site/Navbar'
import BlackfridayTopPromo from '@/components/site/BlackfridayTopPromo.vue'
import SiteContainer from '@/components/site/Container'
import SiteFooter from '@/components/site/Footer'
import ActiveCampaignWhatsappButton from '~/components/site/ActiveCampaignWhatsappButton'

export default {
  components: {
    Navbar,
    BlackfridayTopPromo,
    SiteContainer,
    SiteFooter,
    ActiveCampaignWhatsappButton
  },
  computed: {
    ...mapState('cart', ['isCartModalVisible'])
  },
  mounted() {
    AOS.init()
  },
  methods: mapActions({
    fetchProducts: 'product/fetchProducts',
    closeCartModal: 'cart/closeCartModal'
  })
}
</script>
<style></style>
