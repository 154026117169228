import Vue from 'vue'
import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: {
    id: process.env.GA4MetricId
  }
})

export default () => {
  window.gtag('config', process.env.GA4MetricId, {
    transport_url: process.env.gtmTransportUrl,
    first_party_collection: true
  })
}
