import { genUniqSessionId, getUniqueSessionId } from '~/utils/cookies/session'

export default function() {
  window.onNuxtReady(() => {
    const unique_session_id = getUniqueSessionId()

    if (!unique_session_id) {
      genUniqSessionId()
    }
  })
}
