<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="fb-user-select-birthdate"
  >
    <b-field
      :type="{ 'is-danger': fieldMessage }"
      :class="{ shadow: hasShadow }"
      label="Data de nascimento"
    >
      <b-select :value="day" @input="onChangeDay" expanded size="is-large">
        <option v-for="d in days" :key="d" :value="d">{{ d }}</option>
      </b-select>
      <b-select :value="month" @input="onChangeMonth" expanded size="is-large">
        <option v-for="m in months" :key="m" :value="m">{{ m }}</option>
      </b-select>
      <b-select :value="year" @input="onChangeYear" expanded size="is-large">
        <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
      </b-select>
    </b-field>
    <div
      v-if="fieldMessage"
      class="has-text-danger gap-lg-bottom gap-lg-left help"
    >
      {{ fieldMessage.join(', ') }}
    </div>
  </div>
</template>

<script>
import range from 'lodash/range'

const currentYear = new Date().getFullYear()

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: null,
    prevent: {
      type: Boolean,
      default: false
    },
    messages: null,
    fieldName: null,
    hasShadow: null
  },
  data() {
    return {
      day: null,
      days: range(1, 32),
      month: null,
      months: range(1, 13),
      year: null,
      years: range(currentYear - 14, 1959)
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    fieldMessage() {
      if (this.messages) {
        return this.messages[this.fieldName]
      } else {
        return false
      }
    }
  },
  mounted() {
    if (!this.value) {
      return
    }

    const d = this.$dayjs(this.value, 'DD/MM/YYYY')

    this.day = d.date()
    this.month = d.month() + 1 // 0..11
    this.year = d.year()
  },
  methods: {
    onChangeDay(value) {
      this.day = value
      this.emitDate()
    },
    onChangeMonth(value) {
      this.month = value
      this.emitDate()
    },
    onChangeYear(value) {
      this.year = value
      this.emitDate()
    },
    emitDate() {
      const { day, month, year } = this

      if (!day || !month || !year) {
        return
      }

      const date = this.$dayjs()
        .year(year)
        .month(month - 1) // Accepts numbers from 0 to 11.
        .date(day) // day of the month: 1..31

      if (date.isValid()) {
        this.$nextTick(() => {
          // this.$emit('change', date.format('YYYY-MM-DD'))
          this.$emit('change', date.format('DD/MM/YYYY'))
        })
      }
    }
  }
}
</script>

<style lang="scss">
.fb-user-select-birthdate {
  .field {
    flex-wrap: wrap;
    &.shadow {
      filter: drop-shadow($card-box-shadow);
    }
    .label {
      width: 100%;
    }
    .control {
      flex: 1;
      max-width: 100px;
      &:nth-child(2) {
        select {
          border-top-left-radius: 2rem !important;
          border-bottom-left-radius: 2rem !important;
        }
      }
      &:last-child {
        max-width: 100%;
        select {
          border-top-right-radius: 2rem !important;
          border-bottom-right-radius: 2rem !important;
        }
      }
      .select {
        &::after {
          width: 5.04px !important;
          height: 5.04px !important;
          margin-top: -0.15em;
          border-width: 1px;
          border-radius: 0px;
        }
        select {
          padding-left: 1.25rem;
        }
      }
    }
  }
  // &.is-desktop {
  //   padding: $gap ($gap * 10);
  // }
  // &.is-mobile {
  //   padding: $gap ($gap * 2);
  // }
}
</style>
