export const attributes = {
  address: 'endereço',
  number: 'número da residência',
  complement: 'complemento',
  neighborhood: 'bairro',
  cpf: 'CPF',
  name: 'nome completo',
  first_name: 'nome',
  last_name: 'sobrenome',
  phone: 'telefone',
  email: 'email',
  password: 'senha',
  zipcode: 'CEP',
  responsible_name: 'nome',
  responsible_cpf: 'CPF',
  responsible_birthdate: 'data de nascimento',
  responsible_terms_accepted: 'termo de uso'
}
