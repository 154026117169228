<template>
  <div
    v-if="product"
    :class="{ 'is-page': isPage, 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="card fb-card-course__title"
  >
    <!-- CARD e mobile -->
    <div
      v-if="mobile || !isPage"
      :class="{ 'columns is-mobile': isPage }"
      class="card-content"
    >
      <div v-if="!isPage && !mobile" class="fb-card-course__title--professors">
        <div
          v-for="(p, index) in professors"
          :key="index.toString()"
          :style="{ 'background-image': `url(${p.avatar})` }"
          :title="p.name"
          class="professor"
        ></div>
      </div>

      <fb-product-widget
        v-if="isPage"
        :is-page="isPage"
        :product="product"
        :taggable="true"
        :is-recurring-visible="false"
      ></fb-product-widget>
      <fb-detached-course-card-title
        v-else
        :product="product"
        :taggable="false"
        :is-recurring-visible="false"
      ></fb-detached-course-card-title>
    </div>

    <!-- PAGINA DO CURSO desktop -->
    <div v-if="isPage && !mobile" class="columns is-mobile is-vcentered">
      <div class="column">
        <fb-product-widget
          :product="product"
          :taggable="true"
          :is-recurring-visible="false"
        ></fb-product-widget>
      </div>
      <div class="column border-left is-7">
        <div class="fb-card-course__title--info-container">
          <div
            class="fb-card-course__title--price has-text-centered gap-bottom"
          >
            <span v-if="price.discount" class="discount has-text-centered">{{
              price.full_price | currency
            }}</span>
            Por:
            <strong>{{ price.final_price | currency({ symbol: '' }) }}</strong>
            <span class="is-recurring">{{ recurring }}</span> <br />
            Acesso ao curso por <b>{{ plan.interval_count }}</b> meses
          </div>
          <div class="fb-card-course__title--actions columns is-mobile">
            <div class="column">
              <b-button
                @click="addPlan"
                type="is-info"
                rounded
                outlined
                expanded
                size="is-medium"
                icon-left="icon-add-carrinho"
                icon-pack="custom"
              >
                Carrinho
              </b-button>
            </div>
            <div class="column">
              <b-button
                @click="buyNow"
                type="is-info"
                rounded
                expanded
                size="is-medium"
                >Comprar</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import take from 'lodash/take'
import CourseCardMixins from '~/utils/mixins/CourseCardMixins'
export default {
  mixins: [CourseCardMixins],
  props: {
    isPage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    professors() {
      return take(this.product.professors, 2)
    },
    getPeriod() {
      if (this.product && this.product.plans) {
        return {
          plan: this.product.plans[0],
          product: {
            slug: this.product.slug
          }
        }
      }
      return {}
    }
  }
}
</script>

<style lang="scss">
.fb-card-course__title {
  box-shadow: $card-box-shadow;
  .is-recurring {
    color: #a3a3a3;
  }
  &.is-page {
    .fb-card-course-page--title {
      padding: $gap ($gap * 2);
    }
    .title {
      text-transform: uppercase;
    }
    .card-content {
      align-items: center;
    }
    .fb-card-course--tags {
      padding: $gap 0px;
    }
  }
  .card-content {
    padding: ($gap * 3) ($gap * 3) ($gap * 2);
    .fb-product-widget {
      &-title {
        .title {
          line-height: 1.2;
          padding-top: $gap;
        }
      }
    }
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.15;
    margin-bottom: 0px;
    color: $grey-dark !important;
    text-align: center !important;
    padding: $gap $gap;
    width: 100%;
    min-height: $gap * 6;
    // margin-left: $gap * -2;
    // margin-right: $gap * -2;
  }
  &--info {
    margin-left: ($gap * -3);
    margin-right: ($gap * -3);
    font-size: 9px;
    color: $grey-light;
    &-container {
      padding: 0px 1.15rem;
    }
  }
  &--professors {
    position: absolute;
    top: ($gap * -3);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .professor {
      background-size: contain;
      width: ($gap * 6);
      height: ($gap * 6);
      border-radius: 50%;
      border: 1px solid $white;
      margin: 0px ($gap / 2);
    }
  }
  &--actions {
    .button {
      font-size: 12px;
      .icon > i::before {
        font-size: 18px;
      }
    }
  }
  &--price {
    position: relative;
    text-align: right;
    line-height: 1.5;
    strong {
      font-size: 24px;
      color: $info;
    }
    .discount {
      position: absolute;
      top: -4px;
      right: $gap;
      text-decoration: line-through;
      color: $grey-light;
    }
  }
  .icon-rounded {
    @include icon-rounded($grey-light, 6px);
  }
  &.is-page {
    &.is-desktop {
      padding: ($gap * 4) ($gap * 3);
      max-width: 600px;
      margin: 0px auto ($gap * -8) !important;
      .border-left {
        padding-left: $gap * 2;
      }
      .fb-card-course__title--info-container {
        padding: 0px;
      }
      .fb-card-course__title--actions {
        .column {
          padding: 0.75rem 0.5rem;
        }
      }
      .fb-product-widget {
        .fb-product-widget-title {
          margin-left: $gap * -2;
        }
      }
      .fb-product-widget-tags {
        padding: 0px;
      }
      .discount {
        top: $gap * -1.5;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    &.is-mobile {
      .fb-product-widget-title > .title {
        justify-content: flex-start;
      }
      padding: $gap * 2;
    }
  }
}
</style>
