<template>
  <b-navbar
    type="is-primary"
    fixed-top
    wrapper-class="container"
    class="fb-site-navbar"
    shadow
  >
    <template slot="brand">
      <b-navbar-item
        @click="pressMenu"
        class="is-hidden-widescreen navbar-item--burger"
      >
        <b-icon
          icon="icon-menu"
          pack="custom"
          size="is-normal"
          type="is-white"
        ></b-icon>
      </b-navbar-item>

      <b-navbar-item
        :to="{ path: '/' }"
        class="navbar-brand--fb-logo"
        tag="nuxt-link"
      >
        <img
          src="~/assets/images/logo-white-border.png"
          alt="FBOnline"
          width="100px"
          height="55px"
        />
      </b-navbar-item>

      <b-navbar-item
        :to="{ path: '/carrinho' }"
        tag="nuxt-link"
        class="is-hidden-desktop navbar-item--last"
        aria-label="Total de items no carrinho"
      >
        <b-icon
          icon="icon-carrinho"
          type="is-white"
          pack="custom"
          size="is-normal"
        >
        </b-icon>
        <div v-if="totalItems" class="cart-bagde">
          {{ totalItems }}
        </div>
      </b-navbar-item>
    </template>

    <template slot="start">
      <b-navbar-item to="/" tag="nuxt-link" class="is-exact-link">
        Início
      </b-navbar-item>

      <b-navbar-item to="/professores" tag="nuxt-link">
        Professores
      </b-navbar-item>

      <b-navbar-item to="/suporte" tag="nuxt-link">
        Suporte
      </b-navbar-item>

      <!-- <b-navbar-item to="/blog" tag="nuxt-link">
        Blog
      </b-navbar-item> -->
    </template>
    <template slot="end">
      <b-navbar-item class="fb-site-navbar-cart-item" tag="div">
        <div v-if="cartVisible">
          <nuxt-link :to="{ path: '/carrinho' }">
            <b-icon
              tag="nuxt-link"
              icon="icon-carrinho"
              type="is-white"
              pack="custom"
              size="is-normal"
            >
            </b-icon>
          </nuxt-link>
          <div v-show="totalItems" class="cart-bagde">
            {{ totalItems }}
          </div>
        </div>
      </b-navbar-item>
      <b-navbar-item v-if="!isAuthenticated" tag="div">
        <div class="buttons">
          <b-button
            tag="a"
            href="/login"
            type="is-light"
            outlined
            rounded
            class="is-signin"
          >
            Entre
          </b-button>
          <b-button
            type="is-light"
            rounded
            class="is-signup"
            tag="a"
            href="/cadastro"
            >Inscreva-se</b-button
          >
        </div>
      </b-navbar-item>
      <b-navbar-item v-else class="fb-site-navbar-is-logged" tag="div">
        <div class="buttons">
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <picture class="image is-42x42">
                <img :src="currentStudent.avatar" class="is-rounded" />
              </picture>
            </div>
            <div class="column is-10">
              <div class="fb-site-navbar-profile-name">
                {{ currentStudent.name }}
              </div>
              <b-button
                @click.stop="logout"
                type="is-text"
                size="is-small"
                class="fb-site-navbar-logout-btn"
                >Sair</b-button
              >
            </div>
          </div>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    cartVisible: {
      type: Boolean,
      default: true
    }
  },
  computed: mapGetters({
    isAuthenticated: 'isAuthenticated',
    currentStudent: 'currentStudent',
    cart: 'cart',
    totalItems: 'cart/totalItems'
  }),
  methods: {
    pressMenu() {
      this.$root.drawerToggle()
    },
    async logout() {
      await this.$logout()
    },
    ...mapActions({
      $logout: 'auth/logout'
    })
  }
}
</script>

<style lang="scss"></style>
