<template>
  <div
    v-if="product"
    :class="{ 'is-page': isPage, 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="fb-detached-course-title container is-fluid"
  >
    <div class="fb-detached-course-title-title">
      <div class="title is-spaced has-text-centered">
        {{ product.title }}
      </div>

      <div v-if="!isPage" class="offer container is-fluid mt-1">
        <div class="columns is-mobile">
          <div
            v-for="item in showTwoOfferItems()"
            class="column fb-detached-course-title-item"
          >
            <div class="course-item-text">
              <b-icon
                :icon="item.icon"
                pack="custom"
                size="is-small"
                type="is-secondary"
              ></b-icon>
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="taggable" class="fb-detached-course-title-tags">
        <b-tag
          v-for="(tag, index) in product.tag_list"
          :key="index.toString()"
          type="is-warning"
          rounded
          >{{ tag }}</b-tag
        >
      </div>
    </div>
  </div>
</template>

<script>
import take from 'lodash/take'
import keys from 'lodash/keys'

export default {
  props: {
    product: {},
    isPage: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: true
    },
    isRecurringVisible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    offerItems() {
      return keys(this.product.offer).map((a, b) => {
        return this.offerIcon(a)
      })
    },
    allOfferIcons() {
      return [
        {
          offer: 'default',
          icon: 'icon-videoaula',
          text: '+50 vídeos aulas'
        },
        {
          offer: 'default',
          icon: 'icon-horas',
          text: 'Horário flexível'
        },
        {
          offer: 'default',
          icon: 'icon-professor-qualificado',
          text: 'Professores qualificados'
        },
        {
          offer: 'full_access',
          icon: 'icon-horas',
          text: 'Acesso ilimitado'
        },
        {
          offer: 'courseware',
          icon: 'icon-material-didatico',
          text: 'Material didático'
        },
        {
          offer: 'study_plan',
          icon: 'icon-plano-de-curso',
          text: 'Plano de estudo personalizado'
        },
        {
          offer: 'questionnaires',
          icon: 'icon-simulado',
          text: 'Exercícios e simulados'
        },
        {
          offer: 'essays',
          icon: 'icon-redacao',
          text: 'Correção de redação'
        },
        {
          offer: 'monitoring',
          icon: 'icon-monitoria',
          text: 'Monitoria com tira-dúvidas'
        },
        {
          offer: 'live_lessons',
          icon: 'icon-videoaula',
          text: 'Aulas ao vivo'
        }
      ]
    }
  },
  methods: {
    showTwoOfferItems() {
      return take(this.offerItems, 2)
    },
    offerIcon(offer) {
      return this.allOfferIcons.filter((oi) => oi.offer === offer)[0]
    }
  }
}
</script>

<style lang="scss">
.fb-detached-course-title {
  .columns {
    align-items: center;
    box-sizing: border-box;
  }
  &-item {
    padding: ($gap * 0.5) 0px;
    font-size: 10px;
    color: transparentize($text, 0.4);
    .icon {
      margin-right: 2px;
      .custom::before {
        color: transparentize($text, 0.4);
      }
    }
  }
  &-icon {
    position: relative;
    @include icon-ronded-shadow(56px, 56px, $primary);
    width: 100%;
    margin: auto;
    .icon {
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      width: ($gap * 5);
      height: ($gap * 5);
      border-radius: 50%;
      background-color: $white;
      margin: auto;
      z-index: 2;
    }
  }
  &-title {
    .title,
    .subtitle {
      text-align: left !important;
    }
    .title {
      @include title-type-1;
      white-space: pre-wrap;
    }
    .subtitle {
      text-transform: uppercase;
      font-size: 12px;
      padding: ($gap / 2) 0px;
    }
  }

  &-tags {
    padding: $gap 0px;
    .tag.is-warning {
      font-weight: bold;
      color: darken($warning, 22);
      background-color: transparentize($color: $warning, $amount: 0.7);
      padding: 0px ($gap * 1.25);
    }
  }
}
</style>
