import uuidv4 from 'uuid/v4'
import Cookies from 'js-cookie'

export function genUniqSessionId() {
  const unique_session_id = uuidv4()
  Cookies.set('unique_session_id', unique_session_id)
  return unique_session_id
}

export function destroyUniqSessionId() {
  Cookies.remove('unique_session_id')
}

export function getUniqueSessionId() {
  const unique_session_id = Cookies.get('unique_session_id')

  if (unique_session_id) {
    return unique_session_id
  }

  return genUniqSessionId()
}
