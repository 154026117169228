var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fb-responsible-form",class:{ 'is-mobile': _vm.mobile, 'is-desktop': !_vm.mobile }},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"fields"},[_c('b-field',{attrs:{"message":_vm.errors.first('responsible_name') || _vm.getMessage('responsible_name'),"type":{
          'is-danger':
            _vm.errors.has('responsible_name') || _vm.getMessage('responsible_name')
        },"label":"Nome do responsável"}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|first_name'),expression:"'required|first_name'"}],attrs:{"placeholder":"Ex.: Severo James","type":"text","name":"responsible_name","size":"is-large","rounded":""},model:{value:(_vm.responsible_name),callback:function ($$v) {_vm.responsible_name=$$v},expression:"responsible_name"}})],1),_vm._v(" "),_c('b-field',{attrs:{"group-multiline":_vm.mobile,"grouped":""}},[_c('b-field',{attrs:{"message":_vm.errors.first('responsible_cpf') ||
              _vm.getMessage('responsible_cpf') ||
              _vm.getMessage('cpf'),"type":{
            'is-danger':
              _vm.errors.has('responsible_cpf') || _vm.getMessage('responsible_cpf')
          },"label":"CPF do responsável","expanded":""}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|cpf'),expression:"'required|cpf'"},{name:"cleave",rawName:"v-cleave",value:(_vm.masks.cpf),expression:"masks.cpf"}],attrs:{"autocomplete":"off","placeholder":"Ex.: 999.999.999-99","type":"text","name":"responsible_cpf","size":"is-large","rounded":""},on:{"input":_vm.validateAll},model:{value:(_vm.responsible_cpf),callback:function ($$v) {_vm.responsible_cpf=$$v},expression:"responsible_cpf"}})],1),_vm._v(" "),_c('fb-user-select-birthdate',{attrs:{"messages":_vm.messages,"field-name":"responsible_birthdate","has-shadow":"true"},model:{value:(_vm.responsible_birthdate),callback:function ($$v) {_vm.responsible_birthdate=$$v},expression:"responsible_birthdate"}})],1),_vm._v(" "),(!_vm.responsible_terms_accepted_at)?_c('p',{staticClass:"terms"},[_c('b-checkbox',{attrs:{"false-value":"0","true-value":"1"},model:{value:(_vm.responsible_terms_accepted),callback:function ($$v) {_vm.responsible_terms_accepted=$$v},expression:"responsible_terms_accepted"}},[_vm._v("Li e aceito os\n          "),_c('a',{attrs:{"href":"/links/termos-de-uso-e-politica/","target":"_blank"}},[_vm._v("termos de uso e política de privacidade")])])],1):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-0"},[_c('div',{staticClass:"title"},[_vm._v("\n      Insira os dados do Responsável\n    ")])])
}]

export { render, staticRenderFns }