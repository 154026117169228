<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="layout-lite"
  >
    <client-only>
      <fb-site-checkout-flow-header v-if="mobile" :label="label">
        <fb-checkout-progress />
      </fb-site-checkout-flow-header>
      <fb-site-checkout-flow-navbar v-else />
    </client-only>
    <nuxt />
    <client-only>
      <fb-site-footer :lite="true" />
      <fb-site-interventional-modal />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    mobile() {
      return ['xs', 'sm', 'md'].includes(this.$mq)
    },
    ...mapState({
      label: (state) => state.checkoutProgressLabel,
      progress: (state) => state.checkoutProgress
    })
  }
}
</script>
<style lang="scss">
.layout-lite {
  &.is-desktop {
    .lite-page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: ($gap * 8) 0px ($gap * 10);
    }
  }
}
</style>
