import omit from 'lodash/omit'

import { updateProfile } from '@/services/profile'
import { mapProfile } from '@/utils/map'

const unpermitedParams = ['responsible_terms_accepted_at']

const state = () => ({
  student: {},
  loggedIn: false
})

const actions = {
  setAuth({ commit }, payload) {
    commit('SetAuth', payload)
  },
  setProfile({ commit }, payload) {
    commit('SetProfile', payload)
  },
  async login({ commit }, payload) {
    const login_url = 'api/student/v1/user/login'
    const { data } = await this.$axios.post(login_url, payload)
    commit('Login', data)
  },
  localLogin({ commit }, payload) {
    commit('Login', payload)
  },
  // Recuperar senha.
  async newPassword({ commit }, payload) {
    await this.$axios.post('/api/student/v1/user/password', payload)
  },
  async setRemoteProfile({ commit }, payload) {
    let response = {}

    response = await updateProfile(this, {
      ...omit(payload, unpermitedParams)
    })

    if (!response.success) {
      return { success: false, error: response.error }
    }

    commit('SetProfile', mapProfile(response.data))

    return { success: true }
  },
  async profile({ commit }) {
    try {
      commit('SetProfile', { persisting: true })

      const { data } = await this.$axios.get('api/student/v1/user/profile')

      commit('Login', mapProfile(data))
    } catch (error) {
    } finally {
      commit('SetProfile', { persisting: false })
    }
  },
  async logout({ commit }) {
    try {
      // notificando servidor externo
      await this.$axios.delete('api/student/v1/user/logout')
      // sincronizando vuex-persist
      commit('Logout')
    } catch (error) {
      commit('Logout')
    }
  }
}

const mutations = {
  SetAuth(state, { student, loggedIn }) {
    state.student = student
    state.loggedIn = loggedIn
  },
  SetProfile(state, payload) {
    state.student = Object.assign({}, state.student, payload)
  },
  Login(state, payload) {
    state.student = payload
    state.loggedIn = true

    // salvando copia de segurança
    window.sessionStorage.setItem('student', payload && JSON.stringify(payload))
  },
  Logout(state) {
    state.student = {}
    state.loggedIn = false

    this.$cookies.remove('vuex')
    this.$cookies.remove('_palantir_session')

    this.$cookies.remove('current_student_authorization_token', {
      domain: process.env.cookieDomain
    })

    window.sessionStorage.removeItem('student')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
