<template>
  <div>
    <SiteContainer />
    <BlogNavbar />
    <nuxt />
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
import { getStrapiMedia } from '@/utils/media'

import BlogNavbar from '@/components/site/Navbar'
import SiteContainer from '@/components/site/Container'
import SiteFooter from '@/components/site/Footer'

export default {
  components: {
    BlogNavbar,
    SiteContainer,
    SiteFooter
  },
  data() {
    return {
      global: null,
      bannerLoaded: false
    }
  },
  methods: {
    getMedia(url) {
      return getStrapiMedia(url)
    }
  }
}
</script>
