import lozad from 'lozad'

const lozadDirective = {
  name: 'lozad',
  bind(el, _options, vnode) {
    el.classList.add('lozad')

    const observer = lozad(el, {
      loaded() {
        el.classList.add('is-loaded')
        vnode.context.$emit('load', true)
      }
    })
    observer.observe()
  },
  unbind(el) {
    const observer = lozad(el)
    observer.observer.unobserve(el)
  }
}

export default lozadDirective
