<template>
  <b-navbar
    type="is-primary"
    fixed-top
    wrapper-class="container"
    class="fb-site-navbar"
    shadow
  >
    <template slot="brand">
      <b-navbar-item
        @click="pressMenu"
        class="is-hidden-widescreen navbar-item--burger"
      >
        <b-icon
          icon="icon-menu"
          pack="custom"
          size="is-normal"
          type="is-white"
        ></b-icon>
      </b-navbar-item>

      <b-navbar-item
        :to="{ path: '/' }"
        class="navbar-brand--fb-logo"
        tag="nuxt-link"
      >
        <img
          src="~/assets/images/logo-white-border.png"
          alt="FB Online"
          width="100px"
        />
      </b-navbar-item>

      <b-navbar-item
        :to="{ path: '/carrinho' }"
        tag="nuxt-link"
        class="is-hidden-desktop navbar-item--last"
      >
        <b-icon
          icon="icon-carrinho"
          type="is-white"
          pack="custom"
          size="is-normal"
        >
        </b-icon>
        <div v-if="totalItems" class="cart-bagde">
          {{ totalItems }}
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import Navbar from '@/components/site/Navbar'
export default {
  extends: Navbar
}
</script>
