<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="card fb-card-course is-horizontal is-clipped"
  >
    <nuxt-link
      :title="product.title"
      :to="`/curso-avulso/${product.slug}`"
      class="fb-card-course--link"
    ></nuxt-link>

    <!-- card-content -->
    <div class="card-content p-0">
      <div class="columns is-gapless">
        <div class="column is-4">
          <div class="fb-card-course--gap">
            <div class="fb-card-course--info">
              <div class="fb-card-course--price">
                <span class="currency">R$</span>
                <span class="price">{{ price.per_month.integer_part }}</span>
                <span class="cents"
                  >,{{ price.per_month.decimal_part }}/ mês</span
                >
              </div>
              <div class="total_price">
                Total <b>{{ price.final_price | currency }}</b>
              </div>
            </div>

            <ul class="fb-course-card--description">
              <li v-for="o in product.offer" :key="o">
                {{ o }}
              </li>
            </ul>

            <div class="fb-card-course--actions ">
              <b-button
                @click="addPlan"
                type="is-info"
                rounded
                outlined
                expanded
                size="is-medium"
                icon-left="icon-add-carrinho"
                icon-pack="custom"
                class="mb-1"
              >
                Carrinho
              </b-button>
              <b-button
                @click="buyNow"
                type="is-info"
                rounded
                expanded
                size="is-medium"
                >Comprar</b-button
              >
            </div>
          </div>
        </div>
        <div class="column fb-card-course-media is-8">
          <slot name="media"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CourseCardMixins from '~/utils/mixins/CourseCardMixins'

export default {
  mixins: [CourseCardMixins],
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    getPeriod() {
      if (this.product && this.product.plans) {
        const { slug, title, type } = this.product
        return {
          plan: this.product.plans[0],
          product: {
            slug,
            title,
            type
          }
        }
      }
      return {}
    }
  },
  methods: {
    addPlan() {
      this.$addPlan(this.getPeriod)
    },
    ...mapActions({
      $addPlan: 'cart/addPlan'
    })
  }
}
</script>

<style lang="scss">
.fb-card-course {
  &.is-horizontal {
    .fb-card-course {
      &--gap {
        padding: 2rem;
        @include mobile {
          padding: 1rem 2rem;
        }
        .fb-course-card--description {
          margin-left: 1.5rem !important;
        }
      }
      &--info {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mobile {
          margin-top: 0px;
        }
        .total_price {
          display: block;
          width: 100%;
          color: $grey-light;
          margin: 0px auto $gap;
          font-size: 14px;
          text-align: center;
          font-weight: normal;
          text-transform: none;
          b {
            font-weight: bold;
          }
        }
      }
      &--price {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        .price {
          font-size: 57px;
          margin: 0px $gap;
          line-height: 1;
          font-weight: bold;
          color: #09456f;
        }
        .currency,
        .cents {
          font-size: 17px;
          color: #09456f;
          margin-left: ($gap * -1);
        }
      }
    }
    .columns {
      @include mobile {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  &-media {
    position: relative;
    overflow: hidden;
    @include mobile {
      overflow: initial;
    }
    img.background {
      object-fit: cover;
      object-position: center;
      width: 100%;
      max-height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      @include mobile {
        position: static;
      }
    }
    img.logo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 5%;
      margin: auto;
      z-index: 1;
      @include mobile {
        max-width: 70%;
      }
    }
  }
}
</style>
