<template>
  <div>
    <SiteContainer></SiteContainer>
    <NavbarFlat></NavbarFlat>
    <div class="fb-lead container is-blog">
      <nuxt />
    </div>
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
import NavbarFlat from '@/components/site/NavbarFlat'
import SiteContainer from '@/components/site/Container'
import SiteFooter from '@/components/site/Footer'

export default {
  components: {
    NavbarFlat,
    SiteContainer,
    SiteFooter
  }
}
</script>

<style></style>
