<template>
  <div class="fb-product-widget-icon">
    <b-icon
      :icon="icon"
      pack="custom"
      size="is-medium"
      type="is-primary"
    ></b-icon>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.fb-product-widget-icon {
  position: relative;
  @include icon-ronded-shadow(56px, 56px, $primary);
  width: 59px;
  height: 59px;
  margin: auto;
  .icon {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: ($gap * 5);
    height: ($gap * 5);
    border-radius: 50%;
    background-color: $white;
    margin: auto;
    z-index: 2;
  }
}
</style>
