<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="fb-user-form-person"
  >
    <div
      :class="{ 'card--shadow': mobile, 'card--no-shadow': !mobile }"
      class="card"
    >
      <div class="card-header">
        <div class="card-header-title pt-2">
          <div class="title">
            Confirme seus dados
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="fields">
          <b-field
            :message="errors.first('name') || getMessage('name')"
            :type="{
              'is-danger': errors.has('name')
            }"
            label="Nome Completo"
            expanded
          >
            <b-input
              v-model="name"
              v-validate="'required|full_name'"
              name="name"
              autocomplete="off"
              placeholder="Informe seu primeiro nome"
              rounded
            />
          </b-field>
          <b-field :group-multiline="mobile" grouped>
            <b-field
              :message="errors.first('cpf') || getMessage('cpf')"
              :type="{
                'is-danger': errors.has('cpf') || getMessage('cpf')
              }"
              label="CPF"
              expanded
            >
              <b-input
                v-model="cpf"
                v-validate="'required|cpf'"
                v-cleave="masks.cpf"
                autocomplete="off"
                placeholder="Ex.: 999.999.999-99"
                type="text"
                name="cpf"
                size="is-large"
                rounded
              ></b-input>
            </b-field>
            <fb-user-select-birthdate
              v-model="birthdate"
              :messages="messages"
            />
          </b-field>
        </div>
      </div>
      <span v-if="isStudentUnderAge">
        <fb-user-responsible-form
          @validate="onResponsibleValidate"
          @messages="onResponsibleMessages"
          :messages="messages"
        />
      </span>
      <div class="card-content">
        <slot
          :persisted="persisted"
          :validated="validated"
          :busy="busy"
          :invalid="invalid"
          :submit="submit"
          name="submitButton"
        >
          <div
            v-if="!persisted || !validated"
            class="fb-user-form-person-submit"
          >
            <b-button
              @click="submit"
              :disabled="busy || invalid"
              :loading="busy"
              type="is-info"
              size="is-large"
              rounded
              expanded
            >
              Continuar
            </b-button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { splitFullName } from '@/utils/strings'
import cleave from '@/utils/directives/cleave'

export default {
  directives: { cleave },
  data() {
    return {
      busy: false,
      messages: {},
      isResponsibleValid: false,
      isPersonValid: false,
      masks: {
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
          onValueChanged: ({ target }) => {
            this.$nextTick(() => {
              if (target.value !== this.cpf) {
                this.$set(this, 'cpf', target.value)
              }
            })
          }
        }
      }
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    currentStudent() {
      return this.$store.getters.currentStudent
    },
    name: {
      get() {
        return this.currentStudent?.name
      },
      set(name) {
        this.setProfile({ name })
      }
    },
    first_name: {
      get() {
        return this.currentStudent?.first_name
      },
      set(first_name) {
        this.setProfile({ first_name })
        this.validateAll()
      }
    },
    last_name: {
      get() {
        return this.currentStudent?.last_name
      },
      set(last_name) {
        this.setProfile({ last_name })
        this.validateAll()
      }
    },
    cpf: {
      get() {
        return this.currentStudent?.cpf
      },
      set(cpf) {
        this.setProfile({ cpf })
        this.validateAll()
      }
    },
    birthdate: {
      get() {
        return this.currentStudent?.birthdate
      },
      set(birthdate) {
        this.setProfile({ birthdate })
        this.validateAll()
      }
    },
    persisted: {
      get() {
        return this.currentStudent?.persisted
      },
      set(persisted) {
        this.setProfile({ persisted })
      },
      cache: false
    },
    validated: {
      get() {
        return this.currentStudent?.validated
      },
      set(validated) {
        this.setProfile({ validated })
      }
    },
    invalid() {
      if (this.isStudentUnderAge) {
        return !this.isPersonValid || !this.isResponsibleValid
      }
      return !this.isPersonValid
    },
    isStudentUnderAge() {
      const birthdate = this.$dayjs(this.birthdate, 'DD/MM/YYYY')
      const age = this.$dayjs().diff(birthdate, 'years')
      return age < 18
    }
  },
  watch: {
    name(nextValue) {
      const name = splitFullName(nextValue)
      this.first_name = name ? name.firstName : nextValue
      this.last_name = name ? name.lastName : ''
    },
    cpf(nextValue, oldValue) {
      this.validateAll(nextValue === oldValue)
    },
    invalid(nextValue) {
      this.$set(this, 'validated', !nextValue)
    }
  },
  async mounted() {
    await this.validateAll(true)
    // Inicializando campos do aluno
    this.name = this.currentStudent.name
  },
  methods: {
    async submit() {
      const {
        first_name,
        last_name,
        cpf,
        birthdate,
        responsible_name,
        responsible_cpf,
        responsible_birthdate,
        responsible_terms_accepted_at
      } = this.currentStudent

      this.busy = true

      await this.$validator.validateAll()

      const response = await this.setRemoteProfile({
        last_name,
        first_name,
        cpf,
        birthdate,
        responsible_name,
        responsible_cpf,
        responsible_birthdate,
        responsible_terms_accepted_at,
        responsible_terms_accepted: responsible_terms_accepted_at ? '1' : '0'
      })

      if (!response.success) {
        this.messages = response.error?.errors
      } else {
        this.$set(this, 'persisted', true)
      }

      this.busy = false

      return response?.success
    },
    async validateAll(skipPersisted) {
      const valid = await this.$validator.validateAll(null, {
        silent: true
      })

      this.messages = {}

      this.isPersonValid = valid && !!this.birthdate

      if (!skipPersisted) {
        this.$set(this, 'persisted', false)
      }

      return this.isPersonValid
    },
    onResponsibleValidate(valid) {
      this.isResponsibleValid = valid
    },
    onResponsibleMessages(nextMessages) {
      this.messages = nextMessages
    },
    getMessage(attr) {
      if (!this.messages || !this.messages[attr]) {
        return ''
      }
      return this.messages[attr][0]
    },
    ...mapActions('auth', ['setProfile', 'setRemoteProfile'])
  }
}
</script>

<style lang="scss">
.fb-user-form-person {
  &-submit {
    .button {
      font-size: 12px;
      max-width: 295px;
      margin: auto;
    }
  }
  &.is-desktop {
    padding: 0px;
    .fb-user-form-person-submit {
      padding: 0px ($gap * 10) $gap * 6;
    }
  }
  &.is-mobile {
    .fb-user-form-person-submit {
      padding: 0px ($gap * 2) $gap * 6;
    }
  }
}
</style>
