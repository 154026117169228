<template>
  <div :class="{ 'is-verse': isCvvFocus }" class="credit-card">
    <div v-if="!isCvvFocus && cardBrand" class="credit-card-brand">
      <img :src="cardBrand" alt="Bandeira do Cartão" />
    </div>
    <div class="container is-fluid">
      <div v-if="!isCvvFocus" class="columns is-mobile credit-card-numbers">
        <div class="column">{{ cardNumber }}</div>
      </div>
      <div v-if="isCvvFocus" class="credit-card-magnetic"></div>

      <div v-if="isCvvFocus" class="columns is-mobile credit-card-info">
        <div class="column is-8"></div>
        <div class="column is-4 credit-card-cvv">
          <span>{{ cardCvv ? cardCvv : '***' }}</span>
          <div class="credit-card-cvv-circle"></div>
        </div>
      </div>
      <div v-else class="columns is-mobile credit-card-info">
        <div class="column is-9 credit-card-name">
          {{ holderName ? holderName : 'Nome no Cartão' }}
        </div>
        <div class="column is-3 credit-card-date">
          {{ cardExpiration ? cardExpiration : 'MM/AA' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardBrand: null,
    cardNumber: null,
    holderName: null,
    cardExpiration: null,
    cardCvv: null,
    isCvvFocus: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.credit-card {
  display: flex;
  align-items: flex-end;
  background-color: #72b5e8;
  position: relative;
  width: $gap * 38;
  height: $gap * 20;
  margin: $gap auto ($gap * 3);
  border-radius: $gap * 2;
  font-weight: bold;
  color: white;
  .container {
    margin: 0px !important;
  }
  &-info {
    padding-bottom: $gap * 5;
  }
  &-name {
    overflow: hidden;
    word-wrap: normal;
    text-transform: uppercase;
    opacity: 0.5;
  }
  &-date {
    text-align: right;
    opacity: 0.5;
  }
  &-numbers {
    margin-bottom: $gap * 2;
    .column {
      text-align: center;
      letter-spacing: $gap * 0.5;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: left;
      }
    }
  }
  &-brand {
    position: absolute;
    top: $gap * 2;
    left: $gap * 2;
    img {
      width: 48px;
    }
  }
  &-magnetic {
    background-color: darken($color: $info, $amount: 2);
    height: $gap * 4;
    margin: 0px ($gap * -3) $gap * 2;
  }
  &-cvv {
    position: relative;
    background-color: lighten($info, 50);
    text-align: center;
    color: $info;
    padding-top: $gap / 2;
    padding-bottom: $gap / 2;
    margin-left: $gap * 2;
    font-size: 14px;
    &-circle {
      position: absolute;
      width: 64px;
      height: 64px;
      border: 2px solid white;
      border-radius: 50%;
      top: -20px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
    }
  }
  .container {
    padding: 0px ($gap * 3);
  }
  &.is-verse {
    .credit-card-info {
      margin-top: $gap * 2;
      padding-bottom: $gap * 8;
    }
  }
}
</style>
