<template>
  <div class="form-login is-mobile">
    <div class="container is-fluid lite-container">
      <form @submit.prevent="newPassword" method="post" novalidate>
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field
              :message="errors.first('email')"
              :type="{ 'is-danger': errors.has('email') }"
              label="Email"
            >
              <b-input
                v-model="email"
                v-validate="'required'"
                placeholder="seu email"
                type="email"
                name="email"
                autocomplete="off"
                autofocus="true"
                size="is-large"
                icon-pack="custom"
                icon="icon-usuario"
                rounded
              ></b-input>
            </b-field>
          </div>
        </div>

        <b-notification
          :active.sync="isVisibleNotificationMessage"
          type="is-info"
          has-icon
          role="alert"
        >
          {{ messages }}
        </b-notification>

        <recaptcha />

        <div class="control">
          <b-button
            :disabled="disabled || invalid"
            :loading="disabled"
            class="is-fullwidth"
            type="is-info"
            size="is-large"
            native-type="submit"
            rounded
          >
            Resetar senha
          </b-button>
        </div>
      </form>

      <div class="has-text-centered">
        Já é um aluno?
        <nuxt-link to="/login">Entre</nuxt-link>
      </div>

      <div class="has-text-centered">
        <p>
          Não tem uma conta?
          <nuxt-link to="/cadastro">Cadastre-se</nuxt-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    messages: {
      type: String
    }
  },
  data() {
    return {
      email: '',
      error: null,
      isVisibleNotificationMessage: false,
      notificationMessage: null,
      active: false
    }
  },
  computed: {
    invalid() {
      return !this.email
    }
  },
  watch: {
    messages(messages) {
      this.isVisibleNotificationMessage = !!messages
    }
  },
  methods: {
    async newPassword() {
      this.$emit('disabled', true)

      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
      } catch (error) {}

      const { email } = this
      const result = await this.$validator.validateAll()

      if (result) {
        this.$emit('newPassword', {
          email
        })
      }
    }
  }
}
</script>

<style></style>
