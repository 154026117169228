import auth from './auth'
import blog from './blog'
import cart from './cart'
import site from './site'
import product from './product'

export default {
  auth,
  blog,
  cart,
  site,
  product
}
