const ignorePaths401 = ['users/sign_in']

export default function({ $axios, app, redirect, store }, inject) {
  $axios.onRequest((config) => {
    config.headers.common.Accept = 'application/json'
    config.headers.common['Content-Type'] = 'application/json'

    const accessToken = app.$cookies.get('current_student_authorization_token')

    // Em chamadas para a API do FB Online adiciona o access token.
    if (!config.foreign_request && accessToken) {
      config.headers.Authorization = accessToken
    }
  })

  // Guarda o Bearer token retornado pelo login.
  // Esse token informa que o aluno esta logado.
  $axios.onResponse((res) => {
    const { headers } = res
    if (headers && headers.authorization) {
      app.$cookies.set(
        'current_student_authorization_token',
        headers.authorization,
        {
          domain: process.env.cookieDomain
        }
      )
    }
  })

  $axios.onError((error) => {
    try {
      const code = error.response.status
      const url = error.response.config.url
      const isIgnored = ignorePaths401.includes(url)
      if (isIgnored) {
        return
      }
      if (code === 401 && store.getters.isAuthenticated) {
        return redirect('/sair')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.warn('onError', error)
    }
  })

  // Plugin persistência de dados com Blog
  const blog = $axios.create({
    baseURL: process.env.baseBlogUrl,
    withCredentials: false
  })

  blog.setHeader('Content-Type', 'application/json')

  inject('blog', blog)
}
