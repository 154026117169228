import sha256 from 'js-sha256'

export function splitFullName(str) {
  const firstWhiteSpaceIndex = str.indexOf(' ')
  if (firstWhiteSpaceIndex !== -1) {
    const firstName = str.slice(0, firstWhiteSpaceIndex)
    const lastName = str.slice(firstWhiteSpaceIndex + 1, str.length)

    return { firstName, lastName }
  }

  return null
}

export function toHash(str) {
  return sha256(str)
}
