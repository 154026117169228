<template>
  <b-navbar
    :mobile-burger="false"
    type="is-primary"
    wrapper-class="container"
    class="fb-site-checkout-flow-navbar"
    fixed-top
    shadow
  >
    <template slot="brand">
      <b-navbar-item
        :to="{ path: '/' }"
        class="navbar-brand--fb-logo"
        tag="nuxt-link"
      >
        <img
          src="~/assets/images/logo-white-border.png"
          alt="FB Online"
          width="100px"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <slot name="start">
        <Progress :has-label="true" />
      </slot>
    </template>
  </b-navbar>
</template>

<script>
import Progress from '@/components/checkout/Progress'
export default {
  components: { Progress }
}
</script>

<style lang="scss">
.fb-site-checkout-flow-navbar {
  margin-bottom: $gap * 6;
  .navbar-start {
    flex: 1;
    margin: auto 0px auto -118px;
    pointer-events: none;
    @media screen and (max-width: $desktop) {
      display: flex;
    }
    .fb-checkout-progress {
      padding-top: 0px;
      padding-bottom: $gap * 3;
    }
  }
  @media (max-width: $tablet) {
    display: none;
  }
}
</style>
