// LAYOUT
import SiteNavbar from './site/Navbar.vue'
import SiteSocialMedia from './site/SocialMedia.vue'
import SiteFooter from './site/Footer.vue'
import SiteContainer from './site/Container.vue'
import SiteSideMenu from './site/SideMenu.vue'
import SiteInterventionalModal from './site/InterventionalModal.vue'
import SiteSwitchNavigation from './site/SwitchNavigation.vue'

import SiteCheckoutFlowHeader from './site/CheckoutFlowHeader.vue'
import SiteCheckoutFlowNavbar from './site/CheckoutFlowNavbar.vue'

// BLOG
import BlogNavbar from './blog/Navbar.vue'

// USUARIO
import UserFormPerson from './user/UserFormPerson.vue'
import UserResponsibleForm from './user/UserResponsibleForm.vue'
import UserSelectBirthdate from './user/SelectBirthdate.vue'
import UserFormLogin from './user/FormLogin.vue'
import UserFormRegister from './user/FormRegister.vue'
import UserFormNewPassword from './user/FormNewPassword.vue'
import UserMyCourses from './user/MyCourses.vue'

// PRODUTO

// curso

import CourseCard from './product/course/Card.vue'
import CoursePeriodSelect from './product/course/PeriodSelect.vue'

// curso avulso

import DetachedCourses from './product/detached-course/DetachedCourses.vue'
import DetachedCourseCard from './product/detached-course/Card.vue'
import DetachedCourseCardHorizontal from './product/detached-course/CardHorizontal.vue'
import DetachedCourseCardTitle from './product/detached-course/CardTitle.vue'
import DetachedCourseTitle from './product/detached-course/Title.vue'

// produto

import ProductBanner from './product/ProductBanner.vue'
import ProductTabs from './product/Tabs.vue'
import ProductStudyMaterial from './product/StudyMaterial.vue'
import ProductAbout from './product/About.vue'
import ProductResume from './product/Resume.vue'
import ProductWidget from './product/Widget.vue'
import ProductWidgetIcon from './product/WidgetIcon.vue'
import ProductVideo from './product/Video.vue'

// PROFESSOR
import ProfessorCard from './professor/Card.vue'

// CARRINHO
import CartItem from './cart/Item.vue'
import CartProductAddedSuccess from './cart/modal/ProductAdded.vue'

// Checkout
import CheckoutItem from './checkout/Item.vue'
import CheckoutCoupon from './checkout/Coupon.vue'
import CheckoutProgress from './checkout/Progress.vue'
import CheckoutPaymentMethodSelection from './checkout/PaymentMethodSelection.vue'
import CheckoutTshirtForm from './checkout/TshirtForm.vue'

// Associados
import AssociateProgress from './associate/Progress.vue'

// Metodos de pagamento
import CreditCard from './checkout/CreditCard.vue'
import CreditCardSelectBrand from './checkout/CreditCardSelectBrand.vue'
import CheckoutCreditCardForm from './checkout/CreditCardForm.vue'
import CheckoutPayWithBankSlip from './checkout/PayWithBankSlip.vue'
import CheckoutPayWithCreditCard from './checkout/PayWithCreditCard.vue'
import SuccessWithBankSlip from './checkout/SuccessWithBankSlip.vue'
import SuccessWithCreditCard from './checkout/SuccessWithCreditCard.vue'

const Components = {
  install: (Vue) => {
    // LAYOUT
    Vue.component('fb-site-navbar', SiteNavbar)
    Vue.component('fb-site-container', SiteContainer)
    Vue.component('fb-site-side-menu-content', SiteSideMenu)

    Vue.component('fb-site-interventional-modal', SiteInterventionalModal)
    Vue.component('fb-site-social-media', SiteSocialMedia)
    Vue.component('fb-site-footer', SiteFooter)

    Vue.component('fb-site-checkout-flow-header', SiteCheckoutFlowHeader)
    Vue.component('fb-site-checkout-flow-navbar', SiteCheckoutFlowNavbar)
    Vue.component('fb-site-switch-navigation', SiteSwitchNavigation)

    // BLOG
    Vue.component('fb-blog-navbar', BlogNavbar)

    // USUARIO
    Vue.component('fb-user-my-courses', UserMyCourses)
    Vue.component('fb-user-form-login', UserFormLogin)
    Vue.component('fb-user-form-register', UserFormRegister)
    Vue.component('fb-user-form-new-password', UserFormNewPassword)
    Vue.component('fb-user-select-birthdate', UserSelectBirthdate)
    Vue.component('fb-user-form-person', UserFormPerson)
    Vue.component('fb-user-responsible-form', UserResponsibleForm)

    // PRODUTO

    // curso
    Vue.component('fb-course-card', CourseCard)
    Vue.component('fb-course-card-period-select', CoursePeriodSelect)

    // curso avulso
    Vue.component('fb-detached-courses', DetachedCourses)
    Vue.component('fb-detached-course-card', DetachedCourseCard)
    Vue.component(
      'fb-detached-course-card-horizontal',
      DetachedCourseCardHorizontal
    )
    Vue.component('fb-detached-course-card-title', DetachedCourseCardTitle)
    Vue.component('fb-detached-course-title', DetachedCourseTitle)

    // produto

    Vue.component('fb-product-banner', ProductBanner)

    Vue.component('fb-product-tabs', ProductTabs)
    Vue.component('fb-product-study-material', ProductStudyMaterial)
    Vue.component('fb-product-about', ProductAbout)
    Vue.component('fb-product-resume', ProductResume)
    Vue.component('fb-product-widget', ProductWidget)
    Vue.component('fb-product-widget-icon', ProductWidgetIcon)
    Vue.component('fb-product-video', ProductVideo)

    // PROFESSOR
    Vue.component('fb-professor-card', ProfessorCard)

    // CARRINHO
    Vue.component('fb-cart-product-added-success', CartProductAddedSuccess)
    Vue.component('fb-cart-item', CartItem)

    // CHECKOUT
    Vue.component('fb-checkout-progress', CheckoutProgress)
    Vue.component('fb-success-with-credit-card', SuccessWithCreditCard)
    Vue.component('fb-success-with-bank-slip', SuccessWithBankSlip)

    Vue.component('fb-checkout-item', CheckoutItem)
    Vue.component('fb-checkout-coupon', CheckoutCoupon)

    Vue.component(
      'fb-checkout-payment-method-selection',
      CheckoutPaymentMethodSelection
    )
    Vue.component('fb-checkout-pay-with-bank-slip', CheckoutPayWithBankSlip)
    Vue.component('fb-checkout-pay-with-credit-card', CheckoutPayWithCreditCard)
    Vue.component('fb-checkout-credit-card', CreditCard)
    Vue.component('fb-checkout-credit-card-select-brand', CreditCardSelectBrand)
    Vue.component('fb-checkout-credit-card-form', CheckoutCreditCardForm)
    Vue.component('fb-checkout-tshirt-form', CheckoutTshirtForm)

    // ASSOCIATE
    Vue.component('fb-associate-progress', AssociateProgress)
  }
}

export default Components
