import { getUniqueSessionId } from '~/utils/cookies/session'

export async function checkout(app, cart) {
  const { $axios } = app
  const unique_session_id = getUniqueSessionId(app)

  try {
    const { data } = await $axios.post(
      `/api/student/v1/carts/${cart.id}/checkout`,
      {
        unique_session_id,
        cart: {
          installments: cart.installments,
          payment_method_code: cart.payment_method_code
        }
      }
    )
    // RETORNO:
    // {
    //   success: true,
    //   subscription: 10,
    //   plans: [1,2]
    // }
    return { success: true, data }
  } catch (e) {
    const { data } = e.response
    return { success: false, data }
  }
}
