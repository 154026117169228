<template>
  <client-only>
    <b-loading
      :is-full-page="true"
      :active.sync="blocked"
      :can-cancel="false"
    ></b-loading>
  </client-only>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: mapState(['blocked'])
}
</script>

<style></style>
