<template>
  <div
    v-if="categories && categories.length"
    :class="[type]"
    class="fb-blog-category-menu"
  >
    <div class="fb-blog-category-menu-list">
      <div @click.stop="toggleMenu" class="fb-blog-mobile-toggle">
        Categorias
      </div>
      <ul :class="{ 'is-opened': opened }">
        <li v-for="category in categories" :key="category.id">
          <b-dropdown v-if="hasManySubCategories(category)">
            <button
              slot="trigger"
              slot-scope="{ active }"
              class="button is-text"
            >
              <span>{{ category.name }}</span>
              <b-icon
                :icon="active ? 'chevron-up' : 'chevron-down'"
                type="is-primary"
              ></b-icon>
            </button>
            <b-dropdown-item
              v-for="subcategory in category.subcategories"
              :key="subcategory.id"
              :has-link="true"
            >
              <nuxt-link
                :to="blogPath('categorias', category.slug, subcategory.slug)"
              >
                {{ subcategory.name }}
              </nuxt-link>
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-else
            :to="categoryPath(category)"
            type="is-text"
            tag="nuxt-link"
          >
            {{ category.name }}
          </b-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Mixins from './mixins'

export default {
  extends: Mixins,
  props: {
    type: null
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    ...mapState({
      categories: ({ blog }) => blog.categories
    })
  },
  mounted() {
    if (this.categories && this.categories.length) {
      return
    }

    this.fetchCategories(this)
  },
  methods: {
    categoryPath(category) {
      if (this.hasSubCategories(category)) {
        return this.blogPath(
          'categorias',
          category.slug,
          category.subcategories[0].slug
        )
      }
      return this.blogPath('categorias', category.slug)
    },
    hasSubCategories(category) {
      return category?.subcategories?.length
    },
    hasManySubCategories(category) {
      return (
        this.hasSubCategories(category) && category?.subcategories?.length > 1
      )
    },
    toggleMenu() {
      this.opened = !this.opened
    },
    ...mapActions('blog', ['fetchCategories'])
  }
}
</script>

<style lang="scss">
.fb-blog-category-menu {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
  margin: 4rem auto $gap * 6;

  .fb-blog-mobile-toggle {
    display: none;
  }

  &.is-home {
    margin: -2.5rem auto $gap * 2;
  }

  .fb-blog-category-menu-list {
    background: darken(#f2f6f4, 5);
    padding: 1rem 1.5rem;
    border-radius: 2rem;
  }
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      &:not(:last-child) {
        margin-right: 1rem;
      }
      .button {
        @include link-color(#595959);
        background: darken(#f2f6f4, 5);
        font-size: 1.2rem;
        font-weight: bold;
        box-shadow: none;
        text-transform: none;
        &:hover,
        &:focus,
        &:active {
          background: darken(#f2f6f4, 10);
        }
      }
      .dropdown .dropdown-menu .has-link a {
        font-size: 1.2rem !important;
      }
    }
  }
  @media (max-width: $tablet) {
    padding: 0px $gap * 2;
    ul,
    ul li {
      display: block;
      width: 100%;
    }

    .fb-blog-category-menu-list {
      width: 100%;
      > ul:not(.is-opened) {
        display: none;
      }
    }

    .fb-blog-mobile-toggle {
      display: block;
      font-weight: bold;
      font-size: 1.2rem;
      text-align: center;
      padding: 0.5rem 0px;
      &:hover {
        cursor: pointer;
      }
    }

    .dropdown {
      display: block;
    }
    .button {
      display: flex;
      width: 100%;
      span:not(.icon) {
        flex: 1;
      }
    }
  }
}
</style>
