export function addScript(id, src, callback) {
  const s = document.createElement('script')

  s.addEventListener('load', () => callback && callback())

  s.setAttribute('src', src)
  s.setAttribute('id', id)

  document.body.appendChild(s)
}

export function removeScript(id) {
  const s = document.getElementById(id)
  s.remove()
}
