const currentYear = new Date().getFullYear()

const DEFAULT_SEO_TITLE = `ENEM ${currentYear}, Vestibulares de Medicina e Faculdades Militares`
const DEFAULT_SEO_DESCRIPTION =
  'O FB Online é o curso online do Farias Brito, uma Instituição que tem mais de 80 anos de experiência em educação.'
const DEFAULT_SEO_KEYWORDS = `farias brito online, fbonline, enem, enem ${currentYear}, medicina, ita, ime, vestibulares`

const logo = require('~/assets/images/logo.png')

const DEFAULT_LOGO_URL = `${process.env.baseUrl}${logo}`

export function createSEO(
  title = DEFAULT_SEO_TITLE,
  keywords = DEFAULT_SEO_KEYWORDS
) {
  return {
    title,
    meta: [
      {
        hid: 'keywords',
        name: 'keywords',
        content: keywords
      },

      // Facebook
      { name: 'fb:app_id', content: '1014929132241280' },

      // Open Graph
      { name: 'og:title', content: title },
      { name: 'og:type', content: 'website' },
      { name: 'og:url', content: process.env.baseUrl },

      // Twitter Card
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: '@olinefb_' },
      { name: 'twitter:title', content: title }
    ]
  }
}

export function createSEODescription(description) {
  return [
    {
      hid: 'description',
      name: 'description',
      content: description || DEFAULT_SEO_DESCRIPTION
    },
    { name: 'og:description', content: description || DEFAULT_SEO_DESCRIPTION },
    {
      name: 'twitter:description',
      content: description || DEFAULT_SEO_DESCRIPTION
    }
  ]
}

export function createSEOImage(image, imageAlt) {
  return [
    { name: 'og:image', content: image || DEFAULT_LOGO_URL },
    { name: 'twitter:image', content: image || DEFAULT_LOGO_URL },
    { name: 'twitter:image:alt', content: imageAlt || 'FB Online Logo' }
  ]
}

export function mergeMetas(...metas) {
  return metas.reduce((acumulator, meta) => {
    return acumulator.concat(meta)
  })
}
