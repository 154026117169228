<template>
  <b-field class="fb-course-card-btn-radio">
    <b-radio-button
      :value="input"
      @input="onChangeInput"
      v-for="(period, index) in periods"
      :key="period.interval_count"
      :native-value="index"
      type="is-info"
    >
      <span>{{ getPeriodTitle(period.interval_count) }}</span>
    </b-radio-button>
  </b-field>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  model: {
    prop: 'input',
    event: 'change'
  },
  props: {
    input: {
      type: Number,
      default: 0
    },
    course: {
      type: Object,
      default: null
    },
    periods: {
      type: Array
    },
    canSwitchPlan: {
      type: Boolean,
      default: false
    }
  },
  computed: mapState({
    courses: ({ product }) => product.courses
  }),
  methods: {
    getPeriodTitle(intervalCount) {
      switch (intervalCount) {
        case 1:
          return 'Mensal'
        case 6:
          return 'Semestral'
        case 12:
          return 'Anual'
      }
    },
    onChangeInput(value) {
      this.$emit('change', value)
      if (this.canSwitchPlan) {
        const period = this.getPeriod(value)
        this.addPlan(period)
      }
    },
    getPeriod(value) {
      const { slug, title, type } = this.course
      const plan = this.course.plans[value]
      return {
        plan,
        product: {
          slug,
          title,
          type
        },
        skipModal: true,
        skipLoading: true
      }
    },
    ...mapActions({
      addPlan: 'cart/addPlan'
    })
  }
}
</script>

<style lang="scss">
.fb-course-card-btn-radio {
  position: relative;
  align-items: center;
  justify-content: center !important;
  background-color: $info-light;
  max-width: ($gap * 40);
  margin: 0px auto 0px;
  border-radius: ($gap * 6) !important;
  z-index: 3;
  .control {
    flex: 1;
  }
  .button {
    border: none;
    border-radius: ($gap * 6) !important;
    margin: 0px;
    width: 100%;
    height: 34px;
    &:not(.is-info) {
      background-color: transparent;
      color: transparentize($info, 0.4);
      font-weight: normal;
    }
    &:last-child,
    &:first-child {
      border-radius: ($gap * 6) !important;
    }
  }
}
</style>
