<template>
  <div v-if="product" class="fb-product-widget container is-fluid">
    <div v-if="isText" class="fb-product-widget-text">
      <div class="title">{{ title || product.title }}</div>
      <div class="subtitle">{{ subtitle || recurring }}</div>
    </div>
    <div v-else class="columns is-mobile">
      <div class="column">
        <fb-product-widget-icon :icon="icon" />
      </div>

      <div class="column is-two-thirds">
        <div class="fb-product-widget-title">
          <div class="title is-spaced">{{ title || product.title }}</div>
          <div v-if="taggable" class="fb-product-widget-tags">
            <b-tag
              v-for="(tag, index) in product.tag_list"
              :key="index.toString()"
              type="is-warning"
              rounded
              >{{ tag }}</b-tag
            >
          </div>
          <div v-if="isRecurringVisible" class="subtitle">
            {{ subtitle || recurring }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCardMixins from '~/utils/mixins/CourseCardMixins'
export default {
  mixins: [CourseCardMixins],
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    taggable: {
      type: Boolean,
      default: false
    },
    isRecurringVisible: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'text'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss">
.fb-product-widget {
  .columns {
    align-items: center;
    box-sizing: border-box;
  }
  &-icon {
    position: relative;
    @include icon-ronded-shadow(56px, 56px, $primary);
    width: 100%;
    margin: auto;
    .icon {
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      width: ($gap * 5);
      height: ($gap * 5);
      border-radius: 50%;
      background-color: $white;
      margin: auto;
      z-index: 2;
    }
  }
  &-title {
    .title,
    .subtitle {
      text-align: left !important;
    }
    .title {
      @include title-type-1;
      white-space: pre-wrap;
    }
    .subtitle {
      text-transform: uppercase;
      font-size: 12px;
      padding: ($gap / 2) 0px;
    }
  }

  &-tags {
    padding: $gap 0px;
    .tag {
      background-color: $tag-bg-color !important;
      color: $tag-text-color !important;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.25rem;
      margin-right: $gap * 0.5;
    }
  }
  &-text {
    .title,
    .subtitle {
      font-size: 20px !important;
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
