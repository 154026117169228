import dayjs from 'dayjs'

import 'dayjs/locale/pt-br'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

dayjs.locale('pt-br')

dayjs.tz.setDefault('America/Fortaleza')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
