import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    xs: 768,
    sm: 769,
    md: 1024,
    lg: Infinity
  },
  defaultBreakpoint: 'sm'
})
