<template>
  <div :class="{ 'is-page': isPage }" class="fb-course-card-wrap">
    <div
      v-if="isPage"
      :class="[
        { 'is-mobile': mobile, 'is-desktop': !mobile },
        activePeriodClass
      ]"
      class="fb-course-card card is-page"
    >
      <LayoutHeading
        :period="selectedPeriodIndex"
        :product="product"
        :isPage="true"
      />
      <div class="card-content">
        <div class="content">
          <LayoutPage
            v-if="isPage"
            v-model="selectedPeriodIndex"
            :product="product"
          />
          <LayoutProduct
            v-else
            v-model="selectedPeriodIndex"
            :product="product"
          />
        </div>
      </div>
    </div>
    <Collapse v-else-if="mobile" :product="product">
      <div :class="activePeriodClass" class="fb-course-card card">
        <nuxt-link
          :title="product.title"
          :to="`/curso/${product.slug}`"
          class="fb-course-card--link"
        ></nuxt-link>

        <LayoutHeading :period="selectedPeriodIndex" :product="product" />
        <div class="card-content">
          <div class="content">
            <LayoutPage
              v-if="isPage"
              :period="selectedPeriodIndex"
              :product="product"
            />
            <LayoutProduct
              v-else
              v-model="selectedPeriodIndex"
              :product="product"
            />
          </div>
        </div>
      </div>
    </Collapse>
    <div
      :class="[
        { 'is-mobile': mobile, 'is-desktop': !mobile },
        activePeriodClass
      ]"
      v-else
      class="fb-course-card card"
    >
      <nuxt-link
        :title="product.title"
        :to="`/curso/${product.slug}`"
        class="fb-course-card--link"
      ></nuxt-link>

      <LayoutHeading :period="selectedPeriodIndex" :product="product" />
      <div class="card-content">
        <div class="content">
          <LayoutPage
            v-if="isPage"
            v-model="selectedPeriodIndex"
            :product="product"
          />
          <LayoutProduct
            v-else
            v-model="selectedPeriodIndex"
            :product="product"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import findIndex from 'lodash/findIndex'

import LayoutPage from './CardLayoutPage.vue'
import LayoutProduct from './CardLayoutProduct.vue'
import LayoutHeading from './CardLayoutHeading.vue'

import Collapse from './Collapse.vue'

export default {
  components: {
    LayoutHeading,
    LayoutProduct,
    LayoutPage,
    Collapse
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    isPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedPeriodIndex: 0
    }
  },
  computed: {
    activePeriodClass() {
      switch (this.selectedPeriodIndex) {
        case 0:
          return 'is-monthly'
        case 1:
          return 'is-semiannually'
        case 2:
          return 'is-annually'
        default:
          return null
      }
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    }
  },
  mounted() {
    this.selectedPeriodIndex = findIndex(this.product.plans, {
      interval_count: parseInt(this.product.default_plan_interval || 6)
    })
  }
}
</script>

<style lang="scss">
.fb-course-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ($gap * 50);
  height: 100%;
  box-shadow: $card-box-shadow;
  margin: auto;
  transition: box-shadow 0.3s;

  &-wrap:not(.is-page) {
    height: 100%;
  }

  .is-product-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &:hover {
    box-shadow: $card-box-shadow-hover;
  }

  &.is-skeleton {
    max-width: 312px !important;
    .card-content {
      min-height: 312px;
      margin: auto;
    }
  }

  &.is-page {
    width: 100%;
    max-width: none;
    .card-heading {
      background: #129b84;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .fb-course-card--icon-wrap {
        position: relative;
        height: 72px;
        width: 118px;
        margin-left: ($gap * -2);
        margin-right: ($gap * 2);
        .fb-course-card--icon {
          top: 0;
        }
      }
    }
    .fb-course-card--price {
      .price {
        font-size: 42px;
      }
    }
  }
  .card-heading {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    min-height: ($gap * 28);
    background: url('~assets/images/plan-card-heading.png') no-repeat $secondary;
    background-size: cover;
    border-top-left-radius: $card-radius;
    border-top-right-radius: $card-radius;
    padding-bottom: ($gap * 2);
    overflow: hidden;
    .title {
      font-size: 20px;
      color: $primary-invert;
      text-align: center;
      line-height: 1.25;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .card-content {
    flex: 1;
    width: 100%;
    max-width: ($gap * 50);
    margin: 0px auto;
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .fb-course-card-btn-radio {
        width: 100%;
      }
    }
    &--buttons {
      .button {
        position: relative;
        margin: $gap auto;
        font-size: 1rem;
        z-index: 3;
        .icon {
          margin-right: 0.75rem !important;
        }
      }
    }
    &--actions {
      padding-bottom: $gap * 3;
      .subtitle {
        font-size: 1rem;
        text-align: center;
        padding: ($gap * 2) ($gap * 3);
      }
      .button {
        font-size: 12px;
        .icon > i::before {
          font-size: 18px;
        }
      }
    }
  }
  &--icon {
    @include icon-ronded-shadow;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: ($gap * 6);
    left: 0;
    right: 0;
    height: 74px;
    margin: auto;
    border-radius: 50%;
    [class*='icon-'] {
      &::before {
        font-size: ($gap * 5) !important;
      }
    }
  }

  &--price {
    text-align: center;
    box-sizing: border-box;
    margin-bottom: $gap * 2 !important;
    .price {
      font-size: 57px;
      font-weight: bold;
      color: #09456f;
    }
    .currency,
    .cents {
      font-size: 17px;
      color: #09456f;
      margin-left: ($gap * -1);
    }
    .total_price {
      display: block;
      color: $grey-light;
      margin: 0px auto $gap;
      font-size: 14px;
      font-weight: normal;
      text-transform: none;
      margin-top: $gap;
      b {
        font-weight: bold;
      }
    }
    .content-price {
      padding: 0 $gap * 2;
    }
    .discount--icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-discount {
      width: 22px;
      height: 21px;
      background: transparent url('~assets/images/discount.svg') 0% 0% no-repeat
        padding-box;
      opacity: 1;
    }
    .section--divider {
      content: ' ';
      border-top: 2px solid #129b84;
      max-width: 55px;
      margin: 8px auto 8px;
    }
    .discount--price {
      .discount-heading {
        font-size: 10px;
        font-weight: bold;
      }
      .discount-value {
        font-size: 18px;
        font-weight: 800;
        text-align: center;
      }
    }
  }
  &--discount {
    position: absolute;
    top: 0;
    right: ($gap * 2);
    padding: $gap;
    padding-top: ($gap * 2);
    padding-bottom: ($gap / 2);
    box-shadow: 0px 3px 4px #86791e29;
    .discount-heading {
      font-size: 0.8em;
      font-weight: bold;
    }
    .discount-value {
      margin-top: ($gap * -1/2);
      font-size: 1.6em;
      font-weight: 800;
      text-align: center;
    }
  }

  &--description {
    flex: 1;
    padding: ($gap);
    list-style: none;
    li {
      list-style: none;
      margin: $gap 0px;
    }
    li::before {
      content: '\2022';
      color: $secondary;
      font-weight: bold;
      display: inline-block;
      width: 1rem;
      margin-left: -1rem;
    }
  }
  &--link {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .divider {
    @include divider;
  }
  &.is-desktop {
    &.is-page {
      width: 100%;
      max-width: 100%;
      .card-heading {
        min-height: 180px;
      }
      .card-content {
        padding-top: $gap * 5;
        padding-bottom: $gap * 5;
      }
      .is-desktop-border-left {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        border-left: 1px solid $grey-lighter;
        width: 1px;
        height: 100%;
      }
      .column.is-border-left {
        border-left: 1px solid $grey-lighter;
      }
    }
    width: 100%;
    max-width: 312px;
    border-radius: $card-radius;
    overflow: hidden;
    .card-content {
      max-width: none;
      margin: 0px;
      &--actions {
        margin-top: 0px;
        padding: 0px ($gap * 2);
      }
    }
  }
  &.is-mobile {
    width: calc(100% - #{$gap * 3});
  }
}
</style>
