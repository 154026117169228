import Vue from 'vue'

import {
  Skeleton,
  Button,
  Field,
  Input,
  Upload,
  Checkbox,
  Radio,
  Navbar,
  Modal,
  Menu,
  Loading,
  Dropdown,
  Icon,
  Tabs,
  Tag,
  Toast,
  Collapse,
  Pagination,
  Notification,
  Select,
  Snackbar
} from 'buefy'

Vue.use(Button)
Vue.use(Skeleton)
Vue.use(Field)
Vue.use(Input)
Vue.use(Upload)
Vue.use(Checkbox)
Vue.use(Collapse)
Vue.use(Radio)
Vue.use(Dropdown)
Vue.use(Navbar)
Vue.use(Modal)
Vue.use(Menu)
Vue.use(Loading)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(Tag)
Vue.use(Pagination)
Vue.use(Notification)
Vue.use(Select)
Vue.use(Toast)
Vue.use(Snackbar)
