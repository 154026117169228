<template>
  <div class="form-login is-mobile">
    <div v-if="active || skipAsk" class="container is-fluid lite-container">
      <form @submit.prevent="login" method="post" novalidate>
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field
              :message="errors.first('email')"
              :type="{ 'is-danger': errors.has('email') }"
              label="Email"
            >
              <b-input
                v-model="email"
                v-validate="'required'"
                placeholder="seu email"
                type="email"
                name="email"
                autocomplete="off"
                autofocus="true"
                size="is-large"
                icon-pack="custom"
                icon="icon-usuario"
                rounded
              ></b-input>
            </b-field>
          </div>
          <div class="column is-12">
            <b-field
              :message="errors.first('password')"
              :type="{ 'is-danger': errors.has('password') }"
              label="Password"
            >
              <b-input
                v-model="password"
                v-validate="'required'"
                placeholder="******"
                type="password"
                name="password"
                size="is-large"
                icon-pack="custom"
                icon="icon-senha"
                rounded
              ></b-input>
            </b-field>
          </div>
        </div>

        <b-notification
          :active.sync="isVisibleNotificationMessage"
          @close="notificationMessageClose"
          type="is-danger"
          has-icon
          role="alert"
        >
          <span>{{ messages }}</span>
        </b-notification>

        <recaptcha />

        <div class="control">
          <b-button
            :disabled="disabled || invalid"
            :loading="disabled"
            class="is-fullwidth"
            type="is-info"
            size="is-large"
            native-type="submit"
            rounded
          >
            Entrar
          </b-button>
        </div>
      </form>
      <div class="has-text-centered">
        <p>
          <nuxt-link to="/esqueci-a-senha">Esqueceu a senha?</nuxt-link>
        </p>
      </div>

      <div class="has-text-centered">
        <p>
          Não tem uma conta?
          <nuxt-link to="/cadastro">Cadastre-se</nuxt-link>
        </p>
      </div>
    </div>

    <div v-else class="container is-fluid lite-container">
      <div class="title">
        PARA CONTINUAR, <br />
        ACESSE SUA CONTA!
      </div>

      <b-button
        @click="active = true"
        type="is-info"
        expanded
        rounded
        outlined
        size="is-large"
      >
        Já sou Aluno
      </b-button>

      <b-button
        tag="nuxt-link"
        to="/cadastro"
        type="is-info"
        expanded
        rounded
        outlined
        size="is-large"
      >
        Não tenho uma conta
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    skipAsk: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      email: '',
      password: '',
      messages: '',
      error: null,
      isVisibleNotificationMessage: false,
      notificationMessage: null,
      active: false
    }
  },
  computed: {
    invalid() {
      return !this.email || !this.password
    }
  },
  methods: {
    async login() {
      this.$emit('disabled', true)
      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
      } catch (error) {}

      this.messages = ''

      const result = await this.$validator.validateAll()

      if (result) {
        this.$emit('login', this)
      }
    },
    notificationMessageClose() {
      this.isVisibleNotificationMessage = false
    }
  }
}
</script>

<style></style>
