<template>
  <div
    :class="{ 'card--shadow': mobile, 'card--no-shadow': !mobile }"
    class="fb-checkout-tshirt-form card"
  >
    <div class="card-header">
      <div class="card-header-title pt-2">Bônus</div>
    </div>
    <div class="card-content">
      <div class="container is-fluid">
        <div class="columns mb-2">
          <div class="column is-3">
            <figure v-if="includeSamePlan">
              <img
                src="~/assets/images/checkout/tshirt/superita-supermed.png"
              />
            </figure>
            <figure v-else-if="includeSupermed">
              <img src="~/assets/images/checkout/tshirt/supermed.png" />
            </figure>
            <figure v-else-if="includeSuperIta">
              <img src="~/assets/images/checkout/tshirt/superita.png" />
            </figure>
          </div>
          <div class="column is-9">
            <div class="mb-1">
              Ao assinar o SuperMED ou Super ITA/IME, você ganha uma camisa
              exclusiva.
            </div>
            <div class="has-text-weight-bold mb-1">
              Selecione o seu tamanho:
            </div>
            <div class="fields is-flex is-align-items-center">
              <div v-for="s in sizes" :key="s">
                <div class="text-center">
                  <label>
                    <div>{{ s }}</div>
                    <input
                      v-model="size"
                      :value="s"
                      type="radio"
                      name="tshirt-size"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TshirtForm',
  data() {
    return {
      size: null
    }
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    currentUser() {
      return this.$store.getters.currentStudent
    },
    sizes() {
      return ['P', 'M', 'G', 'GG', 'XG']
    },
    includeSupermed() {
      return this.cart.items.some((i) => i.plan.slug.includes('supermed'))
    },
    includeSuperIta() {
      return this.cart.items.some((i) => i.plan.slug.includes('superita'))
    },
    includeSamePlan() {
      return this.includeSupermed && this.includeSuperIta
    },
    ...mapState({
      cart: ({ cart }) => cart.data
    })
  },
  watch: {
    size(newValue) {
      if (newValue) {
        this.$store.dispatch('auth/setRemoteProfile', {
          preferences: { shirt_size: newValue }
        })
      }
    }
  },
  mounted() {
    this.size = this.currentUser?.preferences?.shirt_size
  }
}
</script>

<style lang="scss">
.fb-checkout-tshirt-form {
  .fields {
    gap: 1.5rem;
  }
}
</style>
