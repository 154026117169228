<script>
import { mapActions } from 'vuex'

import {
  createSEO,
  createSEODescription,
  createSEOImage,
  mergeMetas
} from '@/utils/seo'

import { getStrapiMedia } from '@/utils/media'

export default {
  layout: 'blog',
  head() {
    const head = createSEO()
    const SEOImage = createSEOImage()
    const SEODescription = createSEODescription()

    // merge array with default SEO information
    head.meta = mergeMetas(head.meta, SEOImage, SEODescription)

    return head
  },
  methods: {
    getMedia(url) {
      return getStrapiMedia(url)
    },
    articleImageUrl(article) {
      return getStrapiMedia(article.image?.url || '')
    },
    articleSmallImageUrl(article) {
      return getStrapiMedia(article.image?.formats?.small?.url || '')
    },
    articleMediumImageUrl(article) {
      return getStrapiMedia(article.image?.formats?.medium?.url || '')
    },
    blogPath(...params) {
      const blogBaseURL = '/blog/'
      if (Array.isArray(params)) {
        return blogBaseURL + params.join('/')
      }

      return blogBaseURL
    },
    publishedAt(article) {
      if (this.$dayjs) {
        return this.$dayjs(article.publishedAt).format('LL')
      }

      return null
    },
    __category(article) {
      if (article.category) {
        return article.category
      }

      return article.subcategory?.category
    },
    ...mapActions({
      setBlogBreadcrumb: 'blog/setBreadcrumb'
    })
  }
}
</script>
