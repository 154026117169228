<template>
  <div :class="{ 'is-mobile': mobile }" class="fb-checkout-success-creditcard">
    <div class="fb-checkout-success-creditcard-icon mb-3">
      <b-icon
        icon="icon-confirmacao"
        pack="custom"
        size="is-large"
        type="is-primary"
      />
    </div>
    <p class="title has-text-centered mb-3">
      A sua compra foi <br />
      efetuada com sucesso
    </p>
    <p class="title has-text-centered mb-4">Bons estudos!</p>
    <div class="control">
      <b-button
        :href="redirectUrl"
        tag="a"
        class="is-fullwidth"
        type="is-info"
        size="is-large"
        native-type="submit"
        rounded
      >
        Meus cursos
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    redirectUrl() {
      return process.env.baseAppUrl
    }
  }
}
</script>

<style lang="scss">
.fb-checkout-success-creditcard {
  &-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 118px;
    height: 118px;
    margin: auto;
    border-radius: 50%;
    &::after,
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      content: ' ';
      border-radius: 50%;
    }
    &::before {
      width: 74px;
      height: 74px;
      top: 0;
      bottom: 0;
      background-color: white;
      z-index: 2;
    }
    &::after {
      width: 118px;
      height: 118px;
      background-color: transparentize($color: $primary, $amount: 0.7);
      z-index: 1;
    }
    .icon {
      position: relative;
      z-index: 3;
    }
  }
  .title {
    font-size: 14px !important;
  }
  &.is-mobile {
    padding: ($gap * 4) ($gap * 2) ($gap * 10);
  }
}
</style>
