<template>
  <div>
    <b-navbar
      type="is-primary"
      fixed-top
      wrapper-class="container"
      class="fb-site-navbar is-blog"
      shadow
    >
      <template slot="brand">
        <b-navbar-item
          @click="pressMenu"
          class="is-hidden-widescreen navbar-item--burger"
        >
          <b-icon
            icon="icon-menu"
            pack="custom"
            size="is-normal"
            type="is-white"
          ></b-icon>
        </b-navbar-item>

        <b-navbar-item
          :to="{ path: '/' }"
          class="navbar-brand--fb-logo"
          tag="nuxt-link"
        >
          <img
            src="~/assets/images/logo-white-border.png"
            alt="FB Online"
            width="100px"
          />
        </b-navbar-item>

        <!-- DECREAPTED -->
        <!-- <b-navbar-item
          @click.stop="toggleMobileSearchBar"
          class="is-hidden-desktop navbar-item--last"
        >
          <b-icon icon="magnify" type="is-white" size="is-normal"></b-icon>
        </b-navbar-item> -->

        <!--
          <b-navbar-item
            :to="{ path: '/carrinho' }"
            tag="nuxt-link"
            class="is-hidden-desktop navbar-item--last"
          >
          <b-icon
            icon="icon-carrinho"
            type="is-white"
            pack="custom"
            size="is-normal"
          >
          </b-icon>
          <div v-if="totalItems" class="cart-bagde">
            {{ totalItems }}
          </div>
        </b-navbar-item>
        -->
      </template>

      <template slot="start">
        <b-navbar-item to="/" tag="nuxt-link">
          Início
        </b-navbar-item>

        <b-navbar-item to="/professores" tag="nuxt-link">
          Professores
        </b-navbar-item>

        <b-navbar-item to="/suporte" tag="nuxt-link">
          Suporte
        </b-navbar-item>

        <!-- <b-navbar-item to="/blog" tag="nuxt-link">
          Blog
        </b-navbar-item> -->
        <!--
          DECREAPTED
          <form @submit.prevent="search" class="navbar-item search-bar">
            <div class="buttons">
              <b-field>
                <b-input
                  v-model="query"
                  :rounded="true"
                  placeholder="O que você procura?"
                  icon="magnify"
                  icon-size="is-medium"
                >
                </b-input>
              </b-field>
            </div>
          </form>
        -->
      </template>
      <template slot="end">
        <b-navbar-item v-if="!isAuthenticated" tag="div">
          <div class="buttons">
            <b-button
              tag="a"
              href="/login"
              type="is-light"
              outlined
              rounded
              class="is-signin"
            >
              Entre
            </b-button>
            <b-button
              type="is-light"
              rounded
              class="is-signup"
              tag="a"
              href="/cadastro"
              >Inscreva-se</b-button
            >
          </div>
        </b-navbar-item>
        <b-navbar-item v-else class="fb-site-navbar-is-logged" tag="div">
          <div class="buttons">
            <div class="columns is-mobile is-vcentered">
              <div class="column">
                <picture class="image is-42x42">
                  <img :src="currentStudent.avatar" class="is-rounded" />
                </picture>
              </div>
              <div class="column is-10">
                <div class="fb-site-navbar-profile-name">
                  {{ currentStudent.name }}
                </div>
                <b-button
                  @click.stop="logout"
                  type="is-text"
                  size="is-small"
                  class="fb-site-navbar-logout-btn"
                  >Sair</b-button
                >
              </div>
            </div>
          </div>
        </b-navbar-item>

        <b-navbar-item
          @click.stop="toggleMobileSearchBar"
          class="is-hidden-widescreen mr-1"
        >
          <b-icon icon="magnify" type="is-white" size="is-normal"></b-icon>
        </b-navbar-item>
      </template>
    </b-navbar>
    <!--
      DECREAPTED
      <transition name="slide">
        <div
          v-if="isSearchBarMobileVisible"
          class="fb-site-navbar-mobile-blog-search"
        >
          <form @submit.prevent="search" class="is-block">
            <b-field>
              <b-input
                ref="search"
                v-model="query"
                placeholder="O que você procura?"
                icon="magnify"
              >
              </b-input>
            </b-field>
          </form>
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="isSearchBarMobileVisible"
          @click.stop="toggleMobileSearchBar"
          class="fb-site-navbar-mobile-blog-search-backdrop"
        ></div>
      </transition>
    -->
  </div>
</template>

<script>
import Navbar from '@/components/site/Navbar'
export default {
  extends: Navbar,
  data() {
    return {
      query: '',
      isSearchBarMobileVisible: false
    }
  },
  methods: {
    search() {
      this.$router.push(`/blog/pesquisa?q=${this.query}`)
      // if mobile hide search
      this.query = ''
      this.isSearchBarMobileVisible = false
    },
    toggleMobileSearchBar() {
      this.isSearchBarMobileVisible = !this.isSearchBarMobileVisible
      this.$nextTick(() => {
        if (this.isSearchBarMobileVisible) {
          this.$refs.search.focus()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.fb-site-navbar.is-blog {
  .navbar-start {
    .buttons {
      width: 80%;
      display: block;
      margin: auto;
    }
  }
  .navbar-item {
    &.search-bar {
      width: 100%;
      input,
      input::placeholder {
        font-size: 1.25rem;
      }
      input::placeholder {
        color: $grey-light;
      }
      .field {
        margin-bottom: 0px !important;
      }
      .icon {
        .mdi::before,
        ::before {
          font-size: 2rem !important;
        }
      }
    }
  }
}
.fb-site-navbar-mobile-blog-search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .field {
    .control {
      &.has-icons-left .icon.is-left {
        right: $gap * 2;
        .mdi::before {
          font-size: 2rem !important;
        }
      }
      input {
        height: $navbar-height;
        border-radius: 0px;
        border-color: transparent;
      }
    }
  }
  &-backdrop {
    background-color: transparentize($color: $grey-darker, $amount: 0.1);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
  }
}
</style>
