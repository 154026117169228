<template>
  <ul
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="fb-checkout-progress is-associate"
  >
    <li
      v-for="(step, index) in filteredSteps"
      :key="step.icon"
      :class="[
        { 'is-actived': index <= progress },
        { 'is-last': index === progress }
      ]"
    >
      <div class="fb-checkout-progress-icon">
        <b-icon :icon="step.icon" pack="custom" />
        <div v-show="hasLabel" class="fb-checkout-progress-label">
          {{ step.label }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  model: {
    prop: 'progress',
    event: 'change'
  },
  props: {
    hasLabel: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      steps: [
        { icon: 'icon-checklist', label: 'Inserir Ticket' },
        { icon: 'icon-usuario', label: 'Criar Conta' },
        { icon: 'icon-confirmacao', label: 'Confirmação' }
      ]
    }
  },
  computed: {
    filteredSteps() {
      return this.steps?.filter((s) => {
        if (s.icon === 'icon-usuario' && this.isAuthenticated) {
          return false
        }
        return true
      })
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    })
  }
}
</script>

<style lang="scss"></style>
