<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    product: null
  },
  computed: {
    icon() {
      return this.product.icon_slug
    },
    plan() {
      if (this.product.plans) {
        return this.product.plans[0]
      }
      return this.product
    },
    price() {
      return this.plan.price
    },
    // TODO: rename
    recurring() {
      if (this.plan.is_recurring) {
        if (this.cart.coupon === 'BLACKFB') {
          return 'somente no 1º mês'
        }
        return 'todo mês'
      }
      return `em até ${this.price.installments.length}x`
    },
    planIntervalInfo() {
      if (this.plan.type === 'closed_period') {
        return 'Turma fechada'
      }
      switch (this.plan.interval_count) {
        case 1:
          return 'Mensal'
        case 6:
          return 'Semestral'
        case 12:
          return 'Anual'
        default:
          return ''
      }
    },
    isText() {
      return this.type === 'text'
    },
    ...mapGetters({
      cart: 'cart'
    })
  },
  methods: {
    addPlan() {
      this.$addPlan(this.getPeriod)
    },
    buyNow() {
      this.$addPlan({ buyNow: true, ...this.getPeriod })
    },
    ...mapActions({
      $addPlan: 'cart/addPlan'
    })
  }
}
</script>
