<template>
  <div class="card fb-cart-product-added-success">
    <header class="card-header">
      <div class="card-header-title"></div>
      <b-button
        @click="closeCartModal"
        type="is-text"
        size="is-large"
        icon-right="icon-fechar"
        icon-pack="custom"
      />
    </header>
    <div class="card-content">
      <b-icon
        icon="icon-add-carrinho"
        pack="custom"
        size="is-large"
        type="is-primary"
      />
      <div class="title">Parabéns!</div>
      <div class="subtitle">
        Você adicionou o item abaixo <br />
        ao seu carrinho.
      </div>
      <div class="fb-cart-product-added-success-course-wrap">
        <fb-product-widget
          :product="product"
          :subtitle="title"
          :type="isFullCourse ? 'text' : 'default'"
          title="Plano"
        ></fb-product-widget>
        <div v-if="isFullCourse" class="fb-course-card-period-select-wrap">
          <fb-course-card-period-select
            v-model="selectedPeriodIndex"
            :course="course"
            :periods="course.plans"
            :can-switch-plan="true"
            class="mt-1 mb-1"
          ></fb-course-card-period-select>
          <div class="fb-course-card-period-select-wrap-price">
            R$
            <span class="price-integer">{{ perMonth.integer_part }}</span
            >,<span class="price-decimal">{{ perMonth.decimal_part }}</span>
            /mês
          </div>
          <div v-if="isRecurring" class="price-final">
            Recorrente
          </div>
          <div v-else class="price-final">
            Total: {{ finalPrice | currency }}
          </div>
        </div>
      </div>
      <div class="fb-cart-product-added-success-buttons">
        <b-button
          @click="toCart"
          type="is-info"
          expanded
          rounded
          outlined
          size="is-large"
          icon-left="icon-add-carrinho"
          icon-pack="custom"
          >Ir para Carrinho</b-button
        >
        <b-button
          @click="closeCartModal"
          type="is-info"
          rounded
          expanded
          size="is-large"
          >Continuar Comprando</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      selectedPeriodIndex: 1
    }
  },
  computed: {
    title() {
      return this.product.product.title
    },
    subtitle() {
      return this.product.title
    },
    course() {
      return this.$store.getters['product/getCourse'](this.product.product.slug)
    },
    isFullCourse() {
      return this.course && this.course.type === 'full_course'
    },
    isRecurring() {
      return this.course.plans[this.selectedPeriodIndex].is_recurring
    },
    finalPrice() {
      return this.course.plans[this.selectedPeriodIndex].price.final_price
    },
    perMonth() {
      const plan = this.course.plans[this.selectedPeriodIndex]
      return plan.price.per_month
    },
    ...mapState({
      courses: ({ product }) => product.courses,
      product: ({ cart }) => cart.newCartItem,
      isCartModalVisible: ({ cart }) => cart.isCartModalVisible
    })
  },
  mounted() {
    if (this.isFullCourse) {
      this.selectedPeriodIndex = this.course.plans.findIndex(
        (p) => p.slug === this.product.slug
      )
    }
  },
  methods: {
    toCart() {
      this.closeCartModal()
      this.$router.push('/carrinho')
    },
    ...mapActions('cart', ['closeCartModal'])
  }
}
</script>

<style lang="scss">
.fb-cart-product-added-success {
  max-width: 85%;
  width: $product-added-success-wide-width;
  margin: auto;
  .card-header {
    padding: ($gap * 2);
    box-shadow: none;
    .icon .custom::before {
      color: $black;
      font-size: 1.25rem;
    }
  }
  .card-content {
    text-align: center;
    > .title {
      font-size: 20px;
      text-transform: uppercase;
      @include opensans-extrabold;
    }
    .subtitle {
      font-size: 14px;
    }
    > .icon {
      margin-bottom: ($gap * 4);
      .custom::before {
        font-size: 4rem;
      }
    }
  }
  &-course-wrap {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: ($gap * 2) 1.5rem;
    border-top: 1px solid transparentize($primary, 0.8);
    border-bottom: 1px solid transparentize($primary, 0.8);
  }
  &-buttons {
    padding: ($gap * 2) 0px 0px;
    .button {
      &:last-child {
        margin-bottom: $gap;
      }
      margin-top: $gap * 2;
      font-size: 12px;
      .icon {
        padding-right: ($gap * 4) !important;
      }
    }
  }
}

.fb-course-card-period-select-wrap {
  &-price {
    font-size: 16px;
    color: $info;
    .price-integer {
      font-size: 24px;
      font-weight: 800;
    }
  }
  .price-final {
    font-size: 11px;
  }
}

// Notebooks and Widescreens
@media screen and (min-width: $desktop) {
  .fb-cart-product-added-success {
    max-width: 100%;
  }
}
</style>
