<template>
  <div class="detached-courses">
    <div v-if="hasTags" class="filters">
      <div v-if="!mobile" class="filters--container card">
        <span class="filters--title">Filtrar:</span>
        <ul class="tag-list">
          <li
            v-for="tag in tags"
            :key="tag.title"
            :class="[tag.selected ? 'selected' : '']"
            @click="toggleSelected(tag)"
            class="tag"
          >
            {{ tag.title }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="selectedTag === 'todos'" class="container">
      <ul class="fb-items-by-discipline">
        <li>
          <b-collapse aria-controls="Combo">
            <div
              slot="trigger"
              slot-scope="props"
              v-if="mobile"
              aria-controls="Combo"
              role="button"
              class="course-trigger"
            >
              Combos
              <span class="course-trigger-icon">
                <b-icon
                  :icon="props.open ? 'icon-menos' : 'icon-mais'"
                  pack="custom"
                  size="is-normal"
                  type="is-white"
                />
              </span>
            </div>
            <div v-else class="course-name">
              <div class="course-name-text">
                Combos
              </div>
              <div class="course-name-line"></div>
            </div>
            <ul>
              <li
                v-for="(product, index) in getDetachedCombos"
                :key="index.toString()"
              >
                <fb-detached-course-card
                  :product="product"
                ></fb-detached-course-card>
              </li>
            </ul>
          </b-collapse>
        </li>
      </ul>
      <ul class="fb-items-by-discipline">
        <li
          v-for="(course, courseIndex) in disciplines"
          :key="courseIndex.toString()"
        >
          <b-collapse
            :aria-controls="course.name"
            :open.sync="course.collapsed"
            :class="{ 'is-collapsed': course.collapsed }"
          >
            <div
              slot="trigger"
              v-if="mobile"
              slot-scope="props"
              :aria-controls="course.name"
              role="button"
              class="course-trigger"
            >
              {{ course.name }}
              <span class="course-trigger-icon">
                <b-icon
                  :icon="props.open ? 'icon-menos' : 'icon-mais'"
                  pack="custom"
                  size="is-normal"
                  type="is-white"
                />
              </span>
            </div>
            <div v-else class="course-name">
              <div class="course-name-text">
                {{ course.name }}
              </div>
              <div class="course-name-line"></div>
            </div>
            <ul>
              <li
                v-for="(product, index) in getDetachedCoursesByDiscipline(
                  course.slug
                )"
                :key="index.toString()"
              >
                <fb-detached-course-card
                  :product="product"
                ></fb-detached-course-card>
              </li>
            </ul>
          </b-collapse>
        </li>
      </ul>
    </div>
    <ul v-else class="fb-items-by-discipline">
      <li>
        <div class="collapse-content">
          <ul class="center-content">
            <li
              v-for="(product, index) in getFilteredCourses()"
              :key="index.toString()"
              class="collapse-content"
            >
              <fb-detached-course-card
                :product="product"
              ></fb-detached-course-card>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'

export default {
  props: {
    detachedCourses: {
      type: Array
    },
    defaultTag: {
      type: String
    },
    hasTags: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      collapseIndex: 0,
      disciplines: [],
      tags: []
    }
  },
  computed: {
    getDetachedCombos() {
      if (this.detachedCourses) {
        return this.detachedCourses.filter(({ disciplines }) => {
          return disciplines.length > 1
        })
      }
      return []
    },
    selectedTag() {
      const tag = this.tags.find(({ selected }) => selected)
      return tag ? tag.title : 'todos'
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    }
  },
  created() {
    this.mountDisciplines()
  },
  mounted() {
    const featureTag = this.tags.find((tag) => tag.title === this.defaultTag)
    featureTag && this.toggleSelected(featureTag)
  },
  methods: {
    mountDisciplines() {
      const disciplines = this.detachedCourses.map((course) => {
        return course.disciplines.map(({ slug, name }) => ({
          slug,
          name,
          collapsed: true
        }))
      })

      this.disciplines = uniqBy(flatten(disciplines), 'slug')
      this.tags = this.getTagList()
    },
    collapseToggle(course) {
      course.collapsed = !course.collapsed
    },
    getDetachedCoursesByDiscipline(disciplineSlug) {
      if (this.detachedCourses) {
        return this.detachedCourses.filter(({ disciplines }) => {
          return (
            disciplines.map(({ slug }) => slug).includes(disciplineSlug) &&
            disciplines.length >= 1
          )
        })
      }
      return []
    },

    getFilteredCourses() {
      if (this.detachedCourses) {
        return this.detachedCourses.filter((c) =>
          c.tag_list.includes(this.selectedTag)
        )
      }
      return []
    },
    toggleSelected(tagSelected) {
      const tagList = this.tags
      const currentSelected = tagList.find(({ selected }) => selected)
      currentSelected.selected = false
      tagSelected.selected = true
    },
    getTagList() {
      let tagList = []
      const coursesTags = flatten(
        this.detachedCourses
          ? this.detachedCourses
              .map((c) => c.tag_list)
              .filter((t) => t.length > 0)
          : []
      )
      for (const tag of coursesTags) {
        if (!tagList.includes(tag)) tagList.push(tag)
      }
      tagList = tagList.map((t) => ({ title: t, selected: false }))
      tagList.unshift({ title: 'todos', selected: true })
      return tagList
    }
  }
}
</script>

<style lang="scss">
.detached-courses {
  .filters,
  .filters--container,
  .tag-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filters--container {
    padding: ($gap / 2) ($gap * 2);
    border-radius: ($card-radius * 2);
    border: 1px solid $grey-lighter;
    box-shadow: none;
    .tag {
      user-select: none;
      text-transform: uppercase;
      padding: ($gap * 2.5);
      border-radius: ($card-radius * 2);
      font-size: 1.2em;
      margin: 0 ($gap);
      background: transparent;
      cursor: pointer;
      &.selected {
        background: transparentize(#efb600, 0.8);
        color: #df9c00;
        font-weight: bold;
      }
    }
    .filters--title {
      margin: 0 ($gap * 2);
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  @include items-by-discipline;
}
</style>
