<template>
  <div>
    <SiteContainer></SiteContainer>
    <BlogNavbar></BlogNavbar>
    <BlogMenu />
    <ActiveCampaignWhatsappButton />
    <div class="divider"></div>
    <div class="fb-lead container is-blog">
      <nuxt />
    </div>
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
import BlogMenu from '@/components/blog/Menu'
import BlogNavbar from '@/components/site/Navbar'
import SiteContainer from '@/components/site/Container'
import SiteFooter from '@/components/site/Footer'
import ActiveCampaignWhatsappButton from '~/components/site/ActiveCampaignWhatsappButton'

export default {
  components: {
    BlogMenu,
    BlogNavbar,
    SiteFooter,
    SiteContainer,
    ActiveCampaignWhatsappButton
  },
  data() {
    return {
      isPresentLeading: true
    }
  },
  methods: {
    setIsPresentLeading({ isPresentLeading }) {
      this.isPresentLeading = isPresentLeading
    }
  }
}
</script>
<style></style>
