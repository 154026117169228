<template>
  <div :class="{ 'is-mobile': mobile }" class="p-3">
    <div class="fb-checkout-success-bankslip-icon mb-2">
      <b-icon
        icon="icon-boleto"
        pack="custom"
        size="is-large"
        type="is-primary"
      />
    </div>
    <p v-if="bankSlipPrintUrl" class="title has-text-centered mb-1">
      Boleto gerado com sucesso! <br />
    </p>
    <p v-else class="title has-text-centered mb-3">
      Link para o boleto não encontrado
    </p>

    <div v-if="bankSlipPrintUrl" class="hint has-text-centered mb-3">
      Obs.: Espere pelo menos 10 minutos para pagar o boleto.
    </div>

    <div v-if="bankSlipPrintUrl" class="mb-2">
      <b-field class="fb-checkout-success-bankslip-copy">
        <b-input
          id="bankslip"
          @focus="copyToClipboard"
          v-model="bankSlipPrintBarcode"
          rounded
          style="flex: 1;"
        />
        <p class="control">
          <b-tooltip
            :active.sync="copied"
            always
            label="Copiado!"
            type="is-dark"
          >
            <b-button
              @click="copyToClipboard"
              class="button is-info"
              icon-right="icon-boleto"
              icon-pack="custom"
            ></b-button>
          </b-tooltip>
        </p>
      </b-field>
      <div class="hint has-text-centered mt-1">
        Copie seu código!
      </div>
    </div>

    <div class="control mb-2">
      <b-button
        :href="bankSlipPrintUrl"
        :disabled="!bankSlipPrintUrl"
        tag="a"
        class="is-fullwidth"
        type="is-info"
        size="is-large"
        native-type="submit"
        rounded
      >
        Baixar Boleto
      </b-button>
    </div>
    <p class="subtile has-text-centered">
      Após efetuar o pagamento, os cursos escolhidos estarão liberados em até 3
      dias úteis.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      copied: false
    }
  },
  computed: {
    bankSlipPrintUrl() {
      return this.$route.query.print_url
    },
    bankSlipPrintBarcode() {
      return this.$route.query.barcode
    }
  },
  methods: {
    copyToClipboard() {
      const copyText = this.$el.querySelector('#bankslip')
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      document.execCommand('copy')
      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 2000)
    }
  }
}
</script>

<style lang="scss">
.fb-checkout-success-bankslip {
  &-icon {
    text-align: center;
    .icon {
      position: relative;
      z-index: 3;
      .custom::before {
        font-size: 6rem;
      }
    }
  }
  &-copy {
    .is-always {
      height: 100%;
    }
    .control .button {
      height: 100%;
      font-size: 2rem;
      padding-top: 0;
      padding-bottom: 0;
      border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      .icon .custom::before {
        font-size: 2rem;
      }
    }
  }
  .title {
    font-size: 14px !important;
  }
}
</style>
