import pick from 'lodash/pick'

const PROFILE_FIELDS = ['email', 'first_name', 'last_name', 'cpf', 'birthdate']

const RESPONSIBLE_FIELDS = [
  'responsible_name',
  'responsible_cpf',
  'responsible_birthdate',
  'responsible_terms_accepted',
  'responsible_terms_accepted_at'
]

export function mapIcon(p) {
  p.icon = p.icon_slug
  p.icon_slug = `icon-${p.icon_slug}`

  p.plans.forEach((plan) => {
    plan.icon_slug = p.icon_slug
  })

  return p
}

export function cloneObservable(observable) {
  if (!observable) {
    return null
  }
  try {
    return JSON.parse(JSON.stringify(observable, null, 2))
  } catch (error) {
    return {}
  }
}

export function mapProfile(profile) {
  profile.persisted = true
  profile.validated = validateProfile(profile)
  return profile
}

function validateProfile(profile) {
  const errors = {}

  // Caso menor de idade dados pessoais mais dados do responsável
  const mappedProfile = isStudentUnderAge(profile)
    ? pick(profile, [...PROFILE_FIELDS, ...RESPONSIBLE_FIELDS])
    : pick(profile, PROFILE_FIELDS)

  // Buscando por campos não preenchidos
  for (const [field, value] of Object.entries(mappedProfile)) {
    errors[field] = !value
  }

  return Object.values(errors).every((e) => !e) // verdade se todos os campos estiverem corretos
}

export function isStudentUnderAge(currentStudent) {
  const today = self.$nuxt.$dayjs()
  const bd = self.$nuxt.$dayjs(currentStudent.birthdate, 'DD/MM/YYYY')
  const age = today.diff(bd, 'years')
  return age < 18
}
