/* eslint-disable import/no-named-as-default-member */
import Vue from 'vue'
import VeeValidate from 'vee-validate/dist/vee-validate.minimal.esm'
import {
  required,
  email,
  min,
  max,
  numeric,
  confirmed
} from 'vee-validate/dist/rules.esm'
import trim from 'lodash/trim'
import pt_BR from 'vee-validate/dist/locale/pt_BR'

import { attributes } from '@/utils/strings/attributes/pt_BR'

function getDefaultErrorMessage(field) {
  return `${field} é inválido`
}

VeeValidate.Validator.localize('pt_BR', pt_BR)

VeeValidate.Validator.extend('required', required)

VeeValidate.Validator.extend('email', email)

VeeValidate.Validator.extend('min', min)

VeeValidate.Validator.extend('max', max)

VeeValidate.Validator.extend('numeric', numeric)

VeeValidate.Validator.extend('confirmed', confirmed)

// CEP Brasil
VeeValidate.Validator.extend('zipcode', {
  getMessage: getDefaultErrorMessage,
  validate: (value) => /[0-9]{5}-[\d]{3}/g.test(value)
})

VeeValidate.Validator.extend('only_letters', {
  getMessage: getDefaultErrorMessage,
  validate: (value) => !/[^a-zA-Z\u00C0-\u00FF\s]/g.test(value)
})

VeeValidate.Validator.extend('alphanumeric', {
  getMessage: getDefaultErrorMessage,
  validate: (value) => !/[^\w]/.test(value)
})

// CPF Brasil
VeeValidate.Validator.extend('cpf', {
  getMessage: getDefaultErrorMessage,
  validate: (value) => /[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/g.test(value)
})

VeeValidate.Validator.extend('phone', {
  getMessage: (field) => `${field} é obrigatório mínimo 10 dígitos`,
  validate: (value) => {
    return trim(value).length >= 10
  }
})

VeeValidate.Validator.extend('full_name', {
  getMessage: (field) =>
    `O campo ${field} está incompleto. Ex: João das Neves, João Amorim`,
  validate: (value) => {
    // caso substantivo próprio com partícula: Ex João das Neves
    if (/^[A-Z]([a-zA-Z\u00C0-\u00FF]{1,}\s).+([A-Z].+)/.test(value)) {
      return true
    }

    // regra padrão para substantivo prórprio
    return /^[A-Z]([a-zA-Z\u00C0-\u00FF]{1,}\s)([A-Z][a-zA-Z\u00C0-\u00FF])/.test(
      value
    )
  }
})

VeeValidate.Validator.extend('first_name', {
  getMessage: (field) => `${field} é obrigatório mínimo 3 caracteres`,
  validate: (value) => {
    return value.length >= 3
  }
})

VeeValidate.Validator.extend('last_name', {
  getMessage: (field) => `${field} é obrigatório mínimo 3 caracteres`,
  validate: (value) => {
    return value.length >= 3
  }
})

VeeValidate.Validator.extend('password', {
  getMessage: (field) => `${field} é obrigatório mínimo 6 dígitos`,
  validate: (value) => {
    return trim(value).length >= 6
  }
})

Vue.use(VeeValidate, {
  locale: 'pt_BR',
  dictionary: {
    pt_BR: { attributes }
  }
})
