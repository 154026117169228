<template>
  <div>
    <client-only>
      <fb-site-container />
    </client-only>
    <client-only>
      <fb-site-navbar />
    </client-only>
    <nuxt />
    <client-only>
      <fb-site-footer />
    </client-only>
    <client-only>
      <fb-site-interventional-modal />
      <b-modal
        :active.sync="isCartModalVisible"
        :on-cancel="closeCartModal"
        :can-cancel="false"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <fb-cart-product-added-success />
      </b-modal>
    </client-only>
  </div>
</template>

<script>
import AOS from 'aos'
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    ...mapState('cart', ['isCartModalVisible'])
  },
  mounted() {
    AOS.init()
  },
  methods: {
    ...mapActions('cart', ['closeCartModal'])
  }
}
</script>
<style></style>
