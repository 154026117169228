export function isChrome() {
  const chromeAgent = navigator.userAgent.includes('Chrome')
  return chromeAgent
}

export function isSafari() {
  // Detect Safari
  const safariAgent = navigator.userAgent.includes('Safari')

  // Discard Safari since it also matches Chrome
  if (isChrome() && safariAgent) {
    return false
  }
  return safariAgent
}

export default {
  isChrome,
  isSafari
}
