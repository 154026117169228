import { set } from 'vue'
import maxBy from 'lodash/maxBy'
import find from 'lodash/find'
import { addPlan, currentCart, removePlan } from '~/services/cart'
import { trackEvent } from '~/utils/gtm'

const state = () => ({
  isCartModalVisible: false,
  newCartItem: null,
  shopping_cart_id: null,
  data: {
    items: []
  },
  itemIds: []
})

const actions = {
  setProducts({ commit }, payload) {
    commit('FetchProducts', payload)
  },

  setItemIds({ commit }, payload) {
    commit('SetItemIds', payload)
  },

  setShoppingCartId({ commit }, payload) {
    commit('SetShoppingCartId', payload)
  },

  clearProducts({ commit }) {
    const initalItems = {
      items: []
    }
    commit('FetchProducts', initalItems)
  },

  async fetchProducts({ commit, dispatch }) {
    dispatch('block', null, { root: true })

    const { success, data } = await currentCart(this)

    if (success) {
      commit('FetchProducts', data)
    }

    dispatch('unblock', null, { root: true })
  },

  async addPlan({ dispatch, state, commit }, payload) {
    const { plan, product, buyNow, skipModal, skipLoading } = payload

    if (!skipLoading) dispatch('block', null, { root: true })

    const slugs = state.data.items.map(({ product }) => product.slug)

    if (slugs.includes(product.slug)) {
      const odd = find(state.data.items, (p) => p.product.slug === product.slug)

      await dispatch('removePlan', { skipLoading, ...odd.plan })
      await dispatch('addPlan', payload)

      if (!skipLoading) dispatch('unblock', null, { root: true })
      return
    }

    const { success, data } = await addPlan(this, plan)

    if (!skipLoading) dispatch('unblock', null, { root: true })

    if (success) {
      commit('SetNewCartItem', { product, ...plan })
      commit('FetchProducts', data) // recarregando carrinho

      // Notificando Google Tag Manager
      trackEvent('AddToCart', {
        ecomm_pagetype: 'cart',
        ecomm_prodid: state.data.items.map((item) => item.plan.slug),
        ecomm_pvalue: state.data.items.map(
          (item) => item.plan.price.final_price
        ),
        ecomm_promocode: state.data?.coupon,
        ecomm_totalvalue: state.data?.resume?.total
      })

      if (buyNow) {
        this.$router.push('/checkout')
      } else if (!skipModal) dispatch('openCartModal')
    }
  },

  // DECREATED
  // async addCourse({ dispatch, state, commit }, plan) {
  //   dispatch('block', null, { root: true })

  //   const slugs = state.data.items.map(({ product }) => product.slug)

  //   if (slugs.includes(plan.slug)) {
  //     this.$router.push('/carrinho')
  //     dispatch('unblock', null, { root: true })
  //     return
  //   }

  //   const { success, data } = await addPlan(this, plan)

  //   dispatch('unblock', null, { root: true })

  //   if (success) {
  //     commit('SetNewCartItem', plan)
  //     commit('FetchProducts', data) // recarregando carrinho
  //     if (!plan.buyNow) {
  //       dispatch('openCartModal')
  //     } else {
  //       this.$router.push('/checkout')
  //     }
  //   }
  // },

  async removePlan({ commit, dispatch }, plan) {
    const { skipLoading } = plan

    if (!skipLoading) dispatch('block', null, { root: true })

    const { success, data } = await removePlan(this, plan)

    if (!skipLoading) dispatch('unblock', null, { root: true })

    if (success) {
      commit('FetchProducts', data)
    }
  },
  openCartModal({ commit }) {
    commit('OpenCartModal')
  },
  closeCartModal({ commit }) {
    commit('CloseCartModal')
  }
}

const mutations = {
  FetchProducts(state, payload) {
    const itemIds = payload.items.map((i) => i.product.slug)
    set(state, 'data', payload)
    set(state, 'shopping_cart_id', payload.id)
    set(state, 'itemIds', itemIds)
  },
  SetItemIds(state, payload) {
    state.itemIds = payload
  },
  SetShoppingCartId(state, payload) {
    state.shopping_cart_id = payload
  },
  OpenCartModal(state) {
    state.isCartModalVisible = true
  },
  CloseCartModal(state) {
    state.isCartModalVisible = false
  },
  SetNewCartItem(state, payload) {
    state.newCartItem = payload
  }
}

const getters = {
  cart(state) {
    return state.data
  },
  recurring(state) {
    return state.data.items.filter((i) => i.is_recurring)
  },
  notRecurring(state) {
    return state.data.items.filter((i) => !i.is_recurring)
  },
  notRecurringPerMonth: (state, getters) => (installment) => {
    const notRecurringPerMonth = getters.finalPriceNotRecurringPerInstallment(
      getters.installments[installment - 1]
    )
    return notRecurringPerMonth
  },
  finalPriceRecurring(state, getters) {
    if (!getters.recurring.length) {
      return 0
    }
    return getters.recurring
      .map((i) => {
        return parseFloat(i.plan.price.final_price)
      })
      .reduce((a, i) => {
        return a + i
      })
  },
  finalPriceNotRecurring: (state, getters) => (installment) => {
    return getters.notRecurringPerMonth(installment) * installment
  },
  finalPrice: (state, getters) => (installment) => {
    return (
      getters.finalPriceNotRecurring(installment) + getters.finalPriceRecurring
    )
  },
  biggerInstallments(state, getters) {
    return maxBy(
      getters.notRecurring,
      (product) => product.plan.price.installments.length
    )
  },
  maxInstallments(state, getters) {
    return getters.biggerInstallments.plan.price.installments.length
  },
  installments(state, getters) {
    return getters.notRecurring && getters.notRecurring.length
      ? getters.biggerInstallments.plan.price.installments
      : []
  },
  finalPriceNotRecurringPerInstallment: (state, getters) => (installment) => {
    if (!getters.notRecurring.length) {
      return 0.0
    }

    // TODO: as informacoes de valores separadas por tipo devem vir do servidor.
    // O parcelamento e o desconto adicional devem ser aplicados apenas a planos nao recorrentes.
    const finalPrice = getters.notRecurring
      .map((i, index) => {
        const price = i.plan.price.final_price
        return parseFloat(price)
      })
      .reduce((a, i) => {
        return a + i
      })

    // Pagamentos 1x tem desconto adicional.
    if (installment && installment.installment === 1) {
      const percent = installment.additional_discount.replace('%', '')
      // Desconto de 2%: preco * 0.98;
      return finalPrice * ((100.0 - percent) / 100.0)
    }

    return finalPrice / installment.installment
  },
  totalItems(state) {
    if (state.itemIds) {
      return state.itemIds.length
    }
    return 0
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
