<template>
  <div
    :class="{ 'card--shadow': isShadow, 'card--no-shadow': !isShadow }"
    class="card fb-professor-card"
  >
    <div class="card-content">
      <figure class="image is-96x96">
        <img :src="professor.avatar" :alt="professor.name" class="is-rounded" />
      </figure>
      <div class="title">{{ professor.name }}</div>
      <div class="fb-professor-card-divider"></div>
      <div class="discipline">
        {{ professor.disciplines.map((d) => d.name).join(', ') }}
      </div>
      <!-- <div class="course">Curso ENEM e Fundamentos</div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    professor: null,
    isShadow: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.fb-professor-card {
  width: ($gap * 22);
  margin: 0 $gap;
  height: 100%;
  &.card--no-shadow {
    background-color: transparent !important;
  }
  &-divider::after {
    display: block;
    content: ' ';
    border-top: 1px solid $info;
    max-width: ($gap * 6);
    margin: 0px auto $gap;
  }
  .title {
    font-size: 12px;
    letter-spacing: 1.44px;
    font-weight: 800;
    color: $secondary;
    text-transform: uppercase;
    text-align: center;
    padding: $gap;
    margin-bottom: $gap;
  }
  .discipline,
  .course {
    font-size: 12px;
    text-align: center;
  }
  .discipline {
    font-weight: bold;
  }
  .image {
    margin: auto;
  }
  @media screen and (max-width: $tablet) {
    width: ($gap * 22);
    margin: 0 $gap;
  }
}
</style>
