import createPersistedState from 'vuex-persistedstate'

export default ({ app, store }) => {
  createPersistedState({
    key: 'vuex',
    paths: [
      'auth.loggedIn',
      'auth.student.name',
      'auth.student.email',
      'auth.student.username',
      'auth.student.avatar',
      'cart.itemIds',
      'cart.shopping_cart_id',
      'site'
    ],
    storage: {
      getItem: (key) => JSON.stringify(app.$cookies.get(key)),
      setItem: (key, value) => app.$cookies.set(key, value),
      removeItem: (key) => app.$cookies.remove(key)
    }
  })(store)
}
