const state = () => ({
  breadcrumbs: [],
  categories: []
})

const actions = {
  async fetchCategories({ commit }, app) {
    const categories = await app.$blog.$get('/categories', {
      params: {
        _limit: 10
      }
    })

    const categoryPromises = categories.map(async (category) => {
      category.articles = await app.$blog.$get(
        `/articles/findByCategory/${category.id}`,
        {
          params: {
            _limit: 3
          }
        }
      )
    })

    await Promise.all(categoryPromises)

    commit('SetCategories', categories)
  },
  setBreadcrumb({ commit }, payload) {
    commit('SetBreadcrumb', payload)
  }
}

const mutations = {
  SetCategories(state, payload) {
    state.categories = payload
  },
  SetBreadcrumb(state, payload) {
    state.breadcrumbs = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
