<template>
  <div v-if="courses && courses.length" class="fb-product-banner-wrap">
    <transition name="fade" mode="out-in">
      <div
        key="banner"
        v-if="!pending"
        class="fb-product-banner is-flex is-align-items-center"
      >
        <div class="container is-fullhd">
          <div class="columns">
            <div class="column is-6">
              <figure class="fb-product-banner-about">
                <img
                  :src="
                    require('~/assets/scss/globals/themes/supermed-semestral23/assets/banner.png')
                  "
                  width="600"
                  height="600"
                  alt="Conheça agora nossos novos cursos"
                />
              </figure>
            </div>
            <div class="column is-6">
              <div
                class="title is-size-3 has-text-weight-normal fb-product-banner-text"
              >
                Garanta a preparação mais completa <br />
                para o vestibular com <b>20% OFF</b>
              </div>
              <div class="columns">
                <div
                  v-for="(course, index) in courses"
                  :key="course.id"
                  class="column is-6"
                >
                  <nuxt-link
                    :class="`is-${course.icon}`"
                    :data-aos-delay="200 + 100 * index"
                    :to="`/curso/${course.icon}`"
                    class="product-card"
                    data-aos="fade-up"
                  >
                    <div class="card">
                      <div class="card-content">
                        <div
                          v-if="hasPlanDiscount(course)"
                          class="product-card-course-discount"
                        >
                          <div class="discount-heading">DESCONTO</div>
                          <div class="discount-value">
                            -{{ getDiscountInfo(course) }}
                          </div>
                        </div>
                        <!-- <div class="product-card-course-label">
                          {{ getCourseLabel(course) }}
                        </div> -->
                        <div class="product-card-course-logo">
                          <figure>
                            <img
                              v-lozad
                              :src="getCourseLogo(course)"
                              :alt="course.title"
                              width="200px"
                              height="100px"
                            />
                          </figure>
                        </div>
                        <div class="product-card-price">
                          <div class="product-card-price-currency">R$</div>
                          <div class="product-card-price-decimal">
                            {{ getPerMonthPrice(course) }}
                          </div>
                          <div class="product-card-price-period">mês</div>
                        </div>
                        <div class="product-card-total-price">
                          Preço total {{ getFinalPrice(course) | currency }}
                        </div>
                        <button
                          @click="addPlan(course)"
                          @click.prevent=""
                          type="button"
                          class="product-card-buy-now-button"
                        >
                          Comprar agora
                        </button>
                        <ul class="product-card-features">
                          <li v-for="o in course.offer" :key="o">
                            <span>{{ o }}</span>
                          </li>
                        </ul>
                        <b-button
                          :to="`/curso/${course.icon}`"
                          class="product-card-readmore"
                          type="is-text"
                          tag="nuxt-link"
                        >
                          Saiba mais
                        </b-button>
                      </div>
                    </div>
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div key="preload" v-else class="fb-product-banner-pending"></div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import lozad from '@/utils/directives/lozad'

export default {
  directives: {
    lozad
  },
  props: {
    pending: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      courses: ({ product }) => product.super_courses
    })
  },
  methods: {
    getCourseLabel(course) {
      switch (course.icon) {
        case 'superita':
          return 'super'
        default:
          return 'curso'
      }
    },
    getPlan(course) {
      return course.plans.length && course.plans[0]
    },
    hasPlanDiscount(course) {
      const plan = this.getPlan(course)
      return plan?.price?.discount
    },
    getFinalPrice(course) {
      const plan = this.getPlan(course)
      return plan?.price?.final_price
    },
    getDiscountInfo(course) {
      const plan = this.getPlan(course)
      return plan?.price?.discount.info
    },
    getPerMonthPrice(course) {
      const plan = this.getPlan(course)
      const integer_part = plan?.price?.per_month?.integer_part
      const decimal_part = plan?.price?.per_month?.decimal_part

      return `${integer_part},${decimal_part}`
    },
    getCourseLogo(course) {
      return require(`~/assets/scss/globals/themes/supermed23/assets/${course.icon}.png`)
    },
    getPeriod(course) {
      const plan = this.getPlan(course)
      if (course && plan) {
        const { slug, title, type } = course
        return {
          plan,
          product: {
            slug,
            title,
            type
          },
          buyNow: true,
          skipModal: true
        }
      }
      return {}
    },
    addPlan(course) {
      const period = this.getPeriod(course)
      this.$addPlan(period)
    },
    ...mapActions({
      $addPlan: 'cart/addPlan'
    })
  }
}
</script>

<style lang="scss">
.fb-site-carousel-wrap:not(.is-empty) ~ .fb-product-banner {
  margin-top: 0px;
}

.fb-product-banner {
  // background: linear-gradient(98.12deg, #cbe889 0%, #96e6a1 80.75%);
  background-image: url('~assets/images/super-courses/background.webp');
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  margin: auto;
  width: 100%;
  max-width: 1920px;
  min-height: 100vh;

  @media (max-width: $tablet) {
    margin-top: $gap * -1;
    background-size: cover;

    > .container {
      padding: $navbar-height $gap * 4 $gap * 6;
    }
  }

  &-about {
    img {
      width: 100%;
      height: auto;
    }
  }

  &-pending {
    min-height: 100vh;
  }

  > .container {
    min-height: 0px;
  }

  .product-card {
    max-width: 308px;
    margin: auto;

    &.is-supermed {
      .product-card-course-discount {
        background-color: #e4ae20;
      }
    }

    &.is-superita {
      .product-card-course-label,
      .product-card-course-discount {
        background-color: #e4ae20;
      }
    }

    &-course-label {
      @include gotham-semibold;
      background-color: $brand-secondary;
      padding: 0.25rem $gap * 3.5;
      border-radius: $gap * 2;
      text-transform: lowercase;
      letter-spacing: 3px;
    }

    &-course-discount {
      position: absolute;
      top: 0;
      right: $gap * 1.5;
      padding: $gap * 1.25 $gap $gap * 0.5;
      background-color: white;
      box-shadow: 0px 3px 4px #86791e29;
      border-radius: 12px;

      .discount-heading {
        font-size: 0.8em;
        font-weight: bold;
      }

      .discount-value {
        margin-top: ($gap * -1/2);
        font-size: 1.6em;
        font-weight: 800;
        text-align: center;
      }
    }

    &-course-logo {
      margin: $gap * 4 auto 0px;
      max-width: 90%;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-price {
      display: flex;
      align-items: center;

      &-decimal {
        @include opensans-extrabold;
        font-size: 48px;

        @media (max-width: $tablet) {
          font-size: 38px;
        }
      }

      &-currency,
      &-period {
        font-size: 20px;
      }

      &-currency {
        margin-right: $gap;
      }

      &-period {
        margin-left: $gap;
      }
    }

    &-total-price {
      @include gotham-semibold;

      font-size: 12;
      margin-bottom: $gap * 2;
    }

    &-buy-now-button {
      @include opensans-semibold;
      user-select: none;
      background-color: $brand-secondary;
      font-size: 18px;
      padding: 12px 36px;
      margin-bottom: $gap * 2;
      border-radius: $gap * 6;
      border: none;

      &:hover {
        cursor: pointer;
        background-color: darken($brand-secondary, 3);
      }
    }

    &-features {
      text-align: left;
      margin-bottom: $gap;

      li {
        @include gotham-light;
        user-select: none;
        align-items: center;
        display: flex;
        font-size: 12px;

        span {
          flex: 1;
        }

        &::before {
          font-family: 'icomoon';
          color: $brand-secondary;
          font-size: $gap * 3;
          margin-right: $gap * 1;
          content: '\e935'; // icon-confirm
        }
      }
    }

    &-readmore {
      font-size: 16px;
      color: $black;
      text-decoration: underline;
      text-transform: none;
      margin-bottom: $gap;
    }

    .card {
      .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: $gap * 4;
      }
    }
  }

  .titles {
    max-width: 480px;
    margin: auto;
  }

  .title {
    @include opensans-extrabold;
    line-height: 1.2;
    margin-bottom: $gap * 3;

    @media (max-width: $tablet) {
      font-size: 36px;
    }
  }

  .subtitle {
    font-size: $gap * 2;
  }
}
</style>
