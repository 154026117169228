<template>
  <div
    :class="{ 'is-mobile': mobile, 'is-desktop': !mobile }"
    class="fb-product-video"
  >
    <div
      :class="{
        'card--shadow': mobile,
        'card--no-shadow': !mobile
      }"
      class="card card-product-video"
    >
      <div class="card-header">
        <div class="card-header-title">
          Assista a Primeira Aula Gratuitamente
        </div>
      </div>
      <lite-youtube :videoid="videoRegex"></lite-youtube>
    </div>
  </div>
</template>

<script>
import '@justinribeiro/lite-youtube'

export default {
  props: {
    video: {
      type: String,
      default: ''
    }
  },
  computed: {
    videoRegex() {
      if (this.video) {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = this.video.match(regExp)
        if (match && match[2].length === 11) {
          return match[2]
        }
        return ''
      }
      return ''
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    }
  }
}
</script>

<style lang="scss">
.fb-product-video {
  margin-top: 24px;
  margin-left: -16px;
  margin-right: -16px;
  &.is-desktop {
    border-top: 1px solid $grey-lighter;
    border-radius: 0px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    .card-product-video {
      padding: $gap * 2 $gap * 3;
    }
  }
}
</style>
