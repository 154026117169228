<template>
  <div class="fb-site-checkout-flow-header">
    <b-button
      @click="goBack"
      icon-left="arrow-left"
      type="is-text"
      class="fb-site-checkout-flow-header-back-btn"
      >Voltar</b-button
    >
    <slot>
      <img
        src="~assets/images/logo-white-border.png"
        width="104"
        class="fb-site-checkout-flow-header-logo"
      />
    </slot>
    <div v-if="label" class="fb-site-checkout-flow-header-label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    back: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (document) {
      document.querySelector('body').className = ''
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss">
.fb-site-checkout-flow-header {
  position: relative;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('~assets/images/mask-drawer-menu.png') no-repeat;
  background-size: 100% 100%;
  height: 240px;
  margin: 0px auto ($gap * 3);
  &-logo {
    padding-bottom: $gap;
  }
  &-label {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: $white;
  }
  &-back-btn {
    position: absolute;
    top: $gap * 3;
    left: $gap * 3;
    &.button.is-text {
      color: $white;
      font-weight: bold;
      font-size: 12px;
      &:active,
      &:hover,
      &:focus {
        background-color: transparent;
      }
      .icon {
        margin-right: $gap * 2;
        > .mdi::before {
          font-size: 21px;
        }
      }
    }
  }
  @media (max-width: $tablet) {
    display: flex;
  }
}
</style>
