<template>
  <div class="fb-credit-card-select-brand-wrap">
    <div v-if="focused || isVisible" class="fb-credit-card-select-brand">
      <picture
        v-for="brand in brands"
        :key="brand.id"
        @click="onSelect(brand)"
        :class="{ 'is-selected': input === brand.id }"
        class="image"
      >
        <img :src="brand.img" :alt="brand.name" :title="brand.name" />
      </picture>
    </div>
    <div v-if="messages" class="has-text-danger">
      <span v-if="messages.payment_company_id || messages.payment_company_code">
        Aceitamos apenas as bandeiras listadas acima.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'input',
    event: 'change'
  },
  props: {
    input: null,
    messages: null,
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focused: false,
      brands: [
        {
          id: 'visa',
          name: 'Visa',
          img: require('~/assets/images/credit-card-brand/visa.svg')
        },
        {
          id: 'mastercard',
          name: 'MasterCard',
          img: require('~/assets/images/credit-card-brand/mastercard.svg')
        },
        {
          id: 'elo',
          name: 'Elo',
          img: require('~/assets/images/credit-card-brand/elo.svg')
        },
        {
          id: 'diners_club',
          name: 'Diners',
          img: require('~/assets/images/credit-card-brand/diners_club.svg')
        },
        {
          id: 'american_express',
          name: 'American Express',
          img: require('~/assets/images/credit-card-brand/american_express.svg')
        },
        {
          id: 'hipercard',
          name: 'Hipercard',
          img: require('~/assets/images/credit-card-brand/hipercard.svg')
        }
      ]
    }
  },
  methods: {
    onSelect(brand) {
      if (brand) {
        this.focused = true
      }
      this.$emit('change', brand.id)
    }
  }
}
</script>

<style lang="scss">
.fb-credit-card-select-brand {
  display: flex;
  align-content: center;
  justify-content: center;
  picture {
    margin: ($gap * 2) $gap;
    outline: none;
    opacity: 0.2;
    &:hover,
    &:active {
      cursor: pointer;
    }
    &.is-selected {
      opacity: 1;
    }
    img {
      height: 48px;
    }
  }
}
.fb-credit-card-select-brand-wrap {
  .has-text-danger {
    margin: $gap 0px $gap * 3;
    text-align: center;
  }
}
</style>
