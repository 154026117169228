<template>
  <div v-if="item" :class="{ 'is-mobile': mobile }" class="fb-cart-item">
    <div class="container is-fluid">
      <div :class="mobile ? 'is-desktop' : 'is-mobile'" class="columns">
        <div
          class="column item-container is-full-mobile is-three-fifths-desktop"
        >
          <div class="columns is-mobile">
            <div class="column is-one-fifth item-icon">
              <fb-product-widget-icon :icon="icon" />
            </div>
            <div class="column is-three-quarters item-description">
              <div class="fb-cart-item-title">{{ item.product.title }}</div>
              <div class="fb-cart-item-subtitle">
                {{ planIntervalInfo }}
              </div>
              <div class="fb-cart-item-observations">
                {{ plan.observations }}
              </div>
            </div>
          </div>
        </div>

        <div class="column item-price is-full-mobile is-two-fifths-desktop">
          <div class="columns is-mobile price-columns">
            <div class="column is-half">
              <div
                v-if="discount"
                :class="{ 'is-mobile': mobile }"
                class="fb-cart-item-discount"
              >
                <span class="currency">R$&nbsp;</span>
                {{ plan.price.full_price | currency({ symbol: '' }) }}
              </div>
            </div>

            <div class="column is-half">
              <div
                :class="mobile ? 'is-mobile' : 'is-desktop'"
                class="fb-cart-item-price"
              >
                <div class="currency-price">
                  <span class="currency">R$</span>
                  <b>{{ plan.price.final_price | currency({ symbol: '' }) }}</b>
                </div>
                <span class="period">{{ finalPriceInfo }}</span>
              </div>
            </div>
          </div>
        </div>
        <button
          @click="() => removePlan(plan)"
          :class="mobile ? 'is-mobile' : 'is-desktop'"
          class="delete is-small"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CourseCardMixins from '~/utils/mixins/CourseCardMixins'
export default {
  extends: CourseCardMixins,
  props: {
    item: {}
  },
  data() {
    return {
      selectedPeriodIndex: 1
    }
  },
  computed: {
    plan() {
      return this.item.plan
    },
    icon() {
      const { icon_slug } = this.item.product
      return `icon-${icon_slug}`
    },
    finalPriceInfo() {
      const { plan } = this.item
      const installments = plan.price.installments.length
      if (plan.is_recurring) {
        return 'todo mês'
      }

      return `em até ${installments}x`
    },
    discount() {
      const { plan } = this.item
      const { discount } = plan.price
      return !!discount
    },
    planIntervalInfo() {
      if (this.plan.type === 'closed_period') {
        return 'Turma de Agosto'
      }
      switch (this.plan.interval_count) {
        case 1:
          return 'Plano Mensal'
        case 6:
          return 'Plano Semestral'
        case 12:
          return 'Plano Anual'
        default:
          return ''
      }
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    }
  },
  methods: {
    ...mapActions('cart', ['removePlan'])
  }
}
</script>

<style lang="scss">
.fb-cart-item {
  position: relative;
  border-bottom: 1px solid transparentize($black, 0.8);
  > .container {
    margin: ($gap * 4) 0px !important;
    min-height: $gap * 10;
  }
  .columns {
    position: relative;
    align-items: center;
    padding: 0px $gap;
    .is-three-quarters {
      padding: 0px ($gap * 1.5) $gap;
      border-left: 3px solid $secondary;
    }
  }

  .item-icon {
    padding: 0;
  }

  .item-container {
    padding-bottom: 0;
    padding-top: 0;
  }

  .item-description {
    flex-direction: column;
    min-height: 70px;
  }

  .item-price {
    align-self: flex-end;
    padding-bottom: 0px;
    padding-top: 0px;
    .price-columns {
      padding-top: $gap * 3;
    }
  }

  .columns + .columns {
    padding: 0px $gap $gap;
    .is-three-quarters {
      border-left-color: transparent;
    }
  }
  &-title {
    @include title-type-1(600);
    padding: 0px 0px $gap;
    margin-left: $gap;
  }
  &-subtitle {
    text-transform: uppercase;
    font-size: 12px;
    padding: 0px 0px $gap;
    margin-left: $gap;
    color: #6d6d6d;
  }
  &-observations {
    font-size: 11px;
    color: #6d6d6d;
    margin-left: $gap;
  }
  &-price {
    display: flex;
    &.is-desktop {
      align-items: flex-end;
      flex-direction: column;
    }
    &.is-mobile {
      align-items: center;
      flex-direction: row;
      .period {
        font-size: 10px;
        margin-left: 2px;
        margin-top: 3px;
      }
    }
    // font-size: 10px;
    // color: transparentize($color: $black, $amount: 0.5);
    b {
      font-size: 16px;
      color: #09456f;
      font-weight: bold;
    }
    .currency-price {
      // display: flex;
      // align-items: flex-end;
      .currency {
        font-size: 9px;
        color: #565656;
      }
    }
    .period {
      font-size: 12px;
      color: #a3a3a3;
    }
  }
  &-discount {
    font-size: 14px;
    color: #6d6d6d;
    text-decoration: line-through;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 18px;

    &.is-mobile {
      font-size: 14px;
      padding-bottom: 0px;
    }
  }
  .delete {
    position: absolute;
    &.is-mobile {
      top: $gap * 3;
      right: $gap * 2;
      margin-top: -20px;
      margin-right: 10px;
    }
    &.is-desktop {
      top: $gap * -1;
      right: $gap * 3;
    }
    background-color: $info;
  }
  .fb-course-card-btn-radio {
    margin: $gap 0px ($gap * 1.5);
  }
  &.is-mobile {
    margin-left: ($gap * -2);
    margin-right: ($gap * -2);
    margin-top: $gap;
    > .container {
      margin: ($gap * 4.5) 0px ($gap * 4) !important;
      min-height: 0px;
    }
    .price-columns {
      display: block;
      padding-top: $gap * 2;
      > .column {
        position: relative;
        display: inline;
        &:first-child {
          display: inline-block;
          width: 20%;
        }
        .fb-cart-item-discount,
        .fb-cart-item-price,
        .currency-price {
          display: inline;
        }
        .fb-cart-item-discount {
          position: absolute;
          right: $gap * 0.5;
          top: $gap * 0.5;
          width: 100%;
          text-align: right;
          font-size: 12px;
        }
        .fb-cart-item-price {
          display: inline-block;
          margin-top: $gap;
          margin-left: $gap;
        }
      }
    }
  }
}
</style>
