<template>
  <div class="fb-site-social-media fluid-container">
    <div :class="{ 'is-center': mobile }" class="columns is-mobile">
      <a href="https://www.facebook.com/fariasbritoonline/" target="_blank">
        <b-icon
          icon="icon-facebook"
          pack="custom"
          size="is-normal"
          type="is-white"
        />
      </a>
      <a href="https://www.instagram.com/online.fb/" target="_blank">
        <b-icon
          icon="icon-instagram"
          pack="custom"
          size="is-normal"
          type="is-white"
        />
      </a>
      <a href="https://www.youtube.com/fbonlineoficial" target="_blank">
        <b-icon
          icon="icon-youtube"
          pack="custom"
          size="is-normal"
          type="is-white"
        />
      </a>
      <a href="https://twitter.com/onlinefb_" target="_blank">
        <b-icon
          icon="icon-twitter"
          pack="custom"
          size="is-normal"
          type="is-white"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$mq)
    }
  }
}
</script>

<style lang="scss" scoped>
.fb-site-social-media {
  padding: ($gap * 4) 0.75rem 0px;
  margin-top: $gap * 3;
  a:not(:last-child) {
    margin-right: ($gap * 3);
  }
}
// @media screen and (min-width: $desktop) {
//   .fb-site-social-media {
//     border-top: none;
//     .columns {
//       justify-content: flex-end;
//     }
//   }
// }
</style>
